import useSWR from "swr";

export default function useAnalyzeIdSum() {
    const { data, error, mutate } = useSWR("api/v1/ya-metrika/stat/campaigns-daily")
    const isLoading = !error && !data

    return {
        StatIdSum: data,
        isLoading,
        mutate
    }
}