import { useState } from "react";
import DataTable from "../Table/Datatable";
import { format as dateFormat } from "date-fns";
import { useEffect } from "react";
import { API_HOST } from "../../../configs/main";
import "./dmp.scss";
import { INIT_FILTER_VALUE, filterDate, filterEmploye, filterStatsDate, filterStatsPartner } from "./filters";
import { Modal, Box, Typography, MenuItem } from "@material-ui/core";
import { FormControl, InputLabel, Select } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo";


const styles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const decisionStyles = {
  maxHeight: "550px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const campaignStyles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};


const CampaignData = ({data}) => {
  const { enqueueSnackbar } = useSnackbar();
  const listCampaigns = []
  listCampaigns.push(data)

  const columns = [
    {
      Header: "ID РК",
      accessor: "id",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Название",
      accessor: "name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сайт",
      accessor: "site",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Почта клиента",
      accessor: "campaign_creator_email",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Дата создания",
      accessor: "created_at",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Тип РК",
      accessor: "type",
      maxWidth: 400,
      minWidth: 60,
      width: 117,
    },
    {
      Header: "Дневной бюджет",
      accessor: "daily_budget",
      maxWidth: 400,
      minWidth: 80,
      width: 145,
    },
    {
      Header: "Общий бюджет",
      accessor: "full_budget",
      maxWidth: 400,
      minWidth: 80,
      width: 145,
    },
    {
      Header: "Ставка",
      accessor: "bet",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Период с-по",
      accessor: "range",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сумма спис",
      accessor: "charge",
      maxWidth: 400,
      minWidth: 50,
      width: 150,
    },
    {
      Header: "Статус",
      accessor: "status",
      maxWidth: 400,
      minWidth: 50,
      width: 200,
    },
  ];

  const STATUS_TYPE = {
    ACTIVE: "На модерации",
    INACTIVE: "Неактивна",
    WORK: "Работает",
    MODERATION: "На модерации",
    ERROR: "Ошибка",
  };
  
  const TARGET_TYPE = {
    TRAFFIC: "Трафик",
    REACH: "Охват",
  };

  function getTableData() {
    return listCampaigns.map((el) => {
      return {
        id: (
          <div
            className="td"
            style={{ display: "flex", flexDirection: "row", borderRadius: 5 }}
          >
            {el.id.slice(0, 8)}
            <ContentCopy
              style={{ marginLeft: 5, cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(el.id);
                enqueueSnackbar("ID кампании скопирован", {
                  variant: "success",
                  autoHideDuration: 1000,
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
              }}
              className="copyIcon"
              fontSize="small"
            />
          </div>
        ),
        name: <div className="td">{el.name ? el.name : "-"}</div>,
        site: (
          <div className="td" style={{ display: "flex", flexDirection: "row" }}>
            <span>{el.site ? el.site : "-"}</span>
            {el.site && (
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.site);
                  enqueueSnackbar("Сайт кампании скопирован", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        campaign_creator_email: (
          <div className="td" style={{ display: "flex", flexDirection: "row" }}>
            <span>{el.campaign_creator_email ? el.campaign_creator_email : "-"}</span>
            {el.campaign_creator_email && (
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.campaign_creator_email);
                  enqueueSnackbar("Почта клиента скопирована", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        created_at: (
          <div className="td">
            {dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")}
          </div>
        ),
        type: <div className="td">{TARGET_TYPE[el.target_type]}</div>,
        daily_budget: <div className="td">{el.daily_budget}</div>,
        full_budget: <div className="td">{el.full_budget}</div>,
        bet: <div className="td">{el.price_per_action}</div>,
        range: (
          <div className="td">
            {dateFormat(
              Date.parse(new Date(el.validity_period.date_start)),
              "dd/MM/yyyy"
            )}
            -
            {dateFormat(
              Date.parse(new Date(el.validity_period.date_end)),
              "dd/MM/yyyy"
            )}
          </div>
        ),
        charge: <div className="td">{el.charges_sum}</div>,
        status: (
          <div className="td">
            {STATUS_TYPE[el.status]}
          </div>
        ),
      };
    });
  }

  return (
    <DataTable data={getTableData()} columns={columns} />
  )
}


const DMP_API = "https://dmp.programmatic.ru";
const PrgStatsData = ({ campaignData }) => {
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [filterArrSegments, setFilterSegments] = useState([]);
  const [filterArrSex, setFilterSex] = useState([]);
  const [filterArrAge, setFilterAge] = useState([]);
  const [upd, setUpd] = useState(false);


  useEffect(() => {
    async function fetchData() {
      const date_start = new Date(filtersValue.date.date_start).setHours(0, 0, 0, 0) / 1000;
      const date_end = new Date(filtersValue.date.date_end).setHours(23, 59, 59, 0) / 1000;

      if (!isNaN(date_start) && !isNaN(date_end)) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        const siteDomain = campaignData.site.match(/^https?:\/\/(?:www\.)?([^\/?]+)/i)?.[1];

        if (siteDomain) {
          const dmpResponse = await fetch(`${DMP_API}/getCampaignStatisticByPeriod?campaign_id=${siteDomain}&date_from=${date_start}&date_to=${date_end}`, {
            method: "GET",
            headers: myHeaders,
          });
    
          if (dmpResponse.ok) {
            const dmpData = await dmpResponse.json();
            setFilterSegments(dmpData.segments);
            setFilterSex(dmpData.sex);
            setFilterAge(dmpData.age);
          }
        }
      }
    }
  
    if (filtersValue.date.date_start !== "" && filtersValue.date.date_end !== "") {
      fetchData();
    }
  }, [upd]);

  const columns = [
    {
      Header: "Сегмент",
      accessor: "segment",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Процент визитов",
      accessor: "visits_percent",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
  ];

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
    setUpd(!upd);
  };

  function getTableData(data) {
    if (Object.keys(data).length === 0) {
      return [
        {
          segment: <div className="td">-</div>,
          visits_percent: <div className="td">-</div>,
        },
      ];
    }

    return Object.entries(data).sort(([, a], [, b]) => b - a).map(([key, value]) => {
      return {
        segment: <div className="td">{key ? key : "-"}</div>,
        visits_percent: <div className="td">{(value * 100).toFixed(1)}%</div>,
      };
    });
  }
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 20 }}>
      <DateFromTo
        from={new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000).getTime()}
        to={new Date(new Date().setHours(23, 59, 59, 999)).getTime()}
        handleChange={handleDateChange}
      />
      <div>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <p style={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}>Сегменты по полу</p>
          <DataTable data={getTableData(filterArrSex)} columns={columns} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px", marginTop: "30px" }}>
          <p style={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}>Сегменты по возрасту</p>
          <DataTable data={getTableData(filterArrAge)} columns={columns} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px", marginTop: "30px" }}>
          <p style={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}>Сегменты по интересам</p>
          <DataTable data={getTableData(filterArrSegments)} columns={columns} />
        </div>
      </div>
    </div>
  );
};

const CodeBlock = ({campaing_id}) => {
  console.log(campaing_id)
  const codeString = `
<!-- Programmatic.ru dmp counter --> 
<script async type='text/javascript' src='${DMP_API}/assets'></script> 
<!-- Programmatic.ru dmp counter -->
  `.trim();

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(codeString).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div style={{ position: 'relative', padding: '10px', border: '1px solid #ddd', borderRadius: '4px', fontFamily: 'monospace', marginTop: '15px' }}>
      <button 
        onClick={copyToClipboard} 
        style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}>
        {copied ? <span style={{ color: 'blue' }}>Скопировано!</span> : 'Скопировать'}
      </button>
      <pre style={{ whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
        <code>{codeString}</code>
      </pre>
    </div>
  );
};






const PostViewData = ({ campaignData }) => {
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [filterArrSegments, setFilterSegments] = useState([]);
  const [upd, setUpd] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      const date0 = new Date(filtersValue.date.date0).setHours(0, 0, 0, 0) / 1000;
      const date1 = new Date(filtersValue.date.date1).setHours(23, 59, 59, 0) / 1000;
      const date2 = new Date(filtersValue.date.date2).setHours(0, 0, 0, 0) / 1000;
      const date3 = new Date(filtersValue.date.date3).setHours(23, 59, 59, 0) / 1000;
      
      const dmpResponse = await fetch(`${DMP_API}/get-post-view-traffic`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          campaign_id: campaignData.id,
          date0: date0,
          date1: date1,
          date2: date2,
          date3: date3
        })
      });
  
      if (dmpResponse.ok) {
        const data = await dmpResponse.json();
        setFilterSegments(data);
      }
    }
    if (filtersValue.date && Object.values(filtersValue.date).some(date => date !== "")) {
      // fetchData();
      setFilterSegments(testData);
    }
  }, [upd]);

  const testGoals = [
    {
      name: "Переход в корзину",
      id: "329438429"
    },
    {
      name: "Отправка заявки",
      id: "32943842322239"
    },
    {
      name: "Оплата заказа",
      id: "32943811429"
    },
  ]


  const testData = [
    {
      conversionId: "123456",
      conversionType: "Purchase",
      activityDateTime: "2024-09-01 12:00:00",
      visitDateTime: "2024-09-01 11:30:00",
      interactionDateTime: "2024-09-01 11:45:00",
      campaignId: "CMP123",
      campaignName: "Summer Sale",
      eventType: "Click",
      bannerId: "BNR001",
      trackingSystem: "Programmatic",
      domain: "example.com",
      OS: "Windows",
      device: "Desktop",
      region: "Moscow",
      browser: "Chrome",
      programmaticId: "PRG001",
      clientId: "CLT123",
    },
    {
      conversionId: "654321",
      conversionType: "SignUp",
      activityDateTime: "2024-09-02 14:00:00",
      visitDateTime: "2024-09-02 13:30:00",
      interactionDateTime: "2024-09-02 13:45:00",
      campaignId: "CMP456",
      campaignName: "Winter Sale",
      eventType: "View",
      bannerId: "BNR002",
      trackingSystem: "Programmatic",
      domain: "testsite.ru",
      OS: "iOS",
      device: "Mobile",
      region: "Saint Petersburg",
      browser: "Safari",
      programmaticId: "PRG002",
      clientId: "CLT456",
    }
  ];
  

  const columns = [
    {
      Header: "Conversion ID",
      accessor: "conversionId",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Conversion type",
      accessor: "conversionType",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Activity Date/Time",
      accessor: "activityDateTime",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Visit Date/Time",
      accessor: "visitDateTime",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Interaction Date/Time",
      accessor: "interactionDateTime",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Campaign ID",
      accessor: "campaignId",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Campaign",
      accessor: "campaignName",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Статус события показ/клик",
      accessor: "eventType",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Banner ID",
      accessor: "bannerId",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Трекинг система",
      accessor: "trackingSystem",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Домен показа",
      accessor: "domain",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "ОС",
      accessor: "OS",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Устройство",
      accessor: "device",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "География",
      accessor: "region",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Браузер",
      accessor: "browser",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Programmatic ID",
      accessor: "programmaticId",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "clientID YM",
      accessor: "clientId",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
  ];

  const handleDateChange = (value, type) => {
    setValue((prev) => ({
      ...prev,
      date: type === 1
        ? { ...prev.date, date0: value[1] ?? "", date1: value[0] ?? "" }
        : { ...prev.date, date2: value[1] ?? "", date3: value[0] ?? "" },
    }));
    setUpd(!upd);
  };

  const handleGoalChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === '0') {
      setValue({ goal: { name: 'Все цели', id: '0' } });
    } else {
      const selectedGoal = testGoals.find(goal => goal.id === selectedValue);
      setValue({
        goal: {
          name: selectedGoal?.name || '',
          id: selectedGoal?.id || '',
        }
      });
    }
    setUpd(!upd);
  };

  function getTableData(data) {
    if (Object.keys(data).length === 0) {
      return [
        {
          conversionId: <div className="td">-</div>,
          conversionType: <div className="td">-</div>,
          activityDateTime: <div className="td">-</div>,
          visitDateTime: <div className="td">-</div>,
          interactionDateTime: <div className="td">-</div>,
          campaignId: <div className="td">-</div>,
          campaignName: <div className="td">-</div>,
          eventType: <div className="td">-</div>,
          bannerId: <div className="td">-</div>,
          trackingSystem: <div className="td">-</div>,
          domain: <div className="td">-</div>,
          OS: <div className="td">-</div>,
          device: <div className="td">-</div>,
          region: <div className="td">-</div>,
          browser: <div className="td">-</div>,
          programmaticId: <div className="td">-</div>,
          clientId: <div className="td">-</div>,
        },
      ];
    }
  
    return data
      .sort((a, b) => (a.activityDateTime > b.activityDateTime ? -1 : 1))
      .map((row) => {
        return {
          conversionId: <div className="td">{row.conversionId || "-"}</div>,
          conversionType: <div className="td">{row.conversionType || "-"}</div>,
          activityDateTime: <div className="td">{row.activityDateTime || "-"}</div>,
          visitDateTime: <div className="td">{row.visitDateTime || "-"}</div>,
          interactionDateTime: <div className="td">{row.interactionDateTime || "-"}</div>,
          campaignId: <div className="td">{row.campaignId || "-"}</div>,
          campaignName: <div className="td">{row.campaignName || "-"}</div>,
          eventType: <div className="td">{row.eventType || "-"}</div>,
          bannerId: <div className="td">{row.bannerId || "-"}</div>,
          trackingSystem: <div className="td">{row.trackingSystem || "-"}</div>,
          domain: <div className="td">{row.domain || "-"}</div>,
          OS: <div className="td">{row.OS || "-"}</div>,
          device: <div className="td">{row.device || "-"}</div>,
          region: <div className="td">{row.region || "-"}</div>,
          browser: <div className="td">{row.browser || "-"}</div>,
          programmaticId: <div className="td">{row.programmaticId || "-"}</div>,
          clientId: <div className="td">{row.clientId || "-"}</div>,
        };
      });
  }
  
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 20 }}>
      <div style={{ display: "flex", flexDirection: "column", gap: 20, width: 500 }}>
      <Box sx={{ width: 300 }}>
        <FormControl fullWidth size="small">
          <InputLabel
            id="demo-select-small-label"
            sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
          >
            Выбор цели
          </InputLabel>
          <Select
            style={{
              height: 32,
              background: "#FFFFFF",
              borderColor: "transparent",
            }}
            sx={{
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
            label="Цель"
            id="demo-select-small"
            value={filtersValue.goal.id}
            onChange={handleGoalChange}
          >
            <MenuItem value="0">Все цели</MenuItem>
            {testGoals.map((goal) => (
              <MenuItem key={goal.id} value={goal.id}>
                {goal.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
        <div>
          <Typography style={{ marginBottom: 10, fontSize: 14 }} id="modal-modal-title" variant="h5" component="h4">
            Промежуток времени совершенных показов
          </Typography>
          <DateFromTo
            from={new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000).getTime()}
            to={new Date(new Date().setHours(23, 59, 59, 999)).getTime()}
            handleChange={(value) => handleDateChange(value, 1)}
          />
        </div>
        <div>
          <Typography style={{ marginBottom: 10, fontSize: 14 }} id="modal-modal-title" variant="h5" component="h4">
            Промежуток времени совершенной конверсии
          </Typography>
          <DateFromTo
            from={new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000).getTime()}
            to={new Date(new Date().setHours(23, 59, 59, 999)).getTime()}
            handleChange={(value) => handleDateChange(value, 2)}
          />
        </div>

      </div>

      <div>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px", marginTop: "30px" }}>
          <DataTable data={getTableData(filterArrSegments)} columns={columns} />
        </div>
      </div>
    </div>
  );
};








const OpenModal = ({ openModal, setOpenModal, type, creativeData, campaignData }) => {
  let modalContent;

  const ModalStats = ({ openModal, setOpenModal, children }) => {
    const closeModal = () => setOpenModal(false);
  
    const modalStyles = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: openModal ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    };
  
    const modalContentStyles = {
      backgroundColor: '#fff',
      width: '80%',
      maxWidth: 400,
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };
  
    return (
      <div style={modalStyles} onClick={closeModal}>
        <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    );
  };

  switch (type) {

    case "post_view":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={campaignStyles}>
            <h3 style={{ fontSize: 18 }}>
              Post-view отчёт по кампании - {campaignData.name}
            </h3>
            {Object.keys(campaignData).length !== 0 && <PostViewData campaing_id={campaignData.id} />}
          </Box>
        </ModalStats>
      );
      break;

    case "open_pixel":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={decisionStyles}>
            <h3 style={{ fontSize: 18 }}>
              DMP пиксель для кампании - {campaignData.name}
            </h3>
            <p style={{ marginTop: 5 }}>Разместите HTML код пикселя в пределах тега &lt;head&gt; <span style={{ fontSize: 12 }}>Здесь</span> &lt;/head&gt;. </p>
            {Object.keys(campaignData).length !== 0 && <CodeBlock campaing_id={campaignData.id} />}
          </Box>
          
        </ModalStats>
      );
      break;

    case "dmp_stats":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={decisionStyles}>
            <h3 style={{ fontSize: 18 }}>
              Статистика с Programmatic DMP по кампании - {campaignData.name}
            </h3>
            {Object.keys(campaignData).length !== 0 && <PrgStatsData campaignData={campaignData}/>}
          </Box>
          
        </ModalStats>
      );
      break;

    case "open_creative":
      modalContent = (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Закрепленные баннеры
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <div style={{ width: "100%", height: "100%", display: "flex", gap: 10, marginTop: 10}}>
                {
                  creativeData.icon ? (
                    <div style={{ width: 100, height: 100, background: "#dee7ff", border: "1px solid #3f73f9"}}>
                      <img src={`${API_HOST}/${creativeData.icon.banner_path}`} alt="img" style={{ width: 220, height: 220, borderRadius: 10, objectFit: "contain" }} />
                    </div>
                  ) : (
                    <div style={{ width: 100, height: 100, background: "#dee7ff", border: "1px solid #3f73f9"}}></div>
                  )
                }
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                  {
                    creativeData.banner ? (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}>
                        <img src={`${API_HOST}/${creativeData.banner.banner_path}`} alt="img" style={{ width: 340, height: 190, borderRadius: 10, objectFit: "contain" }} />
                      </div>
                    ) : (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}></div>
                    )
                  }
                  {
                    creativeData.overlay ? (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}>
                        <img src={`${API_HOST}/${creativeData.overlay.banner_path}`} alt="img" style={{ width: 340, height: 190, borderRadius: 10, objectFit: "contain" }} />
                      </div>
                    ) : (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}></div>
                    )
                  }
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <h3>{creativeData.head}</h3>
                <p>{creativeData.text}</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <h3>Реферальные ссылки:</h3>
                {
                  creativeData.icon && (
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <p style={{ fontSize: 15, fontWeight: 600 }}>256x256 icon:</p>
                      <p style={{ fontFamily: "monospace" }}>https://api.programmatic.ru/api/v1/staticbanners/{campaignData.id}/icon/PRG</p>
                    </div>
                  )
                }
                {
                  creativeData.banner && (
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <p style={{ fontSize: 15, fontWeight: 600 }}>600x600 banner:</p>
                      <p style={{ fontFamily: "monospace" }}>https://api.programmatic.ru/api/v1/staticbanners/{campaignData.id}/banner/PRG</p>
                    </div>
                  )
                }
                {
                  creativeData.overlay && (
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <p style={{ fontSize: 15, fontWeight: 600 }}>1080x607 overlay:</p>
                      <p style={{ fontFamily: "monospace" }}>https://api.programmatic.ru/api/v1/staticbanners/{campaignData.id}/overlay/PRG</p>
                    </div>
                  )
                }
              </div>
            </div>
          </Box>
        </Modal>
      )
      break;
    
      case "open_campaign":
        modalContent = (
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={campaignStyles}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Данные РК
              </Typography>
              <Box
                style={{ gap: 20, marginBottom: 30, marginTop: 10 }}
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                {Object.keys(campaignData).length !== 0 && <CampaignData data={campaignData}/>}
              </Box>
            </Box>
          </Modal>
        )
        break;

    default:
      modalContent = null;
  }

  return modalContent;
};

function Dmp() {
  const [filterArr, setFilter] = useState([]);
  const [arrCampaigns, setCampaigns] = useState([]);
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [creativeData, setCreativeData] = useState({});
  const [campaignData, setCampaignData] = useState({});
  
  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/active`, {
        method: "GET",
        headers: myHeaders,
      });
      if (res.ok) {
        const data = await res.json();
        setCampaigns(data);
        setFilter(data);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "date":
            if (value === "") return acc;
            return filterDate(value, acc);
          case "full_name":
            if (value === "") return acc;
            return filterEmploye(value, acc);
          default:
            return acc;
        }
      },
      [...arrCampaigns]
    );
    setFilter(result);
  }, [filtersValue, arrCampaigns]);

  const fetchCreatives = async (campaign_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}/banners`, {
      method: "GET",
      headers: myHeaders,
    });
    if (res.ok) {
      const data = await res.json();
      setCreativeData(data[0]);
    }
  }

  const fetchCampaign = async (campaign_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (res.ok) {
      const data = await res.json();
      setCampaignData(data);
      return data;
    }
  }

  const columns = [
    {
      Header: "Название РК",
      accessor: "campaign_name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сайт",
      accessor: "site",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Почта клиента",
      accessor: "email",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата запуска РК",
      accessor: "started_date",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Статус в SSP",
      accessor: "status_on_ssp",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Открыть креативы",
      accessor: "open_creative",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Открыть РК",
      accessor: "open_campaign",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Открыть пиксель",
      accessor: "open_pixel",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Статистика с DMP",
      accessor: "dmp_stats",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Post-view отчёты",
      accessor: "post_view",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Скачать отчёт краткосрочных сегментов",
      accessor: "short_segment",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
  ];

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };

  const downloadShortSegmentReport = async (campaign_id) => {
    if (!campaign_id) return;
    const campaign = await fetchCampaign(campaign_id);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/csv");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const siteDomain = campaign.site.match(/^https?:\/\/(?:www\.)?([^\/?]+)/i)?.[1];
    const res = await fetch(`${API_HOST}/api/v1/admin/short-live-segments/${siteDomain}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (res.ok) {
      const data = await res.text();
      const csvContent = "phone,email,external_id\n" + data;
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `report-${siteDomain}.csv`;
      document.body.appendChild(a);
      a.click();
      
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }

  // function download(content, filename, contentType)
  // {
  //     if(!contentType) contentType = 'application/octet-stream';
  //         var a = document.createElement('a');
  //         var blob = new Blob([content], {'type':contentType});
  //         a.href = window.URL.createObjectURL(blob);
  //         a.download = filename;
  //         a.click();
  // }

  function getTableData() {
    return filterArr.map((el) => {
      return {
        campaign_name: <div className="td">{el.name}</div>,
        email: <div className="td">{el.campaign_creator_email}</div>,
        site: <div className="td">{el.site}</div>,
        started_date: <div className="td">{dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")}</div>,
        status_on_ssp: <div className="td">Идёт трафик</div>,
        open_creative: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              fetchCreatives(el.id)
              setOpenModal(true)
              setModalType("open_creative")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть
          </button>
        ),
        open_campaign: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              setOpenModal(true)
              setModalType("open_campaign")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть
          </button>
        ),
        open_pixel: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              setOpenModal(true)
              setModalType("open_pixel")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        ),
        dmp_stats: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              setOpenModal(true)
              setModalType("dmp_stats")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        ),
        post_view: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              setOpenModal(true)
              setModalType("post_view")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        ),
        short_segment: (
          <button
            onClick={() => {
              downloadShortSegmentReport(el.id)
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Скачать 
          </button>
        )
      };
    });
  }
  return (
    <div className="logs">
      <div className="filters">
        <div className="searchDate">
          <DateFromTo withoutStartDate={true} handleChange={handleDateChange} />
        </div>
      </div>
      <DataTable data={getTableData()} columns={columns} />
      <OpenModal openModal={openModal} setOpenModal={setOpenModal} type={modalType} creativeData={creativeData} campaignData={campaignData}/>
    </div>
  );
}

export default Dmp;
