import { ACTIONS } from "./actionTypes";

const initialState = {
  list: [],
};

export const notificationsReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ACTIONS.ENQUEUE_SNACKBAR:
      return {
        ...state,
        list: [
          ...state.list,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case ACTIONS.CLOSE_SNACKBAR:
      return {
        ...state,
        list: state.list.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };

    case ACTIONS.REMOVE_SNACKBAR:
      return {
        ...state,
        list: state.list.filter(
          (notification) => notification.key !== action.key
        ),
      };
    default:
      return state;
  }
};
