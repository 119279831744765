import { user as actionsType } from "../actionsType";
import { initialState } from "./state";

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsType.USER_SET_INFO:
      return {
        ...state,
        userInfo: payload,
      };
    case actionsType.SET_USER_DATA:
      return {
        ...state,
        ...payload,
      };
    case actionsType.USER_SET_REQUISITES:
      return {
        ...state,
        userRequisites: payload,
      };
    default:
      return state;
  }
};

export default reducer;
