import { Box, Modal, Typography } from "@material-ui/core";
import { API_HOST } from "../../../configs/main";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

function DeleteModal({ modal, handleCloseEdit, campaing }) {
  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/${campaing.id}`, {
      method: "delete",
      headers: myHeaders
    })
    .then(response => response.json().then(data => ({status: response.status, body: data})))
    .then(response => {
      if (response.status === 200) {
        enqueueSnackbar("Компания успешно удалена", { variant: "success",autoHideDuration: 1000,anchorOrigin:{ vertical: "top", horizontal: "right" } })
      } else {
        enqueueSnackbar(response.body.detail, { variant: 'error',autoHideDuration: 1000,anchorOrigin:{ vertical: "top", horizontal: "right" } })
      }
    })
    .catch((error)=> enqueueSnackbar(error.message, { variant: 'error',autoHideDuration: 1000,anchorOrigin:{ vertical: "top", horizontal: "right" } }))
    .finally(
      setTimeout(() => {
        handleCloseEdit()
      }, 300)
    )
  }

  return (
    <Modal
      open={modal}
      onClose={handleCloseEdit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Удалить компанию
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{
            marginBottom: 20,
            marginTop: 20,
            color: "#42454C",
            opacity: "70%",
          }}
        >
          Название кампании: {campaing?.name}<br/>
          Почта клиента: {campaing?.campaign_creator_email}
        </Typography>
        <div className="buttonGroup">
          <div
            className="resetButton"
            onClick={() => {
              handleCloseEdit();
            }}
          >
            Отменить
          </div>
          <div
            className="confirmButton"
            onClick={() => {
              handleDelete();
            }}
          >
            Подтвердить
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default DeleteModal;
