/* eslint-disable no-empty-pattern */
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { FormControl, FormHelperText, TextField } from "@mui/material";

const StyledHelperText = styled(FormHelperText)(({}) => ({
  background: "orange",
  width: "fit-content",
  position: "absolute",
  right: 0,
  top: 0,
  margin: 0,
  padding: "2px 5px",
  borderTopRightRadius: "5px",
}));

export function BannersFormat({ values }) {
  return (
    <Stack width="630px">
      <FormControl fullWidth>
        <TextField
          className="cpm_input"
          label="Ссылка"
          name="link"
          value={values.values.link}
          onChange={values.handleChange}
        />
        {Boolean(values.touched.link && values.errors.link) && (
          <StyledHelperText>Введите ссылку</StyledHelperText>
        )}
      </FormControl>
    </Stack>
  );
}
