import { makeStyles } from "@material-ui/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect } from "react";
import usePreferences from "../../data/usePreferences";

const useStyles = makeStyles({
  root: {
    height: 600,
    width: 550,
    overflow: "auto",
  },
})

export default function ControlledTreeView({ edit = false, value, onChange }) {
  let { preferences } = usePreferences()
  const pref = preferences?.interests_soc_dem.concat(preferences?.interests)
  // console.log("Preferences ", preferences)
  const INTERESTS={
    "id": "0",
    name: "root node",
    children: pref
  };
  const classes = useStyles();
  const [selected, setSelected] = React.useState(value);

  useEffect(() => onChange(selected), [selected]);

  function getChildById(node, id) {
    let array = [];

    function getAllChild(nodes) {
      if (nodes === null) return [];
      array.push(nodes.id);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(nodes, id) {
      if (nodes.id === id) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node) => {
          // eslint-disable-next-line no-extra-boolean-cast
          if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, id));
  }

  function getOnChange(checked, nodes) {
    const allNode = getChildById(INTERESTS, nodes.id);
    let array = checked
      ? [...selected, ...allNode]
      : selected.filter((value) => !allNode.includes(value));

    setSelected(array);
  }
  
  const RenderTreeWithCheckboxes = (nodes) => {
    return (
      <TreeItem
        key={nodes.id}
        nodeId={(nodes.id).toString()}
        label={
          <FormControlLabel
            control={
              <Checkbox
                checked={selected.some((item) => item === nodes.id)}
                onChange={edit ? edit : (event) =>
                  getOnChange(event.currentTarget.checked, nodes)
                }
              />
            }
            label={<>{nodes.name}</>}
            key={nodes.id}
          />
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => RenderTreeWithCheckboxes(node))
          : null}
      </TreeItem>

    );
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {INTERESTS.children?.map((node) => RenderTreeWithCheckboxes(node))}
    </TreeView>
  );
}

