import "./Catalogs.scss";
import { Typography } from "../../ui";
import useRegions from '../../data/useRegions';
import usePreferences from "../../data/usePreferences";

function Catalogs({ segment }) {
  const { regions } = useRegions();
  const { preferences } = usePreferences();
  // console.log(preferences)
  //console.log(segment)
  if (!segment) {
    //console.log("No segment")
    return <> </>;
  }

  function printsex(arr){
    let str = ""
    if (arr?.length === 2) {
      str+="Женский/Мужской"
    }
    else if (arr?.length === 1 && arr[0]==="male") {str+="Мужской"}
    else if (arr?.length === 1 && arr[0]==="female") {str+="Женский"}
    return str
  }

  function printages(arr){
    let str = ""
    str+=`Возраст от ${arr?.from} до ${arr?.to}`
    //if (arr.exclude?.length !== 0) str += `, исключая ${arr.exclude}`
    return str
  }

  function printallgeo(arr){
    if (arr > 0){
      const namereg = regions?.filter(elem => elem.id === arr)
      if (namereg){
        const reg=namereg[0]
        return (<button className="catalog">
                <Typography >
                  {reg?.name}
                </Typography>
                </button>)
      }
    }
    else if (arr < 0) {
      const namereg = regions?.filter(elem => elem.id === Math.abs(arr))
      if (namereg) {
      return (<button className="catalog2">
                <Typography>
                {namereg[0].name}
                </Typography>
                </button>)}
    }
    else console.log("wrong") 
    
  }

  function printallinterests(arr){
    //console.log(arr)
    const pref = preferences?.interests?.filter((elem => elem.id === (arr)))
    // console.log("Pref",pref)
    if (pref && pref[0]) {return (<button className="catalog">
                <Typography><div className="body1">
                {pref[0]?.name}</div>
                </Typography>
                </button>)}
    let name = []
    preferences?.interests?.map((elem => elem.children?.map(el => (el.id === (arr)) ? name = el.name : "")))
    //console.log("Child",name)
    if (name) {return (<button className="catalog">
                <Typography><div className="body1">
                {name}</div>
                </Typography>
                </button>)}
  }

  function printall_interests_soc_dem(arr){
    // console.log(arr)
    // console.log(preferences,arr)
    const pref = preferences?.interests_soc_dem?.filter((elem => elem.id === (arr)))
    // console.log("pref", pref)
    if (pref && pref[0]) {return (<button className="catalog">
                <Typography><div className="body1">
                {pref[0].name}</div>
                </Typography>
                </button>)}
    let name = []
    preferences?.interests_soc_dem?.map((elem => elem.children?.map(el => (el.id === (arr)) ? name = el.name : "")))
    // console.log(name)
    if (name) {return (<button className="catalog">
                <Typography><div className="body1">
                {name}</div>
                </Typography>
                </button>)}
  }

  return (segment && 
    <>
      <div className="buttons">
                {(segment.sex !== null) && 
                <button className="catalog">
                <Typography color="dark-gray">
                  <div className="body1">
                  {printsex(segment.sex)}
                  </div>
                </Typography>
                </button>
                }
                {(segment.age !== null) && (
                <button className="catalog">
                <Typography variant="body1" color="dark-gray">
                  <div className="body1">
                  {printages(segment.age)}
                  </div>
                </Typography>
                </button>)
                }
                {(segment.regions !== null) && 
                  segment.regions?.map(elem => <div key={elem}>{printallgeo(elem)}</div>).slice(0, 3)
                }
                {(segment.interests !== null) && 
                  segment.interests?.map(elem => <div key={elem}>{printallinterests(elem)}</div>).slice(0, 3)
                }
                {(segment.interests_soc_dem !== null) && 
                  segment.interests_soc_dem?.map(elem => <div key={elem}>{printall_interests_soc_dem(elem)}</div>).slice(0, 3)
                }
              </div>
    </>
  );
}

export default Catalogs;