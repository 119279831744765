export const countersSelector = {
  all_object: (state) => state.counters,
  modalComponent: (state) => state.counters.modalComponent,
  counters_list: (state) => state.counters.countersList,
  goalsOfCounter: (counterId, state) => {
    const counter = state.counters.countersList.find(
      (counter) => counter.id === counterId
    );
    return counter ? counter.goals : [];
  },
};
