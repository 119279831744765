import "./SelectCounter.scss";
import cx from "classnames";

import { Dropdown } from "../";
import { ReactComponent as DeltaIcon } from "../../assets/images/arrow-gray.svg";


function SelectCounter({ placeholder, variant = "white", options, onChange, value }) {

  const handleSelect = (obj) => {
    if (onChange) onChange(obj.id);
  };

  if (window.outerWidth < 768) variant = "gray"

  return (
    <div className={cx("SelectCounter", variant)}>
      <Dropdown
        button={
          <div className="select">
            {value!=="" ? options.map((item) => 
            item.id=== value ? <span key={item.id}>{item.name}</span> : "")
            : (
              <div className="placeholder-value">
                <div className="placeholder">{placeholder}</div>
                <DeltaIcon />
              </div>
            )} 
          </div>
        }
      >
        {options &&
          options.map((obj, index) => {
            return (
              <button
                key={index}
                type="button"
                onClick={ () => handleSelect(obj)}
              >
                {obj.name}
              </button>
            );})
          }
      </Dropdown>
    </div>
  );
}

export default SelectCounter;

