import { client } from "../../utils/network";
import { ACTIONS } from "./actionTypes";
import { waiter } from "../../modules/Counters/helper";
import { enqueueSnackbar } from "../notifications/actions";

const DELAY_FOR_REQUEST = 1000;

const setLoading = (value = true) => ({
  type: ACTIONS.SET_LOADING,
  payload: value,
});
const setError = (err) => ({
  type: ACTIONS.SET_ERROR,
  payload: err,
});
const updateCounter = (payload) => ({
  type: ACTIONS.UPDATE_COUNTER,
  payload,
});

export const saveCounter = (counterData, counterId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    if (counterId == null) {
      const { data } = await waiter(
        client.post,
        ["/api/v1/metrics/counters", counterData],
        DELAY_FOR_REQUEST
      );
      const result = { ...data, goals: [] };
      return dispatch(addCounter(result));
    }

    const { data } = await waiter(
      client.put,
      [`/api/v1/metrics/counters/${counterId}`, counterData],
      DELAY_FOR_REQUEST
    );
    return dispatch(updateCounter({ counterId, data }));
  } catch (err) {
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
    dispatch(getCounters());
  }
};

const setCounters = (list) => ({
  type: ACTIONS.SET_COUNTERS,
  payload: list,
});

const addCounter = (counter) => ({
  type: ACTIONS.ADD_COUNTER,
  payload: counter,
});

export const getCounters = () => async (dispatch) => {
  try {
    const result = await client.get("/api/v1/ya-metrika/counters");
    dispatch(setCounters(result.data));
  } catch (err) {
    dispatch(setError(err));
  }
};

const deleteCounter = (id) => ({
  type: ACTIONS.DELETE_COUNTER,
  payload: id,
});

export const deleteCounterFromDb = (id) => async (dispatch) => {
  try {
    await waiter(
      client.delete,
      [`/api/v1/metrics/counters/${id}`],
      DELAY_FOR_REQUEST
    );
    dispatch(
      enqueueSnackbar({
        message: "Счётчик успешно удалён",
        options: {
          variant: "success",
        },
      })
    );
    dispatch(deleteCounter(id));
  } catch (err) {
    dispatch(
      enqueueSnackbar({
        message: "Ошибка MT",
        options: {
          variant: "error",
        },
      })
    );
    dispatch(setError(err));
  }
};

export const setComponentToModal = (component, title) => ({
  type: ACTIONS.SET_COMPONENT_TO_MODAL,
  payload: { component, title },
});
export const addGoal = (goal) => ({
  type: ACTIONS.ADD_GOAL,
  payload: goal,
});
export const updateGoal = (goal) => ({
  type: ACTIONS.UPDATE_GOAL,
  payload: goal,
});

export const saveGoal = (goalData, counterId, goalId) => async (dispatch) => {
  try {
    dispatch(setLoading());

    if (goalId == null) {
      const { data } = await waiter(
        client.post,
        [`/api/v1/metrics/counters/${counterId}/goals`, goalData],
        DELAY_FOR_REQUEST
      );
      return dispatch(addGoal({ data, counterId }));
    }

    const { data } = await waiter(
      client.put,
      [`/api/v1/metrics/counters/${counterId}/goals/${goalId}`, goalData],
      DELAY_FOR_REQUEST
    );
    return dispatch(updateGoal({ data, counterId }));
  } catch (err) {
    dispatch(
      enqueueSnackbar({
        message: "Ошибка MT",
        options: {
          variant: "error",
        },
      })
    );
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
  }
};
