import { useEffect } from "react";
import CompanyContainer from "./container/CompanyContainer";
import "./styles/company.css";
import { resetEditCampaign } from "../../redux/createNewCompany/actions";
import { useDispatch } from "react-redux";

const AddCompany = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetEditCampaign());
    };
  }, []);
  return (
    <div className="first">
      <CompanyContainer />
    </div>
  );
};

export default AddCompany;
