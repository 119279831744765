import { ACTIONS } from "./actionTypes";

const initialState = {
  isLoading: false,
  countersList: [],
  modalComponent: null,
  error: "",
};

export const countersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SAVE_COUNTER:
      return state;
    case ACTIONS.SET_LOADING:
      return { ...state, isLoading: payload };
    case ACTIONS.SET_COUNTERS:
      return { ...state, countersList: payload };
    case ACTIONS.ADD_COUNTER:
      return { ...state, countersList: state.countersList.concat(payload) };
    case ACTIONS.ADD_GOAL:
      return {
        ...state,
        countersList: state.countersList.map((counter) => {
          if (counter.id === payload.counterId) {
            return { ...counter, goals: counter.goals.concat(payload.data) };
          }
          return counter;
        }),
      };
    case ACTIONS.SET_COMPONENT_TO_MODAL:
      return { ...state, modalComponent: payload };
    case ACTIONS.RESET_COMPONENT_MODAL:
      return { ...state, modalComponent: null };
    case ACTIONS.DELETE_COUNTER:
      return {
        ...state,
        countersList: state.countersList.filter(
          (counter) => counter.id !== payload
        ),
      };
    case ACTIONS.SET_ERROR:
      return { ...state, error: payload };
    default:
      return state;
  }
};
