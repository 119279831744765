import "./SelectStatus.scss";
import cx from "classnames";

import { Dropdown } from "../";
import { ReactComponent as DeltaIcon } from "../../assets/images/arrow-gray.svg";
import { ReactComponent as CheckboxIcon } from "../../assets/images/checkbox.svg";

function SelectStatus({ placeholder, variant = "white", options, onChange }) {
  const handleSelect = (obj) => {
    const newSelectValue = obj.selected
      ? options.filter(({ type, selected }) => type !== obj.type && selected)
      : options.filter(({ selected }) => selected).concat(obj);
      console.log(newSelectValue);
    if (onChange) onChange(newSelectValue);
  };
  const selected = options.filter(({selected}) => selected)
  if (window.outerWidth < 768) variant = "gray"
  return (
    <div className={cx("Select", variant)}>
      <Dropdown
        button={
          <div className="select">
            {selected.length === 0 && (
              <div className="placeholder-value">
                <div style={(window.outerWidth > 1000) ? {fontSize:13} : {fontSize:"12px"}} className="placeholder">{placeholder}</div>
                <DeltaIcon />
              </div>
            )}
            {selected.map((obj, index) => (
              <span key={index}>
                {obj.label}
                {index + 1 !== selected.length && ","}
              </span>
            ))}
          </div>
        }
      >
        {options &&
          options.map((obj, index) => {
            return (
              <button
                key={index}
                type="button"
                onClick={() => handleSelect(obj)}
              >
                <span
                  className={cx("rectangle", {
                    selected: obj.selected,
                  })}
                >
                  <CheckboxIcon
                    className={cx("rectangle-icon", {
                      selected: "rectangle-icon__visible",
                    })}
                  />
                </span>
                {obj.label}
              </button>
            );
          })}
      </Dropdown>
    </div>
  );
}

export default SelectStatus;

