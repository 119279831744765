import { useMemo } from "react";
import { Table } from "../";
import { Loading } from "../../ui";



function ReplenishmentTable({ data, isLoading }) {
  const columns = useMemo(
    () => [
      {
        Header: "Дата",
        accessor: "Date",
        disableGroupBy: true,
        headerStyle: {
          width: 100,
        },
        cellStyle: {
          width: 100,
        },
      },
      {
        Header: "Операция",
        accessor: "Operations",
        disableSortBy: true,
        disableGroupBy: true,
        headerStyle: {
          width: 400,
        },
        cellStyle: {
          width: 400,
        },
      },
      {
        Header: "Сумма без НДС",
        accessor: "AmountExcludingVAT",
        disableSortBy: true,
        disableGroupBy: true,
        headerStyle: {
          width: 140,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        },
        cellStyle: {
          width: 140,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        },
      },
      {
        Header: "Сумма с НДС",
        accessor: "SumIncludingVAT",
        disableSortBy: true,
        disableGroupBy: true,
        headerStyle: {
          width: 140,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        },
        cellStyle: {
          width: 140,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        },
      },
      {
        Header: "Документы",
        accessor: "Documents",
        disableSortBy: true,
        disableGroupBy: true,
        headerStyle: {
          width: 100,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        },
        cellStyle: {
          width: 100,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        },
      },
      {
        Header: "Статус",
        accessor: "Status",
        disableSortBy: true,
        disableGroupBy: true,
        headerStyle: {
          width: 120,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        },
        cellStyle: {
          width: 120,
          color: "rgba(66, 69, 76, 0.6)",
          borderRight: "none",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        },
      },
    ],
    []
  );
  return (
    <div className="ReplenishmentTable">
      {
        isLoading ?
          <Loading /> : // todo: Fix position of loader
          data.length ?
            <Table columns={columns} data={data} /> :
            "Не найдено"  // todo: Add if not found
      }
    </div>
  );
}

export default ReplenishmentTable;
