export const getIsEmptyObject = (obj) => {
    if (!obj) {
      return true;
    }
    return Object.keys(obj).length === 0;
  };

export const genderNormalizer = (genderInfo) => {
    switch(genderInfo) {
        case "ALL":
            return "Муж/Жен";
        case "MALE":
            return "Муж";
        default:
            return "Жен";
    }
}


export const agesNormalizer = (agesInfo) => {
    if (agesInfo.ages_before && agesInfo.ages_after) {
        return `От ${agesInfo.ages_before} до ${agesInfo.ages_after} лет`;
    } else {
        return `От ${agesInfo.ages_before} лет`;        
    }
}