import { Box } from "@material-ui/core";
import SendIcon from "@mui/icons-material/Send";
import {
  Checkbox,
  Grid,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Segment } from "../../../widgets";
import { CreateSegment } from "../../../widgets/Segment";
import { updateCampaign } from "../../../redux/createNewCompany/actions";
import { LoadingButton } from "@mui/lab";
import "./Audience.scss"

const ITEM_HEIGHT = 66;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DataEqual = (objA, objB) => JSON.stringify(objA) === JSON.stringify(objB)

const Audience = (props) => {
  //console.log(props)
  let SEGMENTS_INITIAL_STATE = ""
  if (props.data !== null) {
    SEGMENTS_INITIAL_STATE = props.data;
  }

  // todo: Change initial state to editing task
  const [selectedSegments, setSelectedSegments] = useState(
    SEGMENTS_INITIAL_STATE
  );
  const [tempSegments, setTempSegments] = useState(null);

  const [isCreateSegmentOpen, setIsCreateSegmentOpen] = React.useState(false);
  const [isChoiceSegmentOpen, setIsChoiceSegmentOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const segments = useSelector((state) => state.segments.segments);
  const dispatch = useDispatch();

  // Choice segments dialog handlers
  const handleOpenChoiceSegment = () => {
    setTempSegments(selectedSegments);
    setIsChoiceSegmentOpen(true);
  };
  const handleCancelChoiceSegment = () => {
    setSelectedSegments(tempSegments);
    setTempSegments(null);
    setIsChoiceSegmentOpen(false);
  };
  const handleApplyChoiceSegment = () => {
    setTempSegments(null);
    setIsChoiceSegmentOpen(false);
  };
  const handleResetChoiceSegment = () => {
    setSelectedSegments(SEGMENTS_INITIAL_STATE);
    setTempSegments(null);
    setIsChoiceSegmentOpen(false);
  };
  const handleChangeTab = () => props.handleChange("event", "6");
  const handleSubmit = async () => {
    setIsLoading(true);
    if (!DataEqual(props.data, selectedSegments)) {
      await dispatch(
        updateCampaign(
          selectedSegments,
          props.campaignID,
          "new_segment_id",
          handleChangeTab
        )
      )
    } else handleChangeTab()
    setIsLoading(false);
  };

  const getSelectedNames = (ids) => {
    const selected = segments.filter((segment) => ids.includes(segment.id));
    const names = selected.map((segment) => segment.name);
    return names;
  };

  return (
    <div className="audience" style={{ marginLeft: "20px" }}>
      <div className="title-text" style={{ marginBottom: "50px" }}>
        <h2>Aудитория</h2>
        <p>3000+ Сегментов - Показывайте рекламу Только Тем, Кто Вам Нужен</p>
      </div>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Button className="addButton" disabled={props.disable} onClick={handleOpenChoiceSegment}>
            Выбрать из сохраненных сегментов
          </Button>
          <Dialog disableEscapeKeyDown open={isChoiceSegmentOpen}>
            <DialogTitle>Сегменты</DialogTitle>
            <DialogContent>
              <div className="modalSelect">
                <FormControl sx={{ m: 1, minWidth: 120, width: 350 }}>
                  <InputLabel id="choice-segments-label">
                    Выбрать сегменты
                  </InputLabel>
                  <Select
                    labelId="choice-segments-label"
                    id="choice-segments"
                    value={selectedSegments}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSelectedSegments(value);
                    }}
                    input={<OutlinedInput label="Выбрать сегменты" />}
                    renderValue={(selected) =>
                      getSelectedNames(selected).join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {segments.map((audit) => (
                      <MenuItem key={audit.id} value={audit.id}>
                        <Checkbox
                          checked={selectedSegments.indexOf(audit.id) > -1}
                        />
                        <ListItemText primary={audit.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleResetChoiceSegment}>Сбросить</Button>
              <Button onClick={handleCancelChoiceSegment}>Отмена</Button>
              <Button onClick={handleApplyChoiceSegment}>Ok</Button>
            </DialogActions>
          </Dialog>
        </FormControl>
      </Box>
      <Grid
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="createButton"
              onClick={() => setIsCreateSegmentOpen(!isCreateSegmentOpen)}
              variant="text"
              disabled={props.disable}
            >
              {isCreateSegmentOpen ? "Скрыть форму" : "Создать новый сегмент"}
            </Button>
          </Box>
          {isCreateSegmentOpen && (
            <CreateSegment onClose={() => setIsCreateSegmentOpen(false)} />
          )}
          {segments.map((segment) => {
            const isChosen = selectedSegments.includes(segment.id);
            const seg = {
              segment: segment,
              status: isChosen ? "CHOSEN" : "NOT-CHOSEN",
              disable: props.disable,
            };

            return (
              <Grid key={segment.id} sx={{ marginTop: "20px", width: 1200 }}>
                <Segment {...seg} />
              </Grid>
            );
          })}
        </FormControl>
        <Grid
          container
          sx={{
            m: 1,
            marginTop: "50px",
            minWidth: 120,
          }}
        >
          <Grid item xs={4}>
            <Link to="/">
              <Button variant="outlined">Отмена</Button>
            </Link>
          </Grid>

          <Grid item xs={5.4}></Grid>

          <Grid
            item
            container
            xs={2.6}
            style={{
              backgroundColor: "transparent",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip
              title={!selectedSegments.length ? "Выберите сегмент" : ""}
              placement="top-end"
            >
              <span>
                <LoadingButton
                  loading={isLoading}
                  loadingPosition="end"
                  endIcon={<SendIcon />}
                  variant="contained"
                  sx={{
                    marginLeft: "32px",
                  }}
                  onClick={() => handleSubmit()}
                  disabled={!selectedSegments.length}
                >
                  {isLoading ? "Сохранение" : "Далее"}
                </LoadingButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Audience;
