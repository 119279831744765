import { SEG_ACTIONS } from "./actionTypes";

const initialState = {
  segments: [],
  loading: true,
  error: "",
  id: "",
  errorPostSegments: "",
  errorCreateList: "",
  list: null,
  editSegment: null,
};

export const createNewSegmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEG_ACTIONS.CREATE_SEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEG_ACTIONS.SET_EDIT_SEGMENT:
      return {
        ...state,
        id: action.payload,
        editSegment: state.segments.find(
          (segment) => segment.id === action.payload
        ),
      };
    case SEG_ACTIONS.RESET_EDIT_SEGMENT:
      return {
        ...state,
        id: "",
        editSegment: null,
      };
    case SEG_ACTIONS.CREATE_SEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload,
      };
    case SEG_ACTIONS.CREATE_SEGMENT_FAILURE:
      return {
        ...state,
        loading: false,
        errorPostSegments: action.payload,
      };
    case SEG_ACTIONS.GET_SEGMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEG_ACTIONS.GET_SEGMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        segments: action.payload,
        error: "",
      };
    case SEG_ACTIONS.GET_SEGMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        segments: [],
        error: action.payload,
      };
    case SEG_ACTIONS.UPDATE_SEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEG_ACTIONS.UPDATE_SEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEG_ACTIONS.UPDATE_SEGMENT_FAILURE:
      return {
        ...state,
        loading: false,
        errorPostSegments: action.payload,
      };
    case SEG_ACTIONS.CREATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEG_ACTIONS.CREATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case SEG_ACTIONS.CREATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorCreateList: action.payload,
      };
    default:
      return state;
  
  }
};