export const filterWithCustomSign = (firstValue, secondValue, sign) => {
  switch (sign) {
    case ">":
      return firstValue > secondValue;
    case "<":
      return firstValue < secondValue;
    case ">=":
      return firstValue >= secondValue;
    case "<=":
      return firstValue <= secondValue;
    case "=":
      return firstValue === secondValue;
    default:
      return firstValue > secondValue;
  }
};
export const filterCompanyOnDate = (aCompanies, oDate) => {
  const tempDate = {
    date_end: oDate.date_end || new Date(3000, 10, 10),
    date_start: oDate.date_start || new Date(3, 10, 10),
  };
  const result = aCompanies.filter((company) => {
    if (!oDate.date_start && !oDate.date_end) return true;
    // if (!company?.media_plan?.date_end || !company?.media_plan?.date_start)
    //   return false;

    const cDend = new Date(company?.media_plan?.date_end ?? company.created_at);
    const cDstart = new Date(
      company?.media_plan?.date_start ?? company.created_at
    );
    const isIntersectionLeft =
      tempDate.date_start >= cDstart && tempDate.date_start <= cDend;
    const isIntersectionRight =
      tempDate.date_end >= cDstart && tempDate.date_end <= cDend;
    const isInside =
      tempDate.date_start <= cDstart && tempDate.date_end >= cDend;
    if (isIntersectionLeft) return true;
    if (isIntersectionRight) return true;
    if (isInside) return true;

    return false;
  });
  return result;
};

export const MAIN_INDICATORS = [
  {
    name: "all",
    title: "Выбрать все",
    computedValue: (values) => Object.values(values).every(Boolean),
    cb: (values, setFieldValue) => {
      const valueForAll = !Object.values(values).every(Boolean);
      Object.keys(values).forEach((item) => {
        setFieldValue(item, valueForAll);
      });
    },
  },
  { name: "expense", title: "Расход, ₽", value: true },
  { name: "ctr", title: "CTR, %", value: true },
  { name: "cpc", title: "CPC, ₽", value: true },
  { name: "income", title: "Доход, ₽", value: true },
  { name: "romi", title: "ROMI, %", value: true },
  { name: "drr", title: "ДРР, %, %", value: true },
];

export const sortCampaigns = (arr, type) => {
  switch (type) {
    case "click":
      return [...arr].sort(
        (a, b) =>
          b.campaign_stat?.daily_statistic?.clicks -
          a.campaign_stat?.daily_statistic?.clicks
      );
    case "click asc":
      return [...arr].sort(
        (a, b) =>
          a.campaign_stat?.daily_statistic?.clicks -
          b.campaign_stat?.daily_statistic?.clicks
      );
    case "shows":
      return [...arr].sort(
        (a, b) =>
          a.campaign_stat?.daily_statistic?.shows -
          b.campaign_stat?.daily_statistic?.shows
      );
    case "coverage":
      return [...arr];
    case "conversion":
      return [...arr].sort(
        (a, b) =>
          a.campaign_stat?.daily_statistic?.conversion -
          b.campaign_stat?.daily_statistic?.conversion
      );
    case "budget":
      return [...arr].sort(
        (a, b) =>
          a.campaign_stat?.budget?.full_budget -
          b.campaign_stat?.budget?.full_budget
      );
    case "created_at":
      return [...arr].sort(
        (a, b) =>
          new Date(b.created_at) -
          new Date(a.created_at)
      );
    case "created_at asc":
      return [...arr].sort(
        (a, b) =>
          new Date(a.created_at) -
          new Date(b.created_at)
      );
    case "full_budget":
      return [...arr].sort(
        (a, b) =>
          b.basic_config.full_budget -
          a.basic_config.full_budget
      );
    case "full_budget asc":
      return [...arr].sort(
        (a, b) =>
          a.basic_config.full_budget -
          b.basic_config.full_budget
      );
    case "daily_budget":
      return [...arr].sort(
        (a, b) =>
          b.basic_config.daily_budget -
          a.basic_config.daily_budget
      );
    case "daily_budget asc":
      return [...arr].sort(
        (a, b) =>
          a.basic_config.daily_budget -
          b.basic_config.daily_budget
      );
    default:
      return arr;
  }
};

export const filterCampaignsOnStatus = (arr, type) => {
  let res = [];
  if(!type.length) return arr;
  type.forEach((el) =>{
    switch (el) {
      case 'active': res = [...res,...arr.filter((el) => el.status === "ACTIVE" && el.broadcast_status === "DELIVERING")]; break;
      case 'moderation': res = [...res,...arr.filter((el) => el.status === "ACTIVE" && el.broadcast_status === "PENDING")]; break;
      case 'error': res = [...res,...arr.filter((el) => el.status === "ACTIVE" && el.broadcast_status === "NOT_DELIVERING")]; break;
      case 'no_active': res = [...res,...arr.filter((el) => el.status === "BLOCKED")]; break;
      case 'archive': res = [...res,arr.filter((el) => el.status === "DELETED")]; break;
      default: res = arr;
    }
  })
  return res
}