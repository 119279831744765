import useSWR  from "swr"

export default function useStatistics(metric_counter,date_start,date_end,selected_campaigns,selected_goals,type) {
    //console.log(metric_counter,selected_campaigns,selected_goals,type, date_start.toISOString().slice(0,10))
    let _selected_goals = [...selected_goals].filter(x => x != "all_goals");

    let api = `api/v1/ya-metrika/counters`
    if (metric_counter && date_start) {
        api=`api/v1/ya-metrika/stat?counter_id=${metric_counter}`
        selected_campaigns?.map(((campaing) => (campaing !== '0') ? api=api.concat(`&campaign_ids=${campaing}`) : ""))
        _selected_goals?.map(((goal) => (goal !== '0') ? api=api.concat(`&goal_ids=${goal}`) : ""))
        api = api.concat(`&date_from=${date_start?.toLocaleDateString('en-CA')}&date_to=${date_end?.toLocaleDateString('en-CA')}&group=${type}`)
    }
    const { data, error, mutate } = useSWR(api)

    const isLoading = !error && !data
    const loggedOut = Boolean(!isLoading && error)

    return {
        data,
        isLoading,
        loggedOut,
        mutate,
        error
    }
}
