/* eslint-disable no-empty-pattern */
import * as React from 'react';
import {
  Typography,
  IconButton,
  Dialog,
  Slide,
  Box,
  styled,
  TextField,
  Stack
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import DateRangePicker from "@mui/lab/DateRangePicker";
import {LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {saveBanner} from "../../../../redux/createBanners/actions";
import {Button} from "../../../../ui";
import { API_HOST } from "../../../../configs/main"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const GridParent = styled('div')(({}) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: (window.outerWidth > 1000) ? '20px' : '5px',
  padding: '20px',
  'div > img, div > video': {
    maxWidth: '100%',
    height: 'auto',
    transition: 'all 0.5s ease-in-out',
    cursor: 'pointer',
    '&.active, &:not(.active):hover': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 6px 2px blue'
    }
  }
}))
const NavBar = styled(Box)(({}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'white',
  padding: (window.outerWidth > 1000) ? '10px 20px' : '5px',
  marginBottom: (window.outerWidth > 1000) ? '20px' : '0px',
}))

const StyledDialog = styled(Dialog)(() => ({
  padding: '0 !important',
  '.MuiPaper-root': {
    padding: (window.outerWidth > 1000) ? '10px' : '5px',
    background: '#ebebeb'
  }
}))

export default function AllImagesDialog({setIsDialogOpen, banners}) {
  const dispatch = useDispatch()
  const [rangeDate, setRangeDate] = useState([null, null]);
  const [searchValue, setSearchValue] = useState('')
  const [activeBanner, setActiveBanner] = useState(-1)
  const handleActiveImage = (index) => index === activeBanner ? setActiveBanner(-1) : setActiveBanner(index)

  const handleSave = () => {
    if (activeBanner === -1) return
    dispatch(saveBanner({existBanner: banners[activeBanner]}))
    setIsDialogOpen(false)
  }

  const handleChange = (event) => {
    setSearchValue(event.target.value)
  }
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const filteredBanners = useMemo(() => {
    setActiveBanner(-1)
    return banners.reduce((acc, cur) => {
      const {banner_path} = cur
      const fileName = banner_path.split('/').slice(-1)[0]
      const isFitFileName = fileName.toLowerCase().includes(searchValue.toLowerCase())
      const isFitDate = (new Date(cur.created_at) > rangeDate[0]) && (!rangeDate[1] || new Date(cur.created_at) < rangeDate[1])
      return isFitFileName && isFitDate ? [...acc, cur] : acc
    }, [])
  }, [banners, rangeDate, searchValue])

  return (
    <StyledDialog
      fullScreen={true}
      open={true}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={(window.outerWidth > 1000) ? {padding: '10px 20px'} : {padding: "5px 5px"}}
    >
      <NavBar>
        <Stack direction="row" height="100%" gap={(window.outerWidth > 1000) ? "20px" : "10px"} alignItems="center">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Период с"
              endText="Период по"
              value={rangeDate}
              onChange={(newValue) => {
                setRangeDate(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} 
                    InputLabelProps={(window.outerWidth > 1000) ? {} : {style: { fontSize: "12px" }}} 
                    InputProps={(window.outerWidth > 1000) ? {} : { style: { fontSize: "12px" }}}
                    size="small"/>
                  <Box sx={(window.outerWidth > 1000) ? {mx: 2, fontSize: 24, fontWeight: 700} : {}}> - </Box>
                  <TextField {...endProps} 
                    InputLabelProps={(window.outerWidth > 1000) ? {} : {style: { fontSize: "12px" }}} 
                    InputProps={(window.outerWidth > 1000) ? {}: { style: { fontSize: "12px" }}} 
                    size="small"/>
                </>
              )}
            />
          </LocalizationProvider>
        </Stack>
        <Stack direction="row" height="100%" gap={(window.outerWidth > 1000) ? "10px" : "5px"} alignItems="center">
          <Typography variant="body1">
            Поиск
          </Typography>
          <TextField 
            InputLabelProps={(window.outerWidth > 1000) ? {} : {style: { fontSize: "12px" }}} 
            InputProps={(window.outerWidth > 1000) ? {} : { style: { fontSize: "12px" }}}
            name="file_name" size="small" value={searchValue} onChange={handleChange}/>
          {activeBanner > -1 && <Button variant="primary" onClick={handleSave}>Сохранить</Button>}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
        </Stack>
      </NavBar>
      <GridParent>
        {filteredBanners.map((banner, index) =>
          <div key={index} className="dialog_image_wrap" onClick={() => handleActiveImage(index)}>
            {
              banner.content_type === 'VIDEO'
              ? (<video controls={true} className={activeBanner === index ? 'active' : ''}
                        src={`${API_HOST}/${banner.banner_path}`}></video>)
              : (<img className={activeBanner === index ? 'active' : ''}
                      src={`${API_HOST}/${banner.banner_path}`}></img>)
            }
          </div>)}
      </GridParent>
    </StyledDialog>
  );
}
