/* eslint-disable no-unsafe-optional-chaining */
import { Box, CircularProgress } from "@material-ui/core";
import CircleIcon from '@mui/icons-material/Circle';
import SettingsIcon from '@mui/icons-material/Settings';
import clsx from "clsx";
import { useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-big.svg";
import { Line, Typography } from "../../ui";
import "./Segment.scss";
import useRegions from '../../data/useRegions';
import usePreferences from "../../data/usePreferences";
import Button from "@mui/material/Button";
import { Checkbox, Grid, ListItemText, MenuItem } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ControlledTreeView from "./PreferencesSet";
import Search from "./SearchSet";
import SegmentMap from "./SegmentMap";
import * as Yup from "yup";

import { updateSegment } from "../../redux/segments/actions";
import Dropzone from "react-dropzone";


const GENDER_TYPES = [
    { value: "male", label: "Мужской" },
    { value: "female", label: "Женский" },
    { value: "both", label: "Учитывать оба" },
];

const FORMIK_INITIAL_VALUES = {
    name: "",
    sex: [],
    age: {
        from: "",
        to: "",
        exclude: [],
    },
    regions: [],
    local_geo: {
        locations: []
    },
    interests: [],
    campaign_list: [],
    search_phrases: {
        phrases: [],
        stop_phrases: [],
    },
    crm: {},
}

const CreateSegmentSchema = Yup.object().shape({
    name: Yup.string().required(),
    sex: Yup.array(),
    age: Yup.object().shape({
        from: Yup.number().min(18).max(100),
        to: Yup.number().min(18).max(100).when(
            "from", (from, field) => from ? field.min(from) : field

        ),
        exclude: Yup.array().of(
            Yup.number().min(18).max(100).required()
        ),
    }),
    income_level: Yup.object().shape({
        income_level_from: Yup.number().min(0),
        income_level_to: Yup.number().min(0).when(
            "income_level_from", (income_level_from, field) => income_level_from ? field.min(income_level_from) : field
        ),
        income_level_exclude: Yup.array().of(
            Yup.number().min(0).required()
        ),
    }),
    search_phrases: Yup.object().shape({
        phrases: Yup.array().of(
            Yup.string().matches(/^[a-zA-Zа-яА-Я0-9\s]*$/)),
        stop_phrases: Yup.array().of(
            Yup.string().matches(/^[a-zA-Zа-яА-Я0-9\s]*$/))
    })
})

const createInitialValues = (data) => {
    if (!data) return FORMIK_INITIAL_VALUES;
    const result = { ...FORMIK_INITIAL_VALUES };
    for (const key in result) {
        if (key in data) {
            result[key] = data[key];
        }
        if (data[key] === null) {
            result[key] = []
        }
    }
    // if (data?.sex) { result.sex = data.sex[0] }
    if (data?.sex) { 
        result.sex = data.sex[0] === "both" ? ["male", "female"] : [data.sex[0]];
    } else {
        result.sex = null;
    }
    if (!data?.age) {
        result.age = {
            from: "",
            to: "",
            exclude: [],
        }
    }
    else if (!data?.age?.from) { result.age.from = "" }
    else if (!data?.age?.to) { result.age.to = "" }
    else if (!data?.age?.exclude) { result.age.exclude = [] }
    if (!data?.search_phrases) {
        result.search_phrases = {
            phrases: [],
            stop_phrases: [],
        }
    } else if (!data?.search_phrases?.phrases) {
        result.search_phrases.phrases = []
    } else if (!data?.search_phrases?.stop_phrases) {
        result.search_phrases.stop_phrases = []
    }
    console.log(result)
    return result;
};

const ITEM_HEIGHT = 66;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Segment(seg) {
    const { regions } = useRegions();
    const { preferences } = usePreferences()
    const segment = seg.segment
    console.log(segment)
    const [openSegment, setOpenSegment] = useState(false);
    const [editSegment, setEditSegment] = useState(false);
    const toggleOpenSegment = () => setOpenSegment(!openSegment);
    const diff = 100 * segment.prediction?.current / segment.prediction?.max
    // eslint-disable-next-line no-unused-vars
    const [isGenderOpen, setIsGenderOpen] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isAgeOpen, setIsAgeOpen] = React.useState(false);
    const [isGeoOpen, setIsGeoOpen] = React.useState(false);
    const [isPreferencesOpen, setIsPreferencesOpen] = React.useState(false);
    const [isCampaignOpen, setIsCampaignOpen] = React.useState(false);
    const [isPhrasesOpen, setIsSearchHistoryOpen] = React.useState(false);

    const campaigns = useSelector(state => state.createNewCompany.campaigns);
    const dispatch = useDispatch();
    function nonEmptyObject(obj) {
        for (const propName in obj) {
            if (
                obj.propName === null ||
                obj[propName] === undefined ||
                obj[propName] === "" ||
                obj[propName].length === 0
            ) {
                //console.log(propName)
                delete obj[propName];
            }
        }
        return obj;
    }

    const formik = useFormik({
        initialValues: createInitialValues(segment),
        validationSchema: CreateSegmentSchema,
        onSubmit: (values) => {
            console.log(values)
            const finalvalue = {
                name: values?.name,
                sex: [],
                age: {
                    from: "",
                    to: "",
                    exclude: [],
                },
                local_geo: {},
                regions: [],
                interests: [],
                interests_soc_dem: [],
                campaign_list: { campaign_ids: [] },
                search_phrases: {
                    phrases: [],
                    stop_phrases: []
                }
            }
            if (values.sex !== undefined && values.sex.length > 0) { 
                finalvalue.sex = values.sex[0] === "both" ? ["male", "female"] : [values.sex[0]];
            } else {
                finalvalue.sex = null;
            }
            let flag = true
            if (values.age.from !== "" || values.age.to !== "" || values.age.exclude.length !== 0) {
                finalvalue.age.from = values.age?.from
                finalvalue.age.to = values.age?.to
                finalvalue.age.exclude = values.age?.exclude
            } else {
                delete finalvalue.age
                flag = false
            }
            if (values.age?.from === "" && flag === true) { delete finalvalue.age.from }
            if (values.age?.to === "" && flag === true) { delete finalvalue.age.to }
            if (values.age?.exclude.length === 0 && flag === true) { delete finalvalue.age.exclude }

            if (values.local_geo) {
                finalvalue.local_geo.locations = values.local_geo.locations;
                finalvalue.local_geo.visit_type = values.local_geo.visit_type;
              } else {
                delete finalvalue.local_geo;
              }
            if (values.regions.length !== 0) finalvalue.regions = values?.regions
            if ((values.local_geo) && (values.regions.length !== 0)) {
                if (isRegions) { delete finalvalue.local_geo }
                else { delete finalvalue.regions }
            }
            if (values.interests.length !== 0) {
                // console.log(values.interests)
                for (var i = 0; i < values.interests.length; i++) {
                    // console.log(values.interests[i])
                    if (preferences?.interests_soc_dem
                        .find((p) => (p.id === values.interests[i]) ||
                            (p.children?.find(el => (el.id === (values.interests[i])))))) {
                        finalvalue.interests_soc_dem.push(values?.interests[i])
                    }
                    else if (preferences?.interests
                        .find((p) => (p.id === values.interests[i]) ||
                            (p.children?.find(el => (el.id === (values.interests[i])))))) {
                        finalvalue.interests.push(values?.interests[i])
                    }
                }
            }
            if (values.campaign_list.length !== 0) {
                console.log("Add compaign")
                finalvalue.campaign_list.campaign_ids = values?.campaign_list
            } else { delete finalvalue.campaign_list }
            if (values.search_phrases.phrases.length !== 0 || values.search_phrases.stop_phrases.length !== 0) {
                finalvalue.search_phrases.phrases = values.search_phrases?.phrases
                finalvalue.search_phrases.stop_phrases = values.search_phrases?.stop_phrases
            } else { delete finalvalue.search_phrases }
            nonEmptyObject(finalvalue)
            console.log(finalvalue)
            console.log(segment.id)
            dispatch(updateSegment(finalvalue, segment.id));
            setEditSegment(false)
            // todo: why 3?
            //if (values) props.handleChange("event", "3");
            //formik.setValues(FORMIK_INITIAL_VALUES);
            //console.log(values);
            //props.onClose();
        },
    });

    // Temp formik context of values and touched
    const [tempFormikValues, setTempFormikValues] = useState(null);
    const [tempFormikTouched, setTempFormikTouched] = useState(null);
    const [selected, setSelected] = useState('regions');
    const [isRegions, setRegions] = React.useState(true)
    const [isMaps, setMaps] = React.useState(false)

    const handleChange = () => {
        console.log(selected)
        if (selected === 'regions') {
            setRegions(false)
            setMaps(true)
            console.log("Maps?", formik.values.local_geo)
            //formik.setFieldValue("geo", [])
            //return (<Search value={formik.values.geo} onChange={(value) => formik.setFieldValue("geo", value)}/>)
        }
        else if (selected === 'maps') {
            setMaps(false)
            setRegions(true)
            console.log("Regions", formik.values.regions)
        } //else {
        //  setRegions(false)
        //  setMaps(false)}//formik.setFieldValue("geo", [])
    }

    console.log(formik)
    const saveFormikContext = () => {
        setTempFormikValues(formik.values);
        setTempFormikTouched(formik.touched);
    }
    const restoreFormikContext = () => {
        formik.setValues(tempFormikValues);
        formik.setTouched(tempFormikTouched);
        setTempFormikValues(null);
        setTempFormikTouched(null);
    }
    const clearFormikContext = () => {
        setTempFormikValues(null);
        setTempFormikTouched(null);
    }

    // Gender handlers
    const handleOpenGender = () => {
        saveFormikContext();
        setIsGenderOpen(true);
    };
    const handleCancelGender = () => {
        restoreFormikContext();
        setIsGenderOpen(false);
    };
    const handleApplyGender = () => {
        clearFormikContext();
        setIsGenderOpen(false);
    }
    const handleResetGender = () => {
        clearFormikContext();
        formik.setFieldValue("sex", FORMIK_INITIAL_VALUES.sex);
        setIsGenderOpen(false);
    }

    // age handlers
    const handleOpenAges = () => {
        saveFormikContext();
        setIsAgeOpen(true);
    };
    const handleCancelAges = () => {
        restoreFormikContext();
        setIsAgeOpen(false);
    };
    const handleApplyAges = () => {
        clearFormikContext();
        setIsAgeOpen(false);
    }
    const handleResetAges = () => {
        clearFormikContext();
        formik.setFieldTouched("age", false);
        formik.setFieldValue("age.from", FORMIK_INITIAL_VALUES.age.from);
        formik.setFieldValue("age.to", FORMIK_INITIAL_VALUES.age.to);
        formik.setFieldValue("age.exclude", FORMIK_INITIAL_VALUES.age.exclude);
        setIsAgeOpen(false);
    }

    // Geo handlers
    const handleOpenGeo = () => {
        saveFormikContext();
        setIsGeoOpen(true);
    };
    // const handleCancelGeo = () => {
    //     restoreFormikContext();
    //     setIsGeoOpen(false);
    // };
    const handleApplyGeo = () => {
        clearFormikContext();
        setIsGeoOpen(false);
    }
    // const handleResetGeo = () => {
    //     clearFormikContext();
    //     //formik.setFieldTouched("geo", false);
    //     formik.setFieldValue("local_geo", FORMIK_INITIAL_VALUES.local_geo);
    //     formik.setFieldValue("regions", FORMIK_INITIAL_VALUES.regions);
    //     setIsGeoOpen(false);
    // }

    // Preferences handlers
    const handleOpenPreferences = () => {
        saveFormikContext();
        setIsPreferencesOpen(true);
    };
    const handleCancelPreferences = () => {
        restoreFormikContext();
        setIsPreferencesOpen(false);
    };
    const handleApplyPreferences = () => {
        clearFormikContext();
        setIsPreferencesOpen(false);
    }
    const handleResetPreferences = () => {
        clearFormikContext();
        //formik.setFieldTouched("interests", false);
        formik.setFieldValue("interests", FORMIK_INITIAL_VALUES.interests);
        setIsPreferencesOpen(false);
    }

    // Campaigns handlers
    const handleOpenCampaign = () => {
        saveFormikContext();
        setIsCampaignOpen(true);
    };
    const handleCancelCampaign = () => {
        restoreFormikContext();
        setIsCampaignOpen(false);
    };
    const handleApplyCampaign = () => {
        clearFormikContext();
        setIsCampaignOpen(false);
    }
    const handleResetCampaign = () => {
        clearFormikContext();
        formik.setFieldValue("campaign_list", FORMIK_INITIAL_VALUES.campaign_list);
        setIsCampaignOpen(false);
    }

    // Search history handlers
    const handleOpenSearchHistory = () => {
        saveFormikContext();
        setIsSearchHistoryOpen(true);
    };
    const handleCloseSearchHistory = () => {
        restoreFormikContext();
        setIsSearchHistoryOpen(false);
    };
    const handleApplySearchHistory = () => {
        clearFormikContext();
        setIsSearchHistoryOpen(false);
    }
    const handleResetSearchHistory = () => {
        clearFormikContext();
        formik.setFieldValue("search_phrases.phrases", FORMIK_INITIAL_VALUES.search_phrases.phrases)
        formik.setFieldValue("search_phrases.stop_phrases", FORMIK_INITIAL_VALUES.search_phrases.stop_phrases)
        setIsSearchHistoryOpen(false);

    }

    const handleReset = () => {
        formik.setValues(createInitialValues(segment))
        setEditSegment(false)
        //formik.setValues(FORMIK_INITIAL_VALUES);
        //props.onClose();
    }

    const getSelectedCampaignsNames = (ids) => {
        //console.log(ids)
        const selected = campaigns.filter(campaign => ids.includes(campaign.id));
        // console.log(selected)
        const names = selected.map(campaign => campaign.basic_config.name);
        return names;
    }


    function printsex(arr) {
        let str = ""
        if (arr?.length === 2) {
            str += "Женский/Мужской"
        }
        else if (arr?.length === 1 && arr[0] === "male") { str += "Мужской" }
        else if (arr?.length === 1 && arr[0] === "female") { str += "Женский" }
        return str
    }

    function printages(arr) {
        let str = ""
        str += `${arr?.from}-${arr?.to}`
        //if (arr.exclude?.length !== 0) str += `, исключая ${arr.exclude}`
        return str
    }

    function printRegions(arr) {
        if (!arr.length) return '';
        let str = '';
        let names = regions?.filter(elem => arr.includes(elem.id))
        arr.forEach((el, i) => str += `${names[i].name}, `)
        return str.slice(0, -2)
    }

    function printInterestsSimple(arr) {
        const pref = [...preferences?.interests, ...preferences?.interests_soc_dem]
          .map((elem) => elem.children)
          .flat()
          .filter((el) => arr.includes(el?.id))
          .slice(0, 6);
        let str = "";
        if (!pref.length) return "";
        pref.forEach((el) => (str += `${el.name}, `));
        return str.slice(0, -2);
    }

    function printInterests(arrInterests, arrSocDem) {
        if (arrInterests && arrSocDem) {
            const arr = [...arrInterests, ...arrSocDem]
            const pref = [...preferences?.interests, ...preferences?.interests_soc_dem].map((elem => elem.children)).flat().filter((el) => arr.includes(el?.id)).slice(0, 10)
            let str = '';
            if (!pref.length) return ""
            pref.forEach((el) => str += `${el.name}, `)
            return str.slice(0, -2)
        } else if (arrInterests) {
            const pref = preferences?.interests.map((elem => elem.children)).flat().filter((el) => arrInterests.includes(el?.id)).slice(0, 10)
            let str = '';
            if (!pref.length) return ""
            pref.forEach((el) => str += `${el.name}, `)
            return str.slice(0, -2)
        } else if (arrSocDem) {
            const pref = preferences?.interests_soc_dem.map((elem => elem.children)).flat().filter((el) => arrSocDem.includes(el?.id)).slice(0, 10)
            let str = '';
            if (!pref.length) return ""
            pref.forEach((el) => str += `${el.name}, `)
            return str.slice(0, -2)
        }
    }
    function printCheckBox(arrInterests, arrSocDem) {
        if (arrInterests && arrSocDem) {
            const arr = [...arrInterests, ...arrSocDem]
            const pref = [...preferences?.interests, ...preferences?.interests_soc_dem].map((elem => elem.children)).flat().filter((el) => arr?.includes(el?.id)).map((el) => el.id)
            const a = [...preferences?.interests_soc_dem, ...preferences?.interests]
            const b = a.filter((el) => el.children?.some((el) => pref?.includes(el.id))).map((el) => el.id)
            return [...b, ...pref]
        } else if (arrInterests) {
            const pref = preferences?.interests.map((elem => elem.children)).flat().filter((el) => arrInterests?.includes(el?.id)).map((el) => el.id)
            const b = preferences?.interests.filter((el) => el.children?.some((el) => pref?.includes(el.id))).map((el) => el.id)
            return [...b, ...pref]
        } else if (arrSocDem) {
            const pref = preferences?.interests_soc_dem.map((elem => elem.children)).flat().filter((el) => arrSocDem?.includes(el?.id)).map((el) => el.id)
            const b = preferences?.interests_soc_dem.filter((el) => el.children?.some((el) => pref?.includes(el.id))).map((el) => el.id)
            return [...b, ...pref]
        }
    }
    function printSearchPhrases(arr) {
        if (!arr.length) return '';
        let str = '';
        arr.forEach((el) => str += `${el}, `)
        return str.slice(0, -2)
    }
    // function printRetarget(arr) {
    //     if (!arr?.length) return '';
    //     let str = '';
    //     arr.forEach((el) => str += `${el}, `)
    //     return str.slice(0, -2)
    // }

    function printallgeo(arr) {
        if (arr > 0) {
            const namereg = regions?.filter(elem => elem.id === arr)
            if (namereg && namereg.length !== 0) {
                return (<button className="catalog">
                    <Typography >
                        {namereg[0]?.name}
                    </Typography>
                </button>)
            }
        }
        else if (arr < 0) {
            const namereg = regions?.filter(elem => elem.id === Math.abs(arr))
            if (namereg && namereg.length !== 0) {
                return (<button className="catalog2">
                    <Typography>
                        {namereg[0]?.name}
                    </Typography>
                </button>)
            }
        }
        else console.log("wrong")

    }

    function printallinterests(arr) {
        // console.log(arr)
        // console.log(preferences,arr)
        const pref = preferences["interests"]?.filter((elem => elem.id === (arr)))
        // console.log("pref", pref)
        if (pref && pref.length !== 0) {
            return (<button className="catalog">
                <Typography><div className="body1">
                    {pref[0].name}</div>
                </Typography>
            </button>)
        }
        let name = []
        preferences.interests?.map((elem => elem.children?.map(el => (el.id === (arr)) ? name = el.name : "")))
        // console.log(name)
        if (name) {
            return (<button className="catalog">
                <Typography><div className="body1">
                    {name}</div>
                </Typography>
            </button>)
        }
    }

    function printall_interests_soc_dem(arr) {
        // console.log(arr)
        // console.log(preferences,arr)
        const pref = preferences?.interests_soc_dem?.filter((elem => elem.id === (arr)))
        // console.log("pref", pref)
        if (pref && pref.length !== 0) {
            return (<button className="catalog">
                <Typography><div className="body1">
                    {pref[0].name}</div>
                </Typography>
            </button>)
        }
        let name = []
        preferences?.interests_soc_dem?.map((elem => elem.children?.map(el => (el.id === (arr)) ? name = el.name : "")))
        // console.log(name)
        if (name) {
            return (<button className="catalog">
                <Typography><div className="body1">
                    {name}</div>
                </Typography>
            </button>)
        }
    }

    return (
        <div>
            <div className={clsx("Card", seg.status)}>
                <div className="Segment">
                    <div className="segment-header">
                        <button type="button" onClick={toggleOpenSegment}>
                            <div className="column">
                                <ArrowIcon
                                    className={openSegment ? "segment-icon__open" : "segment-icon"}
                                />
                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <Typography className="title" variant="h2" color="dark-gray">
                                        {segment.name} <span className="id">ID:{segment.id}</span>
                                    </Typography>
                                </Box>
                            </div>
                        </button>
                        <div className="column">
                            <Typography variant="body1" color="dark-gray">
                                Доступны <span className="hide">для привлечения:</span>
                            </Typography>
                            <Typography variant="body1" color="blue">
                                {segment.prediction?.current || "Идет подсчет охвата"}
                            </Typography>
                            <div className="settings" style={{ paddingLeft: 20 }}>
                                <button disabled={seg.disable} type="button" onClick={() => setEditSegment(!editSegment)}>
                                    <Typography variant="body1" color="dark-gray">
                                        <SettingsIcon />
                                    </Typography>
                                </button>
                            </div>
                        </div>
                    </div>
                    {openSegment &&
                        <div>
                            <div className="segment-content">
                                <div className="column">
                                    <div className="category">
                                        <div style={{ position: 'relative' }} className="circle">
                                            <span className="SegIc" style={{ position: 'absolute', paddingTop: 10, paddingLeft: 10, color: "#6b58e0" }}><CircleIcon style={{ fontSize: 180 }} /></span>
                                            <span style={{ position: 'absolute', paddingTop: 45, paddingLeft: 56, color: "white" }}>
                                                <b style={{ alignContent: "center" }}>
                                                    {(segment.prediction?.current)
                                                        ? (<b >{segment.prediction?.current} <br /> чел.</b>)
                                                        : (<b > Идет <br /> подсчет </b>)}
                                                </b>
                                            </span>
                                            <span style={{ position: 'absolute', paddingTop: 92, paddingLeft: 48, color: "white" }}>
                                                <p>Доступны для привлечения</p>
                                            </span>
                                            <CircularProgress variant="determinate" className="SegIc" value={diff} size={200} style={{ color: "#6b58e0" }}>
                                            </CircularProgress>
                                        </div>
                                        <Line />
                                        <div className="buttons">
                                            {(segment.sex !== null) &&
                                                <button className="catalog">
                                                    <Typography color="dark-gray">
                                                        <div className="body1">
                                                            {printsex(segment.sex)}
                                                        </div>
                                                    </Typography>
                                                </button>
                                            }
                                            {(segment.age !== null) && (
                                                <button className="catalog">
                                                    <Typography variant="body1" color="dark-gray">
                                                        <div className="body1">
                                                            {printages(segment.age)}
                                                        </div>
                                                    </Typography>
                                                </button>)
                                            }
                                            {(segment.regions !== null) &&
                                                segment.regions?.map(elem => printallgeo(elem)).slice(0, 5)
                                            }
                                            {(segment.interests !== null) &&
                                                segment.interests?.map(elem => printallinterests(elem)).slice(0, 5)
                                            }
                                            {(segment.interests_soc_dem !== null) &&
                                                segment.interests_soc_dem?.map(elem => printall_interests_soc_dem(elem)).slice(0, 5)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {editSegment ?
                <div>
                    <br />
                    <Grid
                        component="form"
                        className="createSegment"
                        sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}
                    >
                        <Grid
                            className="grid"
                            container
                            spacing={{ xs: 3, md: 3 }}
                            columns={{ xs: 2, sm: 8, md: 12 }}
                            rowSpacing={5}
                            alignItems="center"
                            justify="center"
                        >
                            {/* Name input */}
                            <Grid item xs={12} >
                                <p
                                    className="title"
                                    style={{
                                        marginBottom: 14,
                                        height: 24,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}
                                >
                                    Название сегмента
                                </p>
                                <FormControl sx={{ width: "100%" }}>
                                    <TextField
                                        required
                                        sx={{ minWidth: 120, width: 300 }}
                                        label="Название"
                                        name="name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        error={formik.errors.name && formik.touched.name}
                                        input={<OutlinedInput label="Название" />}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Gender select */}
                            <Grid className="gridItem" disabled item xs={3}>
                                <p
                                    className="title"
                                    style={{
                                        marginBottom: 14,
                                        height: 24,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}
                                >
                                    Социально-демографические
                                </p>
                                <FormControl disabled sx={{ width: "100%" }}>
                                    {/* // todo: think how to move label in field when it not done */}
                                    <TextField
                                        name="gender"
                                        onClick={handleOpenGender}
                                        disabled
                                        label="Пол"
                                        value={formik.values.sex ? printsex(segment.sex) : ""}
                                        onMouseDown={event => event.preventDefault()}
                                        InputProps={{ readOnly: true }}
                                        id="outlined-multiline-flexible"
                                        sx={{ minWidth: 100, width: 290 }}
                                    />
                                    <Dialog //open={isGenderOpen} 
                                    >
                                        <DialogTitle>Выберите пол</DialogTitle>
                                        <DialogContent>
                                            <Box className="modal" component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
                                                <FormControl sx={{ m: 2, minWidth: 120, width: 350 }}>
                                                    <InputLabel id="gender_type-label">
                                                        Пол
                                                    </InputLabel>
                                                    <Select
                                                        labelId="gender_type-label"
                                                        id="sex"
                                                        name="sex"
                                                        value={formik.values.sex}
                                                        onChange={formik.handleChange}
                                                        input={<OutlinedInput label="Пол" />}
                                                    >
                                                        {GENDER_TYPES.map(gender_type => (
                                                            <MenuItem
                                                                key={gender_type.value}
                                                                value={gender_type.value}
                                                            >
                                                                {gender_type.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleResetGender}>Сбросить</Button>
                                            <Button onClick={handleCancelGender}>Отмена</Button>
                                            <Button onClick={handleApplyGender}>Ok</Button>
                                        </DialogActions>
                                    </Dialog>
                                </FormControl>
                            </Grid>

                            {/* age data */}
                            <Grid className="socialItem" item xs={3}>
                                <p
                                    style={{
                                        marginBottom: 14,
                                        height: 24,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}
                                >
                                </p>
                                <FormControl sx={{ width: "100%" }}>
                                    <TextField
                                        name="age"
                                        disabled
                                        onClick={handleOpenAges}
                                        onMouseDown={event => event.preventDefault()}
                                        InputProps={{ readOnly: true }}
                                        label="Возраст"
                                        value={
                                            !formik.errors.age
                                                && (formik.values.age.from
                                                    || formik.values.age.to
                                                    || formik.values.age.exclude.length)
                                                ? printages(segment.age)
                                                : ""
                                        }
                                        sx={{ minWidth: 120, width: 290 }} />
                                    <Dialog disableEscapeKeyDown //open={isAgeOpen}
                                    >
                                        <DialogTitle>Укажите возраст</DialogTitle>
                                        <DialogContent>
                                            <Box component="form" sx={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                                                <FormControl sx={{ m: 1, minWidth: 120, width: 350 }}>
                                                    <TextField
                                                        fullWidth
                                                        label="От"
                                                        value={formik.values.age.from}
                                                        name="age.from"
                                                        type="number"
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        sx={{ m: 1 }}
                                                        input={<OutlinedInput label="Возраст" />}
                                                        error={formik.errors.age?.from && formik.touched.age?.from}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="До"
                                                        type="number"
                                                        value={formik.values.age.to}
                                                        name="age.to"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        sx={{ m: 1 }}
                                                        input={<OutlinedInput label="Возраст" />}
                                                        error={formik.errors.age?.to && formik.touched.age?.to}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box component="form" sx={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                                                <FormControl sx={{ m: 2, minWidth: 120, width: 350 }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Исключить возраст"
                                                        name="age.exclude"
                                                        id="fullWidth"
                                                        value={formik.values.age.exclude.join(",")}
                                                        onChange={
                                                            (e) => formik.setFieldValue(
                                                                "age.exclude",
                                                                e.target.value ? e.target.value.split(",") : []
                                                            )
                                                        }
                                                        onBlur={formik.handleBlur}
                                                        input={<OutlinedInput label="Исключить возраст" />}
                                                        error={formik.errors.age?.exclude && formik.touched.age?.exclude}
                                                    />
                                                </FormControl>
                                            </Box>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleResetAges}>Сбросить</Button>
                                            <Button onClick={handleCancelAges}>Отмена</Button>
                                            <Button
                                                onClick={handleApplyAges}
                                                disabled={Boolean(formik.errors.age)}
                                            >Ok</Button>
                                        </DialogActions>
                                    </Dialog>
                                </FormControl>
                            </Grid>

                            {/* Geo data */}
                            <Grid className="socialItem" item xs={3}>
                                <p
                                    style={{
                                        marginBottom: 14,
                                        height: 24,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}
                                >
                                </p>
                                <FormControl disabled sx={{ width: "100%" }}>
                                    <div>
                                        <TextField
                                            disabled
                                            name="geo"
                                            value={(formik.values.local_geo.length || formik.values.regions.length) ? printRegions(formik.values.regions) : ""}
                                            onClick={handleOpenGeo}
                                            onMouseDown={event => event.preventDefault()}
                                            InputProps={{ readOnly: true }}
                                            // label="География"
                                            sx={{ minWidth: 120, width: 290, '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': { textOverflow: 'ellipsis' } }}
                                        />
                                        <Dialog disableEscapeKeyDown open={isGeoOpen}
                                            fullWidth maxWidth="md">
                                            <DialogTitle>Укажите гео-данные</DialogTitle>
                                            <DialogContent>
                                                <Box component="form" sx={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                                                    <FormControl sx={{
                                                        m: 2,
                                                        minWidth: 120,
                                                        width: "100%",
                                                        height: 380
                                                    }}>

                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            sx={{ width: "100%" }}>
                                                            <FormControlLabel value="regions"
                                                                control={<Radio
                                                                    checked={selected === 'regions'}
                                                                    onClick={() => setSelected('regions')}
                                                                    onChange={handleChange}
                                                                    value="regions"
                                                                    name="radio-buttons"
                                                                />}
                                                                label="Города и регионы" />
                                                            <FormControlLabel value="maps"
                                                                control={<Radio
                                                                    disabled={true}
                                                                    checked={selected === 'maps'}
                                                                    onClick={() => setSelected('maps')}
                                                                    onChange={handleChange}
                                                                    value="maps"
                                                                    name="radio-buttons"
                                                                    inputProps={{ 'aria-label': 'B' }}
                                                                />} label="Выбрать на карте" />
                                                        </RadioGroup>
                                                        <Search open={isRegions} edit={true} value={formik.values.regions} onChange={(value) => formik.setFieldValue("regions", value)} />
                                                        <SegmentMap edit={true} open={isMaps} value={formik.values.local_geo} onChange={(value) => formik.setFieldValue("local_geo", value)} />
                                                    </FormControl>
                                                </Box>
                                            </DialogContent>
                                            <DialogActions>
                                                {/* <Button onClick={handleResetGeo}>Сбросить</Button> */}
                                                {/* <Button onClick={handleCancelGeo}>Отмена</Button> */}
                                                <Button onClick={handleApplyGeo}>Ok</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                </FormControl>
                            </Grid>

                            {/* Preference data */}
                            <Grid className="gridItem" item xs={6}>
                                <p
                                    className="title"
                                    style={{
                                        marginBottom: 14,
                                        height: 24,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}
                                >
                                    Интересы (IAB категории)
                                </p>
                                <FormControl sx={{ width: "100%" }}>
                                    <>
                                        <TextField
                                            name="interests"
                                            disabled={false}
                                            value={
                                                segment.interests || segment.interests_soc_dem
                                                ? printInterests(segment.interests, segment.interests_soc_dem)
                                                : formik.values.interests.length ? printInterestsSimple(formik.values.interests) : ""}
                                            onClick={handleOpenPreferences}
                                            onMouseDown={event => event.preventDefault()}
                                            InputProps={{ readOnly: true }}
                                            label="Интересы"
                                            sx={{ minWidth: 120, width: 590, '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': { textOverflow: 'ellipsis' } }}
                                        />
                                        <Dialog disableEscapeKeyDown open={isPreferencesOpen}
                                            fullWidth maxWidth="sm">
                                            <DialogTitle>Выберите интересы</DialogTitle>
                                            <DialogContent>
                                                <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
                                                    <FormControl>
                                                        <ControlledTreeView
                                                            value={printCheckBox(segment.interests, segment.interests_soc_dem) || formik.values.interests}
                                                            onChange={(value) => formik.setFieldValue("interests", value)}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleResetPreferences}>Сбросить</Button>
                                                <Button onClick={handleCancelPreferences}>Отмена</Button>
                                                <Button onClick={handleApplyPreferences}>Ok</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                </FormControl>
                            </Grid>
                            {/* Campaigns select */}
                            <Grid className="gridItem" item xs={6}>
                                <p
                                    className="title"
                                    style={{
                                        marginBottom: 14,
                                        height: 24,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}
                                >
                                    Ретаргетинг
                                </p>
                                <FormControl sx={{ width: "100%" }}>
                                    <div>
                                        <TextField
                                            name="campaign_list"
                                            value={formik.values.campaign_list.length ? getSelectedCampaignsNames(formik.values.campaign_list).join(", ") : ""}
                                            onClick={handleOpenCampaign}
                                            onMouseDown={event => event.preventDefault()}
                                            InputProps={{ readOnly: true }}
                                            label="Кампании"
                                            sx={{ minWidth: 120, width: 590 }} />
                                        <Dialog disableEscapeKeyDown open={isCampaignOpen} >
                                            <DialogTitle>Выберите Кампании</DialogTitle>
                                            <DialogContent>
                                                <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
                                                    <FormControl sx={{ m: 2, minWidth: 120, width: 350 }}>
                                                        <InputLabel id="select-campaigns-label">Кампании</InputLabel>
                                                        <Select
                                                            labelId="select-campaigns-label"
                                                            id="select-campaigns"
                                                            multiple
                                                            name="campaign_list"
                                                            value={formik.values.campaign_list}
                                                            onChange={formik.handleChange}
                                                            input={<OutlinedInput label="Кампании" />}
                                                            renderValue={selectedCampaigns => getSelectedCampaignsNames(selectedCampaigns).join(", ")} //getSelectedCampaignsNames(selectedCampaigns)
                                                            MenuProps={MenuProps}
                                                        >
                                                            {campaigns.filter(campaign => (campaign.id !== null)).map(campaign =>
                                                                <MenuItem key={campaign.id} value={campaign.id}>
                                                                    <Checkbox checked={formik.values.campaign_list.includes(campaign.id)} />
                                                                    <ListItemText primary={campaign.basic_config.name} />
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleResetCampaign}>Сбросить</Button>
                                                <Button onClick={handleCancelCampaign}>Отмена</Button>
                                                <Button onClick={handleApplyCampaign}>Ok</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                </FormControl>
                            </Grid>

                            {/* CRM данные */}
                            <Grid className="gridItem" item xs={6}>
                                <p
                                    className="title"
                                    style={{
                                        marginBottom: 10,
                                        height: 24,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}
                                >
                                    CRM данные
                                </p>
                                <Dropzone
                                    accept=".csv,.txt,.xls,.xlsx"
                                    multiple={false}
                                    onDrop={(acceptedFiles) => {
                                        const [file] = acceptedFiles;
                                        formik.setFieldValue("crm", {
                                            file: {
                                                file,
                                                fileType: "",
                                                fileName: file.name,
                                            }
                                        })
                                    }
                                    }
                                >
                                    {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
                                        <div
                                            {...getRootProps({
                                                style: { marginTop: 0 },
                                                className: `dropzone1
                                    ${isDragAccept && "dropzoneAccept1"}
                                    ${isDragReject && "dropzoneReject1"}`,
                                            })}
                                        >
                                            <input type="file" {...getInputProps()} />
                                            <div className="media-plan__drag-container">
                                                {
                                                    formik.values.crm.file
                                                        ? <p style={{ textAlign: "center" }}>Файл добавлен</p>
                                                        : <p style={{ textAlign: "center" }}>Добавить файл</p>
                                                }
                                                <p style={{ fontSize: "11px" }} className="media-plan__drag-text">
                                                    Поддерживаются файлы следующих форматов: xls и xlsx - используются данные из первого столбца, csv / txt - разделитель столбцов ; (точка с запятой) и используются данные из первого столбца
                                                    <br />
                                                    <span className="media-plan__drag-text">Загружено: </span>
                                                    {
                                                        formik.values.crm.file
                                                        && <span className="media-plan__drag-text">{formik.values.crm.file.fileName}</span>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </Grid>

                            {/* Search history data */}
                            <Grid className="gridItem" item xs={6} style={{ marginBottom: 35 }} >
                                <p
                                    className="title"
                                    style={{
                                        marginBottom: 14,
                                        height: 24,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}
                                >
                                    История поисковых запросов
                                </p>
                                <FormControl sx={{ width: "100%" }}>
                                    <div>
                                        <TextField
                                            name="search_phrases.phrases"
                                            // todo: add or remove category.
                                            value={
                                                formik.values.search_phrases.phrases.length
                                                    || formik.values.search_phrases.stop_phrases.length ?
                                                    printSearchPhrases(formik.values.search_phrases.phrases) : ""
                                            }
                                            onClick={handleOpenSearchHistory}
                                            onMouseDown={event => event.preventDefault()}
                                            InputProps={{ readOnly: true }}
                                            label="Поисковые запросы"
                                            sx={{ minWidth: 120, width: 590, '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': { textOverflow: 'ellipsis' } }}
                                        />
                                        <Dialog disableEscapeKeyDown open={isPhrasesOpen} maxWidth="sm" fullWidth>
                                            <DialogTitle>Выберите категорию</DialogTitle>
                                            <DialogContent>
                                                <Box component="form" sx={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                                                    <FormControl sx={{ m: 2, minWidth: 120, width: "100%" }}>
                                                        <TextField
                                                            id="outlined-multiline-static"
                                                            onChange={(e) => formik.setFieldValue("search_phrases.phrases", e.target.value ? e.target.value.split("\n") : [])}
                                                            value={formik.values.search_phrases.phrases.join("\n")}
                                                            name="search_phrases.phrases"
                                                            label="Поисковые фразы"
                                                            placeholder="Введите фразы, по которым ищут товар. Отделяйте разные фразы переносом строки."
                                                            multiline
                                                            rows={10}
                                                            error={formik.errors.search_phrases?.phrases}
                                                        />
                                                        {formik.errors.search_phrases?.phrases && <div style={{ color: 'red', marginTop: 5 }}>Только русский и английский алфавиты, цифры и пробелы разрешены</div>}
                                                    </FormControl>
                                                </Box>
                                                <Box component="form" sx={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                                                    <FormControl sx={{ m: 2, minWidth: 120, width: "100%" }}>
                                                        <TextField
                                                            label="Минус-фразы на список"
                                                            id="outlined-multiline-static"
                                                            name="search_phrases.stop_phrases"
                                                            multiline
                                                            placeholder="Введите общие минус-фразы. Отделяйте разные фразы переносом строки."
                                                            onChange={(e) => formik.setFieldValue("search_phrases.stop_phrases", e.target.value ? e.target.value.split("\n") : [])}
                                                            value={formik.values.search_phrases.stop_phrases.join("\n")}
                                                            // input={<OutlinedInput label="Исключить запросы" />} 
                                                            rows={5}
                                                            error={formik.errors.search_phrases?.stop_phrases}
                                                        />
                                                        {formik.errors.search_phrases?.stop_phrases && <div style={{ color: 'red', marginTop: 5 }}>Только русский и английский алфавиты, цифры и пробелы разрешены</div>}
                                                    </FormControl>
                                                </Box>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleResetSearchHistory}>Сбросить</Button>
                                                <Button onClick={handleCloseSearchHistory}>Отмена</Button>
                                                <Button onClick={handleApplySearchHistory} disabled={Boolean(formik.errors.search_phrases)}>Ok</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                </FormControl>
                            </Grid>

                        </Grid >

                        <Button variant="outlined" onClick={handleReset} style={{ marginTop: 25, marginRight: 15 }}>Отменить</Button>
                        <Button variant="contained" onClick={formik.handleSubmit} style={{ marginTop: 25 }}>Обновить</Button>
                    </Grid >
                </div> : ""}
        </div>
    );
}

export default Segment;
