export const ACTIONS = {
  CHANGE_ACTIVE_FORMAT: 'CHANGE_ACTIVE_FORMAT',
  CHANGE_PREVIEW: 'CHANGE_PREVIEW',
  CHANGE_PREVIEW2: 'CHANGE_PREVIEW2',
  CHANGE_PREVIEW3: 'CHANGE_PREVIEW3',
  CHANGE_PREVIEW_SIZE: 'CHANGE_PREVIEW_SIZE',
  SET_SAVE_BANNER: 'SET_SAVE_BANNER',
  SET_ERROR_SAVE_BANNER: 'SET_ERROR_SAVE_BANNER',
  DELETE_BANNER_FROM_CLIENT: 'DELETE_BANNER_FROM_CLIENT',
  SET_USER_BANNERS: 'SET_USER_BANNERS',
  SET_IS_LOADING_BANNER: 'SET_IS_LOADING_BANNER',
  DELETE_PREVIEW: 'DELETE_PREVIEW',
  DELETE_PREVIEW2: 'DELETE_PREVIEW2',
  DELETE_PREVIEW3: 'DELETE_PREVIEW3',
  SAVE_ADVERTISEMENT: 'SAVE_ADVERTISEMENT',
  SET_ADVERTISEMENT: 'SET_ADVERTISEMENT'
};