export const SELECT_OPTIONS = {
  order: 'Заказать',
  buy: 'Купить',
  apply: 'Оформить заявку',
  learnMore: 'Получить консультацию',
  register: 'Зарегистрироваться',
  visitSite: 'Перейти на сайт',
}

export const POSITION_TYPES = [
  {id: 1, optionValue: "TYPE_1", optionLabel: "TYPE_1"},
  {id: 2, optionValue: "TYPE_2", optionLabel: "TYPE_2"},
  {id: 3, optionValue: "TYPE_3", optionLabel: "TYPE_3"},
  {id: 4, optionValue: "TYPE_4", optionLabel: "TYPE_4"},
  {id: 5, optionValue: "TYPE_5", optionLabel: "TYPE_5"},
];

export const BANNER_TYPES = {
  image: 'IMAGE',
  video: 'VIDEO',
  html: 'HTML5'
};

export const CONFIG_FORMATS = [
  {
    id: 1,
    title: 'Баннеры',
    name: 'MULTIFORM',
    sizes: ['600x600'],
    icon_size: ['256х256'],
    background_size: ['1080х607'],
    descriptionType: 'изображение',
    bannerType: BANNER_TYPES.image,
    accept: 'image/*'
  },
  {
    id: 2,
    title: 'Видео',
    name: 'FULLSCREEN',
    sizes: ['Видео 16:9 (30 секунд)'],
    icon_size: ['256х256'],
    background_size: ['1080х607'],
    descriptionType: 'видео-файл',
    bannerType: BANNER_TYPES.video,
    accept: 'video/*'
  },
  // {
  //   id: 3,
  //   title: 'Полноэкранное видео',
  //   name: 'FULLSCREEN',
  //   sizes: ['Видео 16:9 (30 секунд)'],
  //   icon_size: ['256х256'],
  //   background_size: ['1080х607'],
  //   descriptionType: 'видео-файл',
  //   bannerType: BANNER_TYPES.video,
  //   accept: 'video/*'
  // },
  // {
  //   id: 4,
  //   title: 'Баннеры HTML',
  //   name: 'DEFAULT',
  //   sizes: ['240x400'],
  //   descriptionType: 'html5-архив',
  //   bannerType: BANNER_TYPES.html,
  //   accept: '.ZIP,.JAR,.7Z,.RAR, .GZ'
  // },
  // {
  //   id: 5,
  //   title: 'Баннеры',
  //   name: 'MEDIA',
  //   sizes: ['240x400'],
  //   descriptionType: 'изображение',
  //   bannerType: BANNER_TYPES.image,
  //   accept: 'image/*'
  // }
];