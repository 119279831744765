import {ACTIONS} from "./actionTypes";
import {client} from "../../utils/network"
import {SELECT_OPTIONS, BANNER_TYPES} from "../../components/addCompany/stages/Banners/configs";
import {enqueueSnackbar} from "../notifications/actions";
import {updateCampaignRequest, updateCampaignSuccess, getCampaigns, updateCampaignFailure} from "../createNewCompany/actions";

const setActiveFormat = (id) => ({
  type: ACTIONS.CHANGE_ACTIVE_FORMAT,
  payload: id,
})

export const changePreview = (file) => ({
  type: ACTIONS.CHANGE_PREVIEW,
  payload: file,
})

export const changePreview2 = (file) => ({
  type: ACTIONS.CHANGE_PREVIEW2,
  payload: file,
})

export const changePreview3 = (file) => ({
  type: ACTIONS.CHANGE_PREVIEW3,
  payload: file,
})

export const changePreviewSize = (size) => ({
  type: ACTIONS.CHANGE_PREVIEW_SIZE,
  payload: size,
})

const setSaveBanner = (banner) => ({
  type: ACTIONS.SET_SAVE_BANNER,
  payload: banner
})

export const setErrorSaveBanner = (err) => ({
  type: ACTIONS.SET_ERROR_SAVE_BANNER,
  payload: err
})

const deleteBannerFromClient = (id) => ({
  type: ACTIONS.DELETE_BANNER_FROM_CLIENT,
  payload: id
})

export const setUserBanners = (banners) => ({
  type: ACTIONS.SET_USER_BANNERS,
  payload: banners
})

const setIsLoadingBanner = (isLoading) => ({
  type: ACTIONS.SET_IS_LOADING_BANNER,
  payload: isLoading
})

export const deletePreview = () => ({
  type: ACTIONS.DELETE_PREVIEW
})

export const deletePreview2 = () => ({
  type: ACTIONS.DELETE_PREVIEW2
})

export const deletePreview3 = () => ({
  type: ACTIONS.DELETE_PREVIEW3
})

export const saveAdvertisement = (data) => ({
  type: ACTIONS.SAVE_ADVERTISEMENT,
  payload: data,
})

export const setAdvertisement = (data) => ({
  type: ACTIONS.SET_ADVERTISEMENT,
  payload: data,
})

export const changeActiveFormat = (format) => async dispatch => {
  try {
    dispatch(setActiveFormat(format.id))

    const result = await client.get('/api/v1/banners')
    if (format.bannerType === 'VIDEO') {
      const banners = result.data.filter(({content_type}) => content_type !== 'HTML5')
      dispatch(setUserBanners(banners))
    }
    else {
      const banners = result.data.filter(({content_type}) => content_type === format.bannerType)
      dispatch(setUserBanners(banners))
    }
  } catch (err) {
    console.log(err.message)
    dispatch(
      enqueueSnackbar({ message: err.message.detail[0].msg, options: { variant: "error" } })
    );
  }
}

export const saveBanner = ({format, file, bannerType, existBanner}) => async dispatch => {
  if (bannerType === 'IMAGE2') {
    if (existBanner) {
      dispatch(setErrorSaveBanner(''))
      return dispatch(changePreview2(existBanner))
    }
    try {
      dispatch(setErrorSaveBanner(''))
      dispatch(setIsLoadingBanner(true))
      const data = new FormData()
      data.append('banner', file)
      const headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json'
      }

      client.interceptors.response.use(response => {
        return response
      }, error => {
        const requestData = Object.getOwnPropertyDescriptor(error, 'request');
        if (!requestData) throw new Error(error.message)
        const errData = JSON.parse(requestData.value.responseText);
        throw new Error(errData.message)
      })

      let response
      response = await client.post('/api/v1/banners/image-type?expected_width='+
      format?.sizes.join(' ').split('x')[0]+'&expected_height='+format?.sizes.join(' ').split('x')[1], data, {
            headers,
          })
      const banner = response.data
      const bannerSize = `${banner.data.width}x${banner.data.height}`
      if (!format.sizes.includes(bannerSize)) {
          await client.delete(`/api/v1/banners/${banner.id}`)
          throw new Error(`{"context":"Ошибка при загрузке изображения","message":"Баннер имеет недопустимые размеры. Допускается: 256x256 600x600 1080x607. Получено: ${bannerSize}"}`)
          }

      dispatch(setSaveBanner(response.data))
      dispatch(changePreview2(response.data))
    } catch (error) {
      if (error?.message.indexOf("expected_size") >= 0) {
        dispatch(setErrorSaveBanner(error.message))
      } else if (error.message) {
        dispatch(setErrorSaveBanner(JSON.parse(error.message).message))
      }
    } finally {
      dispatch(setIsLoadingBanner(false))
    }
  }
  else if (bannerType === 'IMAGE3') {
    if (existBanner) {
      dispatch(setErrorSaveBanner(''))
      return dispatch(changePreview3(existBanner))
    }
    try {
      dispatch(setErrorSaveBanner(''))
      dispatch(setIsLoadingBanner(true))
      const data = new FormData()
      data.append('banner', file)
      const headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json'
      }

      client.interceptors.response.use(response => {
        return response
      }, error => {
        const requestData = Object.getOwnPropertyDescriptor(error, 'request');
        if (!requestData) throw new Error(error.message)
        const errData = JSON.parse(requestData.value.responseText);
        throw new Error(errData.message)
      })

      let response
      response = await client.post('/api/v1/banners/image-type?expected_width='+
      format?.sizes.join(' ').split('x')[0]+'&expected_height='+format?.sizes.join(' ').split('x')[1], data, {
            headers,
          })
      const banner = response.data
      const bannerSize = `${banner.data.width}x${banner.data.height}`
      if (!format.sizes.includes(bannerSize)) {
          await client.delete(`/api/v1/banners/${banner.id}`)
          throw new Error(`{"context":"Ошибка при загрузке изображения","message":"Баннер имеет недопустимые размеры. Допускается: 256x256 600x600 1080x607. Получено: ${bannerSize}"}`)
          }

      dispatch(setSaveBanner(response.data))
      dispatch(changePreview3(response.data))
    } catch (error) {
      if (error?.message.indexOf("expected_size") >= 0) {
        dispatch(setErrorSaveBanner(error.message))
      } else if (error.message) {
        dispatch(setErrorSaveBanner(JSON.parse(error.message).message))
      }
    } finally {
      dispatch(setIsLoadingBanner(false))
    }
  } 
  else {
    if (existBanner) {
      dispatch(setErrorSaveBanner(''))
      return dispatch(changePreview(existBanner))
    }
    try {
      dispatch(setErrorSaveBanner(''))
      dispatch(setIsLoadingBanner(true))
      const data = new FormData()
      data.append('banner', file)
      const headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json'
      }

      client.interceptors.response.use(response => {
        return response
      }, error => {
        const requestData = Object.getOwnPropertyDescriptor(error, 'request');
        if (!requestData) throw new Error(error.message)
        const errData = JSON.parse(requestData.value.responseText);
        throw new Error(errData.message)
      })

      let response
      switch (bannerType) {
        case BANNER_TYPES.image:{
          response = await client.post('/api/v1/banners/image-type?expected_width='+
          format?.sizes.join(' ').split('x')[0]+'&expected_height='+format?.sizes.join(' ').split('x')[1], data, {
            headers,
          })
          const banner = response.data
          const bannerSize = `${banner.data.width}x${banner.data.height}`
          if (!format.sizes.includes(bannerSize)) {
            await client.delete(`/api/v1/banners/${banner.id}`)
            throw new Error(`{"context":"Ошибка при загрузке изображения","message":"Баннер имеет недопустимые размеры. Допускается: 256x256 600x600 1080x607. Получено: ${bannerSize}"}`)
          }
          break
        }  
        case BANNER_TYPES.video:
          if (file.size > 90000000) {
            dispatch(setErrorSaveBanner("Размер видео слишком большой"))
            break
          }
          response = await client.post('/api/v1/banners/video-type', data, {
            headers
          })
          break
        case BANNER_TYPES.html:
          // response = await client.post('/api/v1/banners/html-type', data, {
          //   headers
          // })
          break
        default:
          throw new Error(`{"context":"Ошибка при загрузке изображения","message":"Неизвестный тип баннера"}`)
      }
      if (file.size <= 90000000) {
        dispatch(setSaveBanner(response.data))
        dispatch(changePreview(response.data))
      }
    } catch (error) {
      if (error?.message.indexOf("expected_size") >= 0) {
        dispatch(setErrorSaveBanner(error.message))
      } else if (error.message) {
        dispatch(setErrorSaveBanner(JSON.parse(error.message).message))
      }
    } finally {
      dispatch(setIsLoadingBanner(false))
    }
  }
}

export const deleteBanner = (id) => async dispatch => {
  try {
    dispatch(setIsLoadingBanner(true))
    await client.delete(`/api/v1/banners/${id}`)
    dispatch(deleteBannerFromClient(id))
  } catch (err) {
    if (err.message ===`{"context":"Исключение, полученное из-за возможной ошибки внутри системы","message":"error"}`) {
      dispatch(setErrorSaveBanner("Баннер используется в кампании. Удаление невозможно."))
    } else dispatch(setErrorSaveBanner(err.message))
  } finally {
    dispatch(setIsLoadingBanner(false))
  }
}

// function validateErrors(errMessage) {
//   let e = errMessage.detail[0].msg
//   if (e === "Overlay is required for FULLSCREEN format") {
//     return "Для рекламного формата - видео, требуется превью баннер."
//   } else if (e === "field required") {
//     return "Прежде чем перейти дальше необходимо добавить баннеры."
//   } else {
//     return e
//   }
// }

export const addBannerToCampaign = 
(bannerDTO, campaign_id, banner_type, handleChange) => async (dispatch) => {
  const banner_id = bannerDTO.banners[0].id;
  const head      = bannerDTO.banners[0].head;
  const text      = bannerDTO.banners[0].text;
  const utm       = bannerDTO.banners[0].utm;
  const utm_text  = bannerDTO.banners[0].text_utm;
  const icon_id  = bannerDTO.banners[0].icon_id;
  const overlay_id = bannerDTO.banners[0].overlay_id;
  let urls_id = bannerDTO.banners[0].urls_id;
  const link = bannerDTO.banners[0].link;
  let link2 = bannerDTO.banners[0].link2;
  let link3 = bannerDTO.banners[0].link3;
  let link4 = bannerDTO.banners[0].link4;
  let link5 = bannerDTO.banners[0].link5;
  if (link2 === "") {link2 = null}
  if (link3 === "") {link3 = null}
  if (link4 === "") {link4 = null}
  if (link5 === "") {link5 = null}
  const call_to_action = Object.keys(SELECT_OPTIONS).find(
    key => SELECT_OPTIONS[key] === bannerDTO.banners[0].call_to_action
  );
  
  dispatch(updateCampaignRequest());
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
    };

    //let urls_id = ""
    if (urls_id) {
      client.put(`/api/v1/urls/${urls_id}`, {
        "link1": link,
        "link2": link2,
        "link3": link3,
        "link4": link4,
        "link5": link5,
      },headers)
    } else {
    await client.post('/api/v1/urls', {
      "link1": link,
      "link2": link2,
      "link3": link3,
      "link4": link4,
      "link5": link5,
    },headers).then((response) => {
      urls_id = response.data.id
    })}
    dispatch(updateCampaignRequest());
    const data = {
      "banner_type": {
        "format_type": banner_type.name,
      },
      "head": head,
      "text": text,
      "campaign_id": campaign_id,
      "banner_id": banner_id,
      "enable_utm": utm === "NOTHING" ? false : true,
      "urls_id": urls_id,
      "icon_id": icon_id,
      "overlay_id": overlay_id,
      "utm_type": utm,
      "utm": utm_text,
      "call_to_action": call_to_action,

    };

    await client.post(
      `/api/v1/banners/add-to-campaign`,
      data,
      headers,
    ).then((response) => {
      dispatch(saveAdvertisement(response.data));
      dispatch(updateCampaignSuccess(response.data));
      dispatch(getCampaigns());
    });

    if (handleChange) handleChange();

  } catch (err) {
    let errorValue
    try {
      errorValue = JSON.parse(err.message)?.detail[0]?.msg;
    } catch (parseError) {errorValue = err.message}
    if (errorValue === "field required") {
      errorValue = "Необходимо выбрать баннер."
    }
    dispatch(
      // enqueueSnackbar({ message: validateErrors(err.message), options: { variant: "error" } })
      enqueueSnackbar({ message: String(errorValue) || err.message, options: { variant: "error" } })
    );
    dispatch(updateCampaignFailure(err.message));
  }
}

