import { useEffect, useState } from "react";
import "./Clients.scss";
import { format as dateFormat } from "date-fns";
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  ListSubheader,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import {
  INIT_FILTER_VALUE,
  filterBalance,
  filterDate,
  filterDecrease,
  filterDeposit,
  filterEmail,
  filterFio,
  filterId,
  filterInn,
  filterPhone,
  filterSite,
  filterStaff,
  filterStaffArr,
  filterType,
} from "./filters";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo";
import BanModal from "./BanModal";
import DepositModal from "./DepositModal";
import EditStaffModal from "./EditStaffModal";
import { API_HOST } from "../../../configs/main";
import TelephoneInput from "../../../widgets/TelephoneInput/TelephoneInput";
import SearchIcon from "@mui/icons-material/Search";
import useAdminUser from "../../../data/useAdminUser";
import { ContentCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import SortPopup from "../SortPopUp/SortPopup";
import { sortClients } from "./filters";
import DataTable from "../Table/Datatable";

const PHONE_MASK = "+7 (___) ___-__-__";

function removeEmpty(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === "object") {
      removeEmpty(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
}

function Clients() {
  const { enqueueSnackbar } = useSnackbar();
  const { role } = useAdminUser();
  const [filterArr, setFilter] = useState([]);
  const [arrClients, setClients] = useState([]);
  const [arrStaff, setStaff] = useState([]);
  const [current, setCurent] = useState({});
  const [banModal, setBanModal] = useState(false);
  const [staffModal, setStaffModal] = useState(false);
  const [confirmStaff, setConfirmStaff] = useState(false);
  const [balanceModal, setBalanceModal] = useState(false);
  const [confirmBalance, setConfirmBalance] = useState(false);
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/users`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
      });
      if (res.ok) {
        const data = await res.json();
        setClients(data);
        setFilter(data);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/employee`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({}),
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setStaff(data);
        });
      }
    });
  }, []);

  const handleAccessToken = async (user_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    )
    const response = await fetch(`${API_HOST}/api/v1/admin/auth/access-token/${user_id}`, {
      method: "get",
      headers: myHeaders
    })
    const data = await response.json()
    localStorage.setItem("token", data.access_token);
    return window.open("/")
  }

  const handleOpenBan = (el) => {
    setBanModal(true);
    setCurent(el);
  };
  const handleCloseBan = () => {
    setBanModal(false);
  };

  const handleOpenBalance = (el) => {
    setBalanceModal(true);
    setCurent(el);
  };
  const handleCloseBalance = () => {
    setBalanceModal(false);
    setConfirmBalance(false);
  };
  const handleOpenStaff = (el) => {
    setStaffModal(true);
    setCurent(el);
  };
  const handleCloseStaff = () => {
    setStaffModal(false);
    setConfirmStaff(false);
  };
  const handleEditBalance = async (balance_id, newSum, type) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    try {
      if (type === "Пополнение") {
        const res = await fetch(
          `${API_HOST}/api/v1/admin/balances/${balance_id}`,
          {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify({
              score_diff: Number(newSum),
            }),
          }
        );
        if (res.ok) {
          setClients((prev) =>
            prev.map((el) => {
              if (el.balance_id === balance_id) {
                return { ...el, balance_score: +el.balance_score + +newSum };
              }
              return el;
            })
          );
          setFilter(
            filterArr.map((el) => {
              if (el.balance_id === balance_id) {
                return { ...el, balance_score: +el.balance_score + +newSum };
              }
              return el;
            })
          );
          enqueueSnackbar("Баланс клиента изменен", {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      } else {
        const score = Number(newSum);
        const res = await fetch(
          `${API_HOST}/api/v1/admin/balances/${balance_id}`,
          {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify({
              score_diff: -score,
            }),
          }
        );
        if (res.ok) {
          setClients((prev) =>
            prev.map((el) => {
              if (el.balance_id === balance_id) {
                return { ...el, balance_score: +el.balance_score - +newSum };
              }
              return el;
            })
          );
          setFilter(
            filterArr.map((el) => {
              if (el.balance_id === balance_id) {
                return { ...el, balance_score: +el.balance_score - +newSum };
              }
              return el;
            })
          );
          enqueueSnackbar("Баланс клиента изменен", {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
    handleCloseBalance();
  };

  const handleEditManager = (id, newManager) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/users/${id}`, {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        manager_id: newManager,
      }),
    })
      .then((res) => {
        if (res.ok) {
          arrClients.map((el) => {
            if (el.id === id) {
              return { ...el, manager_id: newManager };
            }
            return el;
          });
          setFilter(
            filterArr.map((el) => {
              if (el.id === id) {
                return { ...el, manager_id: newManager };
              }
              return el;
            })
          );
          enqueueSnackbar("Сотрудник привязан", {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        })
      );
    handleCloseStaff();
  };

  const downloadFile = async () => {
    const myHeaders = new Headers();
    const filters = {
      id: filtersValue.id ? filtersValue.id : undefined,
      full_name: filtersValue.name ? filtersValue.name : undefined,
      email: filtersValue.email ? filtersValue.email : undefined,
      phone_number: filtersValue.phone
        ? filtersValue.phone.replace(/[\s()]/g, "")
        : undefined,
      is_entity: filtersValue.type !== "" ? filtersValue.type : undefined,
      site: filtersValue.site ? filtersValue.site : undefined,
      inn: filtersValue.inn ? filtersValue.inn : undefined,
      balance_score: {
        from: filtersValue.balance.start
          ? +filtersValue.balance.start
          : undefined,
        to: filtersValue.balance.end ? +filtersValue.balance.end : undefined,
      },
      deposits_amount: {
        from: filtersValue.deposite.start
          ? +filtersValue.deposite.start
          : undefined,
        to: filtersValue.deposite.end ? +filtersValue.deposite.end : undefined,
      },
      charges_amount: {
        from: filtersValue.decrease.start
          ? +filtersValue.decrease.start
          : undefined,
        to: filtersValue.decrease.end ? +filtersValue.decrease.end : undefined,
      },
      manager_id: filtersValue.staff ? filtersValue.staff : undefined,
    };

    let data = removeEmpty(filters);
    if (filtersValue.date.date_start) {
      data = {
        ...data,
        created_at: {
          from: filtersValue.date.date_start,
        },
      };
    }
    if (filtersValue.date.date_end) {
      data = {
        ...data,
        created_at: {
          ...data.created_at,
          to: filtersValue.date.date_end,
        },
      };
    }
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const response = await fetch(`${API_HOST}/api/v1/admin/users/download`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const link = document.createElement("a");
      let filename = response.headers
        ?.get("content-disposition")
        .split("filename=")[1];
      const blob = await response.blob();
      if (filename === null) filename = "Clients.csv";
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setValue((prev) => ({
      ...prev,
      staffArr: typeof value === "string" ? value.split(",") : value,
    }));
  };
  useEffect(() => {
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "date":
            if (value === "") return acc;
            return filterDate(value, acc);
          case "deposite":
            if (value === "") return acc;
            return filterDeposit(value, acc);
          case "balance":
            if (value === "") return acc;
            return filterBalance(value, acc);
          case "decrease":
            if (value === "") return acc;
            return filterDecrease(value, acc);
          case "full_name":
            if (value === "") return acc;
            return filterFio(value, acc);
          case "type":
            if (value === "") return acc;
            return filterType(value, acc);
          case "phone":
            if (value === "") return acc;
            return filterPhone(value, acc);
          case "inn":
            if (value === "") return acc;
            return filterInn(value, acc);
          case "staff":
            if (value === "") return acc;
            return filterStaff(value, acc, arrStaff);
          case "site":
            if (value === "") return acc;
            return filterSite(value, acc);
          case "id":
            if (value === "") return acc;
            return filterId(value, acc);
          case "email":
            if (value === "") return acc;
            return filterEmail(value, acc);
          case "staffArr":
            if (!value.length) return acc;
            return filterStaffArr(value, acc, arrStaff);
          case "sort":
            if (value === "") return acc;
            return sortClients(value, acc);
          default:
            return acc;
        }
      },
      [...arrClients]
    );
    setFilter(result);
  }, [filtersValue, arrClients]); // eslint-disable-line react-hooks/exhaustive-deps
  const sortChange = (item) => {
    setValue((prev) => ({ ...prev, sort: item.type }));
  };

  const columns = [
    {
      Header: "ID клиента",
      accessor: "id",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "ФИ клиента",
      accessor: "full_name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Логин (email)",
      accessor: "email",
      maxWidth: 400,
      minWidth: 80,
      width: 220,
    },
    {
      Header: "Номер телефона",
      accessor: "phone_number",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Физ/Юр",
      accessor: "is_entity",
      maxWidth: 400,
      minWidth: 80,
      width: 110,
    },
    {
      Header: "Название юр.лица",
      accessor: "entity_full_name",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Сайт",
      accessor: "site",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "ИНН",
      accessor: "inn",
      maxWidth: 400,
      minWidth: 80,
      width: 120,
    },
    {
      Header: "PK",
      accessor: "campaigns_number",
      maxWidth: 400,
      minWidth: 50,
      width: 100,
    },
    {
      Header: "Дата регистр.",
      accessor: "created_at",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Подтв.",
      accessor: "is_email_confirmed",
      maxWidth: 400,
      minWidth: 50,
      width: 50,
    },
    {
      Header: "Баланс",
      accessor: "balance_score",
      maxWidth: 400,
      minWidth: 80,
      width: 118,
    },
    {
      Header: "Сумма п.",
      accessor: "deposits_amount",
      maxWidth: 400,
      minWidth: 80,
      width: 118,
    },
    {
      Header: "Сумма с.",
      accessor: "charges_amount",
      maxWidth: 400,
      minWidth: 80,
      width: 118,
    },
    {
      Header: "Дата посл. спис.",
      accessor: "last_charge_at",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Дата посл. пополн.",
      accessor: "last_deposit_at",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Сотрудник",
      accessor: "manager_id",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Перейти в ЛК",
      accessor: "redirect",
      maxWidth: 400,
      minWidth: 80,
      width: 120,
    },
    {
      Header: "",
      accessor: "ban",
      maxWidth: 400,
      minWidth: 50,
      width: 50,
    },
  ];

  function getTableData() {
    return filterArr.map((el) => {
      return {
        id: (
          <div
            className="td"
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 5,
            }}
          >
            <span style={{ display: "flex" }}>
              {el.id.slice(0, 8)}
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.id);
                  enqueueSnackbar("ID пользователя скопирован", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            </span>
            {!el.is_active && <span style={{ opacity: "70%" }}>blocked</span>}
          </div>
        ),
        full_name: (
          <div className="td">{el.full_name ? el.full_name : "-"}</div>
        ),
        email: <div className="td">{el.email ? el.email : "-"}</div>,
        phone_number: (
          <div className="td">{el.phone_number ? el.phone_number : "-"}</div>
        ),
        is_entity: (
          <div className="td">
            {el.is_entity === true
              ? "Юр. лицо"
              : el.is_entity === undefined
              ? "-"
              : "Физ. лицо"}
          </div>
        ),
        entity_full_name: (
          <div className="td">
            {el.entity_full_name ? el.entity_full_name : "-"}
          </div>
        ),
        site: (
          <div className="td">
            <span>{el.site ? el.site : "-"}</span>
            {el.site && (
              <ContentCopy
                style={{
                  marginLeft: 5,
                  position: "relative",
                  top: 4,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(el.site);
                  enqueueSnackbar("Сайт пользователя скопирован", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        inn: (
          <div className="td">
            {el.inn ? el.inn : "-"}
          </div>
        ),
        campaigns_number: (
          <div className="td">
            {el.campaigns_number ? el.campaigns_number : "-"}
          </div>
        ),
        created_at: (
          <div className="td">
            {dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")}
          </div>
        ),
        is_email_confirmed: (
          <div className="td">{el.is_email_confirmed ? "+" : "-"}</div>
        ),
        balance_score: (
          <div className="td">
            {el.balance_score ? el.balance_score : "-"}
            {["ADMIN", "SUPERUSER"].some((el) => el === role) && (
              <svg
                onClick={() => handleOpenBalance(el)}
                style={{ marginLeft: 10, cursor: "pointer" }}
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.49968 1.25391C9.051 1.25391 8.60238 1.424 8.26237 1.76416L2.72868 7.29736C2.62369 7.40236 2.54679 7.53293 2.50603 7.67627L1.76433 10.2734C1.74603 10.3377 1.74524 10.4057 1.76204 10.4704C1.77883 10.535 1.8126 10.594 1.85985 10.6413C1.90709 10.6885 1.96611 10.7223 2.03078 10.7391C2.09545 10.7559 2.16344 10.7551 2.22771 10.7368L4.82536 9.99512L4.82585 9.99463C4.96823 9.9537 5.09848 9.87776 5.20378 9.77246L10.737 4.23877C11.4173 3.55849 11.4173 2.44444 10.737 1.76416C10.397 1.424 9.94835 1.25391 9.49968 1.25391ZM9.49968 1.99951C9.75488 1.99951 10.0097 2.09784 10.2062 2.29443H10.2067C10.6004 2.68815 10.6004 3.31478 10.2067 3.7085L9.72185 4.19336L8.30778 2.7793L8.79265 2.29443C8.98914 2.09784 9.24448 1.99951 9.49968 1.99951ZM7.77751 3.30957L9.19157 4.72363L4.67302 9.24219C4.65836 9.25675 4.6398 9.2679 4.61882 9.27393L2.67058 9.83057L3.22722 7.88184V7.88135C3.23295 7.86119 3.24345 7.84314 3.25896 7.82764L7.77751 3.30957Z"
                  fill="black"
                />
              </svg>
            )}
          </div>
        ),
        deposits_amount: (
          <div className="td">
            {el.deposits_amount ? el.deposits_amount : "-"}
          </div>
        ),
        charges_amount: (
          <div className="td">
            {el.charges_amount ? el.charges_amount : "-"}
          </div>
        ),
        last_charge_at: (
          <div className="td">
            {el?.last_charge_at
              ? dateFormat(Date.parse(el.last_charge_at), "dd/MM/yyyy")
              : "-"}
          </div>
        ),
        last_deposit_at: (
          <div className="td">
            {el.last_deposit_at
              ? dateFormat(Date.parse(el.last_deposit_at), "dd/MM/yyyy")
              : "-"}
          </div>
        ),
        manager_id: (
          <div className="td">
            {el.manager_id
              ? arrStaff
                  ?.find((emp) => emp.id === el.manager_id)
                  ?.full_name?.split(" ")[0]
                ? arrStaff
                    ?.find((emp) => emp.id === el.manager_id)
                    ?.full_name?.split(" ")[0]
                : ""
              : "-"}
            {role === "SUPERUSER" && (
              <svg
                onClick={() => handleOpenStaff(el)}
                style={{ marginLeft: 10, cursor: "pointer" }}
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.49968 1.25391C9.051 1.25391 8.60238 1.424 8.26237 1.76416L2.72868 7.29736C2.62369 7.40236 2.54679 7.53293 2.50603 7.67627L1.76433 10.2734C1.74603 10.3377 1.74524 10.4057 1.76204 10.4704C1.77883 10.535 1.8126 10.594 1.85985 10.6413C1.90709 10.6885 1.96611 10.7223 2.03078 10.7391C2.09545 10.7559 2.16344 10.7551 2.22771 10.7368L4.82536 9.99512L4.82585 9.99463C4.96823 9.9537 5.09848 9.87776 5.20378 9.77246L10.737 4.23877C11.4173 3.55849 11.4173 2.44444 10.737 1.76416C10.397 1.424 9.94835 1.25391 9.49968 1.25391ZM9.49968 1.99951C9.75488 1.99951 10.0097 2.09784 10.2062 2.29443H10.2067C10.6004 2.68815 10.6004 3.31478 10.2067 3.7085L9.72185 4.19336L8.30778 2.7793L8.79265 2.29443C8.98914 2.09784 9.24448 1.99951 9.49968 1.99951ZM7.77751 3.30957L9.19157 4.72363L4.67302 9.24219C4.65836 9.25675 4.6398 9.2679 4.61882 9.27393L2.67058 9.83057L3.22722 7.88184V7.88135C3.23295 7.86119 3.24345 7.84314 3.25896 7.82764L7.77751 3.30957Z"
                  fill="black"
                />
              </svg>
            )}
          </div>
        ),
        redirect: <button
                    onClick={() => handleAccessToken(el.id)}
                    style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
                    Перейти 
                  </button>,
        ban: (
          <div
            className="td ban"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {role === "SUPERUSER" && (
              <svg
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenBan(el)}
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3327 0.666016L0.666016 11.3327M11.3327 11.3327L0.666016 0.666016L11.3327 11.3327Z"
                  stroke="#42454C"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                />
              </svg>
            )}
          </div>
        ),
      };
    });
  }

  return (
    <div className="Clients">
      <div className="filters">
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, id: e.target.value }))
          }
          className="searchId"
          placeholder="ID клиента"
        />
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, full_name: e.target.value }))
          }
          className="searchEmail"
          placeholder="ФИ клиента"
        />
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, email: e.target.value }))
          }
          className="searchEmail"
          placeholder="Логин (email)"
        />
        <TelephoneInput
          onChange={(e) =>
            setValue((prev) => ({ ...prev, phone: e.target.value }))
          }
          className="searchPhone"
          mask={PHONE_MASK}
          value={filtersValue.phone_number}
        />
        <Box style={{ marginTop: 5 }} sx={{ width: 140 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Физ/Юр лицо
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Физ/Юр лицо"
              id="demo-select-small-label"
              value={filtersValue.type}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, type: e.target.value }))
              }
            >
              <MenuItem value={""}>Все клиенты</MenuItem>
              <MenuItem value={false}>Физ. лицо</MenuItem>
              <MenuItem value={true}>Юр. лицо</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, inn: e.target.value }))
          }
          className="searchInn"
          placeholder="ИНН"
        />
        <div className="searchDate">
          <DateFromTo withoutStartDate={true} handleChange={handleDateChange} />
        </div>
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, site: e.target.value }))
          }
          className="searchSite"
          placeholder="Сайт"
        />
        <FormControl
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            top: 2,
          }}
        >
          <InputLabel
            style={{ opacity: "80%", top: -10 }}
            id="demo-multiple-checkbox-label"
          >
            Сотрудники
          </InputLabel>
          <Select
            style={{ width: 250, background: "#FFFFFF", height: 32 }}
            sx={{
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            input={<OutlinedInput label="Сотрудники" />}
            value={filtersValue.staffArr}
            onChange={handleChangeSelect}
            renderValue={(selected) => {
              return arrStaff
                .filter((el) => selected.includes(el.id))
                .map((el) => el.full_name)
                .join(",");
            }}
          >
            <ListSubheader>
              <TextField
                size="small"
                // autoFocus
                placeholder="Поиск"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  setValue((prev) => ({ ...prev, staff: e.target.value }))
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {arrStaff.map((staff) => (
              <MenuItem key={staff.id} value={staff.id}>
                <Checkbox
                  checked={filtersValue.staffArr.indexOf(staff.id) > -1}
                />
                <ListItemText primary={staff.full_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="searchRange">
          <span className="span">Баланс</span>
          <div className="inpRange">
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  balance: { ...prev.balance, start: e.target.value },
                }))
              }
              value={filtersValue.balance.start}
              className="start"
              placeholder="от"
            />
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  balance: { ...prev.balance, end: e.target.value },
                }))
              }
              value={filtersValue.balance.end}
              className="end"
              placeholder="до"
            />
          </div>
        </div>
        <div className="searchRange">
          <span className="span">Сумма пополнений</span>
          <div className="inpRange">
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  deposite: { ...prev.deposite, start: e.target.value },
                }))
              }
              value={filtersValue.deposite.start}
              className="start"
              placeholder="от"
            />
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  deposite: { ...prev.deposite, end: e.target.value },
                }))
              }
              value={filtersValue.deposite.end}
              className="end"
              placeholder="до"
            />
          </div>
        </div>
        <div className="searchRange">
          <span className="span">Сумма списаний</span>
          <div className="inpRange">
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  decrease: { ...prev.decrease, start: e.target.value },
                }))
              }
              value={filtersValue.decrease.start}
              className="start"
              placeholder="от"
            />
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  decrease: { ...prev.decrease, end: e.target.value },
                }))
              }
              value={filtersValue.decrease.end}
              className="end"
              placeholder="до"
            />
          </div>
        </div>
      </div>
      <div className="downald">
        <button onClick={() => downloadFile()} type="button">
          Скачать CSV
        </button>
      </div>
      <SortPopup
        sortedBy={true}
        title="Сортировать по"
        items={[
          { name: "дате регистрации", type: "created_at" },
          { name: "балансу", type: "balance_score" },
        ]}
        onChange={sortChange}
      />
      <DataTable data={getTableData()} columns={columns} />
      <BanModal
        BanModal={banModal}
        handleCloseBan={handleCloseBan}
        user={current}
      />
      <DepositModal
        modal={balanceModal}
        handleCloseModal={handleCloseBalance}
        confirm={confirmBalance}
        setConfirm={setConfirmBalance}
        client={current}
        handleEditBalance={handleEditBalance}
      />
      <EditStaffModal
        modal={staffModal}
        handleCloseModal={handleCloseStaff}
        confirm={confirmStaff}
        setConfirm={setConfirmStaff}
        client={current}
        handleEditManager={handleEditManager}
        arrStaff={arrStaff}
      />
    </div>
  );
}

export default Clients;
