import { ACTIONS } from "./actionTypes";
import { client } from "../../utils/network";
import { enqueueSnackbar } from "../notifications/actions";

export const createCampaignRequest = () => {
  return {
    type: ACTIONS.CREATE_NEW_COMPANY_REQUEST,
  };
};

export const createCampaignSuccess = (campaign) => {
  return {
    type: ACTIONS.CREATE_NEW_COMPANY_SUCCESS,
    payload: campaign,
  };
};

export const createCampaignFailure = (error) => {
  return {
    type: ACTIONS.CREATE_NEW_COMPANY_FAILURE,
    payload: error,
  };
};

export const createListRequest = () => {
  return {
    type: ACTIONS.CREATE_LIST_REQUEST,
  };
};

export const createListSuccess = (list) => {
  return {
    type: ACTIONS.CREATE_LIST_SUCCESS,
    payload: list,
  };
};

export const createListFailure = (error) => {
  return {
    type: ACTIONS.CREATE_LIST_FAILURE,
    payload: error,
  };
};

export const getCampaignsRequest = () => {
  return {
    type: ACTIONS.GET_CAMPAIGNS_REQUEST,
  };
};

export const getCampaignsSuccess = (campaigns) => {
  return {
    type: ACTIONS.GET_CAMPAIGNS_SUCCESS,
    payload: campaigns,
  };
};
export const getCampaignsFailure = (error) => {
  return {
    type: ACTIONS.GET_CAMPAIGNS_FAILURE,
    payload: error,
  };
};

export const setEditCampaign = (id) => ({
  type: ACTIONS.SET_EDIT_CAMPAIGN,
  payload: id,
});
export const resetEditCampaign = () => ({
  type: ACTIONS.RESET_EDIT_CAMPAIGN,
});
export const getCampaigns = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (!token) {
    return;
  } else {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    if (decodedToken.role in ["Role.DEFAULT", "Role.MANAGER"]) return;
  }
  dispatch(getCampaignsRequest);
  try {
    await client.get("/api/v1/campaigns?upd=false").then((response) => {
      const campaigns = response.data;
      dispatch(getCampaignsSuccess(campaigns));
      //console.log(campaigns);
    });
  } catch (error) {
    dispatch(getCampaignsFailure(error.message));
    console.log(error.message);
  }
};

export const createNewCompany =
  (formData, handleChange) => async (dispatch) => {
    console.log("FORM DAta", formData);
    dispatch(createCampaignRequest());
    try {
      await client
        .post("/api/v1/campaigns", {
          basic_config: formData,
        })
        .then((response) => {
          dispatch(createCampaignSuccess(response.data.id));
          dispatch(getCampaigns());
        });
      if (handleChange) handleChange();
    } catch (err) {
      dispatch(
        enqueueSnackbar({ message: err.message, options: { variant: "error" } })
      );
      dispatch(createCampaignFailure(err.message));
    }
  };

export const updateCampaign =
  (formData, id, type, handleChange) => async (dispatch) => {
    dispatch(updateCampaignRequest());
    try {
      const data = {
        [type]: formData,
      };
      await client.put(`/api/v1/campaigns/${id}/`, data).then((response) => {
        dispatch(updateCampaignSuccess(response.data));
        dispatch(getCampaigns());
      });
      if (handleChange) handleChange();
      if (type === "auditory") {
        dispatch(
          enqueueSnackbar({
            message: "Кампания успешно создана",
            options: { variant: "success" },
          })
        );
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({ message: err.message, options: { variant: "error" } })
      );
      dispatch(updateCampaignFailure(err.message));
    }
  };

export const createNewList = (file, type) => async (dispatch) => {
  dispatch(createListRequest());
  const data = new FormData();
  data.append("name", file.name);
  data.append("file", file);
  data.append("resources", "");
  const headers = {
    "Content-Type": "multipart/form-data",
    accept: "application/json",
  };
  try {
    await client
      .post(`/api/v1/lists?type_list=${type}`, data, {
        headers: headers,
      })
      .then((response) => {
        dispatch(createListSuccess(response.data));
      });
  } catch (err) {
    dispatch(createListFailure(err.message));
  }
};

export const updateCampaignRequest = () => {
  return {
    type: ACTIONS.UPDATE_CAMPAIGN_REQUEST,
  };
};

export const updateCampaignSuccess = (config) => {
  return {
    type: ACTIONS.UPDATE_CAMPAIGN_SUCCESS,
    payload: config,
  };
};

export const updateCampaignFailure = (error) => {
  return {
    type: ACTIONS.UPDATE_CAMPAIGN_FAILURE,
    payload: error,
  };
};
