import  { useEffect, useMemo, useState } from "react";
import "./myBanners.scss";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Grow, IconButton } from "@mui/material";
import { styled } from "@material-ui/core";
import { BANNER_TYPES } from "./configs";
import { useDispatch } from "react-redux";
import { saveBanner } from "../../../../redux/createBanners/actions";
import { API_HOST } from "../../../../configs/main"
import { Typography } from "../../../../ui";

const VisibleContainer = styled("div")(({ width = (window.outerWidth > 1000) ? 800 : 
  (window.outerWidth < 375) ? 350 : 440 }) => ({
  display: "flex",
  overflowY: "hidden",
  overflowX: (window.outerWidth > 1000) ? "hidden" : "scroll",
  height: "100%",
  width,
  marginLeft: (window.outerWidth > 1000) ? "0px" : "-780px",
  paddingLeft: (window.outerWidth > 1000) ? "0px" : (window.outerWidth < 375) ? "80px" : "120px",
}));
const CarouselItems = styled("ul")(
  ({ gap = (window.outerWidth > 1000) ? 8 : 2, transition = "transform 0.8s ease-in-out" }) => ({
    width: "100%",
    display: "flex",
    gap: gap,
    transition,
  })
);
const CarouselItem = styled("li")(
  ({ width = 800, show_item = 6, gap = (window.outerWidth > 1000) ? 8 : 2 }) => ({
    position: "relative",
    height: "100%",
    flex: `0 0 calc(${width}px / ${show_item} - ${gap}px)`,
    maxWidth: `calc(${width}px / ${show_item} - ${gap}px)`,
  })
);

const createBannerContent = ({ content_type, banner_path, handleDrop }) => {
  switch (content_type) {
    case BANNER_TYPES.image:
      return (
        <img
          className="banner_img"
          src={banner_path ? `${API_HOST}/${banner_path}` : ""}
          onDrop={(window.outerWidth > 1000) && ((e) => handleDrop(e, banner_path))}
          onClick={(window.outerWidth < 1000) && ((e) => handleDrop(e, banner_path))}
          onDragEnd={(window.outerWidth > 1000) && ((e) => handleDrop(e, banner_path))}
        />
      );
    case BANNER_TYPES.video:
      return (
        <video
          className="banner_img"
          src={banner_path ? `${API_HOST}/${banner_path}` : ""}
          controls={true}
          style={{ background: "black" }}
          draggable={true}
          onDrop={(window.outerWidth > 1000) && ((e) => handleDrop(e, banner_path))}
          onClick={(window.outerWidth < 1000) && ((e) => handleDrop(e, banner_path))}
          onDragEnd={(window.outerWidth > 1000) && ((e) => handleDrop(e, banner_path))}
        />
      );
    case BANNER_TYPES.html:
      return null;
    default:
      return null;
  }
};

const Carousel2 = ({
  activeFormat,
  userBanners,
  deleteBanner,
  showItem,
  width,
  gap,
  transition,
  isSortDESC,
  disable
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [counter, setCounter] = useState(0);
  const [deletedItem, setDeletedItem] = useState(null);
  const dispatch = useDispatch();
  const sortedBanners = useMemo(
    () =>
      isSortDESC
        ? [...userBanners].sort(
            (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
          )
        : [...userBanners].sort(
            (a, b) => new Date(a?.created_at) - new Date(b?.created_at)
          ),
    [isSortDESC, userBanners]
  );
  useEffect(() => {
    if (activeFormat) {
      setCounter(0);
      setIsFirstRender(true);
      setDeletedItem(null);
    }
  }, [activeFormat]);
  useEffect(() => {
    if (!isFirstRender) {
      if (
        counter &&
        userBanners.length &&
        userBanners.length % showItem === 0
      ) {
        handleMinus();
      }
      const nextPosition = !isSortDESC
        ? Math.floor((userBanners.length - 1) / showItem)
        : 0;
      setCounter(nextPosition);
    }
    if (userBanners.length) {
      setIsFirstRender(false);
    }
  }, [userBanners.length]);

  const handleMinus = () => {
    if (!userBanners.length || counter < 1) return;
    setCounter((prev) => prev - 1);
  };
  const handlePlus = () => {
    const isMax = counter >= Math.ceil(userBanners.length / showItem) - 1;
    if (!userBanners.length || isMax) return;
    setCounter((prev) => prev + 1);
  };
  const handleDelete = (id) => {
    setDeletedItem(id);
    deleteBanner(id);
    setDeletedItem(null);
  };
  const handleDrop = (e, banner_path) => {
    if (!disable) { 
      const findBannerInExistList = userBanners.find(
        (item) => item.banner_path === banner_path
      );
      dispatch(saveBanner({bannerType: 'IMAGE2', existBanner: findBannerInExistList }));
    }
  };

  return (
    <div className="carousel">
      {(window.outerWidth > 1000) ? <IconButton disabled={!userBanners.length || counter <= 0}>
        <ArrowBackIosNewIcon className="btn btn_prev" onClick={handleMinus} />
      </IconButton> : ""}
      <VisibleContainer width={width}>
        <CarouselItems
          style={{ transform: `translateX(-${counter * 100}%)` }}
          gap={gap}
          transition={transition}
        >
          {sortedBanners.map((banner, index) => {
            const { content_type, banner_path, id, data } = banner;
            return (
              <Grow
                in={deletedItem !== id}
                style={{ transformOrigin: "0 0 0" }}
                key={id ?? index}
              >
                <CarouselItem width={width} show_item={showItem} gap={gap}>
                  {createBannerContent({
                    content_type,
                    banner_path,
                    handleDrop,
                  })}
                  {content_type===BANNER_TYPES.image ? 
                    <div className="sizes" width={width}>
                      <Typography variant="body1" color="white">{`${data?.width}x${data?.height}`}</Typography>                 
                    </div> : ""}
                  <IconButton
                    className="delete"
                    onClick={() => handleDelete(id)}
                    disabled={disable}
                  >
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </IconButton>
                </CarouselItem>
              </Grow>
            );
          })}
        </CarouselItems>
      </VisibleContainer>
      {(window.outerWidth > 1000) ? <IconButton
        disabled={
          !userBanners.length ||
          counter === Math.ceil(userBanners.length / showItem) - 1
        }
      >
        <ArrowForwardIosIcon className="btn btn_next" onClick={handlePlus} />
      </IconButton> : ""}
    </div>
  );
};

export default Carousel2;
