export const userSelectors = {
  user_balance: (state) => state.user.user_balance,
  all_object: (state) => state.user,
  all_assign_properties: (state) => {
    let result = {};
    for (const key in state.user) {
      result = { ...result, ...state.user[key] };
    }
    result.user = state.user;
    result.phone_number = result?.profile?.phone_number ?? "";
    for (const key in result) {
      if (result[key] == null) result[key] = "";
    }
    return result;
  },
};
