import { AreaChart, Area, ResponsiveContainer } from "recharts";

function RechartsTinyArea({history}) {
  // const data = [
  //   {
  //     value: 25,
  //   },
  //   {
  //     value: 10,
  //   },
  //   {
  //     value: 20,
  //   },
  //   {
  //     value: 18,
  //   },
  //   {
  //     value: 13,
  //   },
  // ]
  //"https://codesandbox.io/s/tiny-area-chart-uw0k8";

    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={200}
          height={60}
          data={history}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <Area
            type="monotone"
            dataKey="value"
            stroke="#3F73F9"
            fill="rgba(63, 115, 249, 0.06)"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }

  export default RechartsTinyArea;