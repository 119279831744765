import "./Counters.scss";
import { Button, Typography } from "../../ui";
import { CountersTable } from "../../widgets";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCounters } from "../../redux/counters/actions";
import CounterDialog from "./CounterDialog";
import useYaRequest from "../../data/useYaRequest";
import { useSearchParams } from 'react-router-dom';
import {
  getUserInfo,
  getUserRequisites,
  updateUserInfo,
} from "../../redux/user/actions";
import { userSelectors } from "../../redux/user/selectors";
import useYaCount from "../../data/useYaCount";
import { API_HOST } from "../../configs/main"
import HelpCenterSharpIcon from '@mui/icons-material/HelpCenterSharp';
import CloseIcon from '@mui/icons-material/Close';

function Counters() {
  const [isDialogActive, setIsDialogActive] = useState(false);
  const [userInfoUpdated, setUserInfoUpdated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useSelector(userSelectors.all_assign_properties);
  const { mutate: coutersMutate } = useYaCount();
  const { link } = useYaRequest();
  //console.log(link);
  const dispatch = useDispatch();
  const [help, setHelp] = useState(false)

  useEffect(() => {
    dispatch(getCounters());
    dispatch(getUserRequisites());
    dispatch(getUserInfo());
    const param1 = searchParams.get('code');
    if (!user?.userInfo?.ya_metrika_active && param1 !== null) {
      const param = { "code": param1, }
      // const param = { "code" : 654234,}
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem("token"));
      fetch(`${API_HOST}/api/v1/ya-metrika/access-code`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(param),
      }).then(async response => {
        if (!response.ok) {
          alert("Ошибка подключения Яндекс Метрики!");
        }
      });
      searchParams.delete('code');
      setSearchParams(searchParams);
    }
  }, []);
  useEffect(() => {
    if (userInfoUpdated) {
      setUserInfoUpdated(false);
      (!user?.userInfo?.ya_metrika_active) ? (window.location.href = link) : setIsDialogActive(true)
    }
  }, [userInfoUpdated])
  // const handleOpenDialog = (
  //   // component = <CountersTable />,
  //   // title = "Добавление счетчика"
  // ) => {
  //   setIsDialogActive(true);
  //   //dispatch(setComponentToModal(component, title)); //, title
  // };

  return (
    <div className="Counters">
      <Typography variant="h1" color="dark-gray">
        Счетчики
      </Typography>
      {/* <Sort statistics /> */}
      <div className="div_buttons">
        {user?.userInfo?.ya_metrika_active ?
        <Button
          variant="primary"
          className="add"
          onClick={async () => {
            await dispatch(getUserInfo());
            setUserInfoUpdated(true);
          }}
        >
          Добавить счетчик
        </Button>
         :
        <Button
          variant="primary"
          className="add"
          onClick={async () => {
            await dispatch(getUserInfo());
            setUserInfoUpdated(true);
          }}
        >
          Подключить аккаунт
        </Button>
        }

        <div className="ya_account">
          <span style={{ opacity: '70%' }}>Аккаунт: {user?.userInfo?.ya_metrika_email}</span>
          <HelpCenterSharpIcon className="helpButton" onClick={() => setHelp((prev) => !prev)} 
          style={{ opacity: '50%' }} />
          {help && <div className="helpText">
            <div className="title">
              <span>Как сменить аккаунт</span>
              <CloseIcon onClick={() => setHelp(false)} 
              style={(window.outerWidth > 1000) ? {position:'relative', left:60, bottom:15}
              : {position:'relative', left:70, bottom:10}} />
            </div>
            <span>1. Удалите <a href="https://metrika.yandex.ru/preferences/delegates" style={{ color: '#3f73f9', fontSize: 14 }}>programmatic.acc</a> на странице Представители</span>
            <span>2. Удалите <a href="https://id.yandex.ru/profile/devices" style={{ color: '#3f73f9', fontSize: 14 }}>Programmatic</a> из раздела Другие программы и сервисы</span>
            <span>3. <a href="#" style={{ color: '#3f73f9', fontSize: 14 }} onClick={async () => {
              await dispatch(updateUserInfo({
                ya_metrika_email: null,
                ya_access_token: null
              }));
              //setUserInfoUpdated(true);
              coutersMutate();
              setHelp(false);
            }}>Отвяжите текущий аккаунт</a></span>
            <span>4. Привяжите другой аккаунт, нажав на кнопку Подключить аккаунт</span>
          </div>}
        </div>
      </div>
      <div className="line"></div>
      <CountersTable openDialogWithComponent={() => setIsDialogActive(true)} />
      <CounterDialog
        setActive={setIsDialogActive}
        open={isDialogActive}
      //component={modalComponent?.component}
      />
    </div>
  );
}

export default Counters;
