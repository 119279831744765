import { SEG_ACTIONS } from "./actionTypes";
import { client } from "../../utils/network";
import { enqueueSnackbar } from "../notifications/actions";


export const getSegmentsRequest = () => {
    return {
      type: SEG_ACTIONS.GET_SEGMENTS_REQUEST,
    };
};

export const getSegmentsSuccess = (segments) => {
  return {
    type: SEG_ACTIONS.GET_SEGMENTS_SUCCESS,
    payload: segments,
  };
};

export const getSegmentsFailure = (error) => {
  return {
    type: SEG_ACTIONS.GET_SEGMENTS_FAILURE,
    payload: error,
  };
};

export const createSegmentRequest = () => {
  return {
    type: SEG_ACTIONS.CREATE_SEGMENT_REQUEST,
  };
};

export const createSegmentSuccess = (segment) => {
  return {
    type: SEG_ACTIONS.CREATE_SEGMENT_SUCCESS,
    payload: segment,
  };
};

export const createSegmentFailure = (error) => {
  return {
    type: SEG_ACTIONS.CREATE_SEGMENT_FAILURE,
    payload: error,
  };
};

export const updateSegmentRequest = () => {
  return {
    type: SEG_ACTIONS.UPDATE_SEGMENT_REQUEST,
  };
};

export const updateSegmentSuccess = (config) => {
  return {
    type: SEG_ACTIONS.UPDATE_SEGMENT_SUCCESS,
    payload: config,
  };
};

export const updateSegmentFailure = (error) => {
  return {
    type: SEG_ACTIONS.UPDATE_SEGMENT_FAILURE,
    payload: error,
  };
};

export const setEditSegment = (id) => ({
  type: SEG_ACTIONS.SET_EDIT_SEGMENT,
  payload: id,
});

export const resetEditSegment = () => ({
  type: SEG_ACTIONS.RESET_EDIT_SEGMENT,
});

export const getSegments = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (!token) {
    return;
  } else {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    if (decodedToken.role !== "Role.DEFAULT") return;
  }
  dispatch(getSegmentsRequest);
  try {
    await client.get("/api/v1/new_segments").then((response) => {
    const segments = response.data;
    dispatch(getSegmentsSuccess(segments));
    });
  } catch (error) {
    dispatch(getSegmentsFailure(error.message));
    console.log(error.message);
  }
};
      
export const createSegment =
(formData, handleChange) => async (dispatch) => {
  console.log("FORM DAta", formData);
  dispatch(createSegmentRequest());
  try {
    await client
      .post("/api/v1/new_segments", formData)
      .then((response) => {
        if (response.data) dispatch(createSegmentSuccess(response.data.id));
        dispatch(getSegments());
        dispatch(enqueueSnackbar({message: "Сегмент успешно создан", options: { variant: "success" }}));
      });
    if (handleChange) handleChange();
  } catch (err) {
    dispatch(
      enqueueSnackbar({ message: err.message, options: { variant: "error" } })
    );
    dispatch(createSegmentFailure(err.message));
  }
};

export const updateSegment =
(formData, id, type, handleChange) => async (dispatch) => {
  dispatch(updateSegmentRequest());
  try {
    await client.put(`/api/v1/new_segments/${id}/`, formData).then((response) => {
      dispatch(updateSegmentSuccess(response.data));
      dispatch(getSegments());
    });
    if (handleChange) handleChange();
    if (type === "auditory") {
      dispatch(
        enqueueSnackbar({
          message: "Сегмент успешно создан",
          options: { variant: "success" },
        })
      );
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar({ message: err.message, options: { variant: "error" } })
    );
    dispatch(updateSegmentFailure(err.message));
  }
};