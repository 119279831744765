export const ACTIONS = {
  SAVE_COUNTER: "SAVE_COUNTER",
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
  SET_COUNTERS: "SET_COUNTERS",
  ADD_COUNTER: "ADD_COUNTER",
  UPDATE_COUNTER: "UPDATE_COUNTER",
  DELETE_COUNTER: "DELETE_COUNTER",
  ADD_GOAL: "ADD_GOAL",
  UPDATE_GOAL: "UPDATE_GOAL",
  SET_COMPONENT_TO_MODAL: "SET_COMPONENT_TO_MODAL",
  RESET_COMPONENT_MODAL: "RESET_COMPONENT_MODAL",
};
