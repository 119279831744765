import { ACTIONS } from "./actionTypes";
import { client } from "../../utils/network";

export const initCampaigns = (campaigns) => ({
  type: ACTIONS.INIT_CAMPAIGNS,
  payload: campaigns,
});
export const changeFilteredCampaigns = () => ({
  type: ACTIONS.CHANGE_FILTERED_CAMPAIGNS,
});

export const changeFilterValue = ({ name, value }) => ({
  type: ACTIONS.CHANGE_FILTER_VALUE,
  payload: { name, value },
});
export const changeSettingsShow = (settings) => ({
  type: ACTIONS.CHANGE_SETTINGS_SHOW,
  payload: settings,
});

export const changeCampaignActive = async (campaign) => {
  console.log("CHANGE");
  try {
    await client.put(
      `/api/v1/campaigns/${campaign.id}`,
      campaign
    );
    return;
  } catch (err) {
    throw new Error(err);
  }
};
