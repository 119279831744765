export const filterDate = (oDate, arr) => {
  const tempDate = {
    date_end: oDate.date_end || new Date(3000, 10, 10),
    date_start: oDate.date_start || new Date(3, 10, 10),
  };
  const result = arr.filter((log) => {
    if (!oDate.date_start && !oDate.date_end) return true;

    const date = new Date(log?.dt);
    const isInside = tempDate.date_start <= date && tempDate.date_end >= date;
    if (isInside) return true;

    return false;
  });
  return result;
};

export const filterEmploye = (keyword, arr) => {
  const results = arr.filter((user) => {
    const name = user.employee.split(" ")[0];
    const surname = user.employee.split(" ")[1];
    if (keyword.includes(" ")) {
      return user.employee.toLowerCase().startsWith(keyword.toLowerCase());
    }
    if (name.toLowerCase().startsWith(keyword.toLowerCase())) {
      return true;
    } else if (surname.toLowerCase().startsWith(keyword.toLowerCase())) {
      return true;
    }
    return false;
  });
  return results;
};

export const filterId = (keyword, arr) => {
  const results = arr.filter((user) => {
    console.log("aaa", arr, user.target_id_or_number)
    return user.target_id_or_number?.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};

export const filterDescription = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.description?.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};

export const filterAction = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.action?.toLowerCase() === keyword.toLowerCase();
  });
  return results;
};

export const INIT_FILTER_VALUE = {
  date: {
    date_end: "",
    date_start: "",
  },
  full_name: "",
  action: "",
  target_id_or_number: "",
  decription: ""
};
