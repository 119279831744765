import { ErrorContainer } from "../ErrorContainer";
import { Fade } from "@mui/material";
import "./FormWithText.scss";

const FormWithText = ({ children, errors = {}, successText = "" }) => {
  return (
    <div className="form-with-text">
      <ErrorContainer errors={errors} />
      {children}
      <Fade in={!!successText.length}>
        <div className="success-text">{successText}</div>
      </Fade>
    </div>
  );
};

export default FormWithText;
