import {useState} from 'react';
import {Button, Stack} from "@mui/material";
import {styled} from "@mui/system";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledButton = (window.outerWidth > 1000) ? styled(Button)(() => (`
   height:31px;
  background: #3F73F9;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: white;
  width:100%;
  border-radius: 4px 4px 0 0;
  text-transform: none;
  padding: 7px;
  & svg {
    transition: transform 0.3s ease-in-out;
  }
  & svg.active {
    transform: rotate(180deg);
  }
  & span {
  display:flex;
  align-items:center;
  }
  & span:first-of-type {
    margin-right: 14px;
  }
`)) : 
styled(Button)(() => (`
   height:31px;
  background: #3F73F9;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 11px;
  color: white;
  width:100%;
  border-radius: 4px 4px 0 0;
  text-transform: none;
  padding: 0px;
  & svg {
    transition: transform 0.3s ease-in-out;
    align-items:center;
  }
  & svg.active {
    transform: rotate(180deg);
    align-items:center;
  }
  & span {
  display:flex;
  align-items:center;
  }
  & span:first-of-type {
    margin-right: 10px;
    align-items:center;
  }
`))
const StyledStack = (window.outerWidth > 1000) ? styled(Stack)(() => `
    box-sizing:border-box;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-end;
    gap:8px;
    background: #F0F1F3;
    border: 1px solid #3F73F9;
    border-radius: 0 0 4px 4px;
    animation: fadeIn 0.6s ease-in-out forwards, addPadding 0.05s ease-in-out forwards;
    @keyframes addPadding {
      from {
      padding: 0;
      }
      to {
         padding: 12px 16px;
      }
    }
    @keyframes fadeIn {
      from {
        overflow: hidden;
        max-height: 0;
      }
      to {
      overflow: auto;
        max-height: 700px;
      }
    }
      & span {
  display:block;
  width:fit-content;
  text-align:right;
    box-sizing: border-box;
    padding: 4px 8px;
    border-radius:4px;
    & input {
      cursor:pointer
      transition: all 0.5s ease-in-out;
    }
    & input:hover {
       background: #3F73F9;
       color:white;
    }
  }
`) : (window.outerWidth < 375) ? styled(Stack)(() => `
box-sizing:border-box;
width:274px;
display:flex;
flex-direction:column;
align-items:flex-s;
gap:6px;
background: #F0F1F3;
border: 1px solid #3F73F9;
border-radius: 0 0 4px 4px;
animation: fadeIn 0.6s ease-in-out forwards, addPadding 0.05s ease-in-out forwards;
@keyframes addPadding {
  from {
  padding: 0;
  }
  to {
    padding: 4px;
  }
}
@keyframes fadeIn {
  from {
    overflow: hidden;
    max-height: 0;
  }
  to {
  overflow: hidden;
    max-height: 700px;
  }
}
  & span {
display:block;
width:100%;
text-align:center;
box-sizing: border-box;
padding: 2px 0px;
border-radius:4px;
& input {
  width: 260px;
  cursor:pointer;
  transition: all 0.5s ease-in-out;
}
& input:hover {
   background: #3F73F9;
   color:white;
}
}
`) : styled(Stack)(() => `
box-sizing:border-box;
width: 329px;
display:flex;
flex-direction:column;
align-items:flex-s;
gap:6px;
background: #F0F1F3;
border: 1px solid #3F73F9;
border-radius: 0 0 4px 4px;
animation: fadeIn 0.6s ease-in-out forwards, addPadding 0.05s ease-in-out forwards;
@keyframes addPadding {
  from {
  padding: 0;
  }
  to {
    padding: 4px;
  }
}
@keyframes fadeIn {
  from {
    overflow: hidden;
    max-height: 0;
  }
  to {
  overflow: hidden;
    max-height: 700px;
  }
}
  & span {
display:block;
width:100%;
text-align:center;
box-sizing: border-box;
padding: 2px 0px;
border-radius:4px;
& input {
  width: 320px;
  cursor:pointer;
  transition: all 0.5s ease-in-out;
}
& input:hover {
   background: #3F73F9;
   color:white;
}
}
`)
const StyledWrap = (window.outerWidth > 1000) ? styled(Stack)(() => `
 box-sizing: border-box;
  display:flex;
  border-radius: 4px;
  padding: 7px;
  min-width:260px;
  align-self: flex-end;
  margin: 0 0 0 auto;
    font-family: Lato;
  font-weight: 400;
  font-size: 14px;
  color: #3F73F9;
  line-height: 16.8px;
  text-transform: none;
`) : (window.outerWidth < 375) ? styled(Stack)(() => `
 box-sizing: border-box;
 display:flex;
 border-radius: 4px;
 padding: 7px;
 width:288px;
 align-self: flex-start;
 margin: 0 0 0 auto;
   font-family: Lato;
 font-weight: 400;
 font-size: 10px;
 color: #3F73F9;
 line-height: 16.8px;
 text-transform: none;
`) : styled(Stack)(() => `
box-sizing: border-box;
display:flex;
border-radius: 4px;
padding: 7px;
width: 343px;
align-self: flex-start;
margin: 0 0 0 auto;
  font-family: Lato;
font-weight: 400;
font-size: 10px;
color: #3F73F9;
line-height: 16.8px;
text-transform: none;
`)

export const SelectOptions = ({name, options, title, value, onChange, disable}) => {
  const [expanded, setExpanded] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value ?? '')
  const handleClick = (e) => {
    const option = e.target.value
    if (option === selectedValue) return
    onChange(e.target.value)
    setSelectedValue(option)
    setExpanded(false)
  }
  const handleExpand = () => setExpanded(prev => !prev)

  return (
    <StyledWrap>
      <StyledButton disabled={disable} variant="contained" onClick={handleExpand}>
        <span>{title}</span>
        <span>{selectedValue} <KeyboardArrowDownIcon className={expanded ? 'active' : ''}/></span>
      </StyledButton>
      {expanded &&
        <StyledStack>
          {Object.keys(options).map((key) => <span onClick={handleClick} key={key} value={options[key]} name={name}>
            <input readOnly style={{cursor:'pointer'}} key={key} value={options[key]} name={name}
            />
          </span>)}
        </StyledStack>
      }
    </StyledWrap>
  );
};
