import { memo, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          color: "rgb(160,162,165)",
          marginTop: "-1px",
        },
      },
    },
  },
});

function Dropdown({
  label,
  onChange,
  onBlur,
  options,
  name,
  value,
  withCheckboxes,
  isTimeSettings,
  disabled,
  helperText,
  resetTimeRange = false,
  style,
  error,
  placeholder
}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleTimeSettings = (event, name) => {
    const {
      target: { value },
      // eslint-disable-next-line no-restricted-globals
    } = event;

    onChange(name, value, resetTimeRange);
    // if (resetTimeRange) {
    // 	resetTimeRange();
    // }
  };

  return (
    <div>
      <FormControl fullWidth sx={{ width: "100%" }} error={error}>
      {(style) && placeholder ? <ThemeProvider theme={theme}>
          <InputLabel size="small">{label}</InputLabel>
        </ThemeProvider> : <InputLabel id="demo-controlled-open-select-label">{label}</InputLabel>}
        {!withCheckboxes ? (
          <Select
            disabled={disabled}
            name={name}
            labelId="demo-controlled-open-select-label"
            label={label}
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            style={style}
            sx={(window.outerWidth > 1000) ? {} : {"& fieldset": { border: 'none' }, fontSize: "12px"}}
            // input={<OutlinedInput label="Tag" />}
          >
            {options
              ? options.map(({ id, optionValue, optionLabel }) => (
                  <MenuItem sx={(window.outerWidth > 1000) ? {} : { fontSize:"12px", background: "#f0f1f3", margin: "-6x"}} key={id} value={optionValue}>
                    {optionLabel}
                  </MenuItem>
                ))
              : [<MenuItem key={1} value={10}>Ten</MenuItem>]}
          </Select>
        ) : (
          <Select
            disabled={disabled}
            multiple
            name={name}
            label={label}
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={value || []}
            renderValue={(value) => value.join(", ")}
            onChange={
              isTimeSettings
                ? (event) => handleTimeSettings(event, name)
                : onChange
            }
            input={<OutlinedInput label={label} />}
          >
            {options
              ? options.map(({ id, optionValue, optionLabel }) => (
                  <MenuItem key={id} value={optionValue}>
                    <Checkbox checked={value.indexOf(optionValue) > -1} />
                    <ListItemText primary={optionLabel} />
                  </MenuItem>
                ))
              : [<MenuItem key={1} value={10}>Ten</MenuItem>]}
          </Select>
        )}
        {typeof helperText === "string" ? (
          <FormHelperText style={{ height: "5px", color: "#D32F2F" }}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}

export default memo(Dropdown);
