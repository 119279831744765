import useSWR  from "swr"

export default function useYaCount() {
    // eslint-disable-next-line no-unused-vars
    const { data, error, mutate } = useSWR("api/v1/ya-metrika/counters")

    return {
        counters: data,
        mutate
    }
}