import "./Select.scss";
import cx from "classnames";

import { Dropdown } from "../";
import { ReactComponent as DeltaIcon } from "../../assets/images/arrow-gray.svg";
import { ReactComponent as CheckboxIcon } from "../../assets/images/checkbox.svg";

function Select({ placeholder, variant = "white", options, onChange }) {
  const handleSelect = (obj) => {
    const newSelectValue = obj.selected
      ? options.filter(({ value, selected }) => value !== obj.value && selected)
      : options.filter(({ selected }) => selected).concat(obj);
    if (onChange) onChange(newSelectValue);
  };

  if (window.outerWidth < 768) variant = "gray"

  return (
    <div className={cx("Select", variant)}>
      <Dropdown
        button={
          <div className="select">
            {options.every((el) => !el.selected) && (
              <div className="placeholder-value">
                <div className="placeholder">{placeholder}</div>
                <DeltaIcon />
              </div>
            )}
            {options.filter((el) => el.selected).map((obj, index,arr) => 
              <span key={index}>
                {obj.label}
                {index + 1 !== arr.length && ","}
              </span>
            )}
          </div>
        }
      >
        {options &&
          options.map((obj, index) => {
            return (
              <button
                key={index}
                type="button"
                onClick={() => handleSelect(obj)}
              >
                {obj.selected !== null && <span
                  className={cx("rectangle", {
                    selected: obj.selected,
                  })}
                >
                   <CheckboxIcon
                    className={cx("rectangle-icon", {
                      selected: "rectangle-icon__visible",
                    })}
                  />
                </span>}
                {obj.label}
              </button>
            );
          })}
      </Dropdown>
    </div>
  );
}

export default Select;

