/* eslint-disable react/no-unknown-property */
import {IconButton, styled} from "@mui/material";
import Stack from "@mui/material/Stack";
import {BANNER_TYPES} from "./configs";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {useDispatch} from "react-redux";
import {deletePreview, deletePreview2, deletePreview3} from "../../../../redux/createBanners/actions";
import { API_HOST } from "../../../../configs/main"

const StyledBox = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  width: (window.outerWidth > 1000) ? "100%" : (window.outerWidth < 375) ? "288px" : 
  (window.outerWidth < 768) ? "343px" : "280px",
  height: (window.outerWidth > 1000) ? 290 : 200,
  border: '1px solid #3F73F9',
  borderRadius: '4px',
  background: '#DEE7FF',
  marginBottom: 10,
  '& img, & video': {
    maxWidth: (window.outerWidth > 1000) ? '100%' : '80%',
    maxHeight: (window.outerWidth > 1000) ? '100%' : '80%'
  },
  '& .delete': {
    position: 'absolute',
    right: 5,
    top: 5,
    color: '#9C9EA3',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: 'red'
    }
  },
  '& a':{
    color:'#3F73F9',
  }
}))

const StyledBoxIcon = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  width: 90,
  height: 90,
  border: '1px solid #3F73F9',
  borderRadius: '4px',
  background: '#DEE7FF',
  marginBottom: 10,
  '& img, & video': {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  '& .delete': {
    position: 'absolute',
    right: 5,
    top: 5,
    color: '#9C9EA3',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: 'red'
    }
  },
  '& a':{
    color:'#3F73F9',
  }
}))
const setBgSize = ([width, height]) => {
  return {width: width + 'px', height: height + 'px'}
}

const API_URL = `${API_HOST}`
const PreviewBox = (({previewSize, preview, preview2, preview3, activeFormat, disable}) => {
  const dispatch = useDispatch()
  const handleDelete = () => {
    dispatch(deletePreview())
  }
  const handleDelete2 = () => {
    dispatch(deletePreview2())
  }
  const handleDelete3 = () => {
    dispatch(deletePreview3())
  }
  /*if ((!preview) && data) {
    preview = data;
  }
  if ((!preview2) && icon) {
    preview2 = icon;
  }
  if ((!preview3) && overlay) {
    preview3 = overlay;
  }*/
  if (!activeFormat?.bannerType) {
    if ((activeFormat?.bannerType === 'VIDEO') || (activeFormat?.name === 'MULTIFORM')) {
      return (<Stack  width={(window.outerWidth > 1000) ? "590px" : (window.outerWidth < 375) ?  "288px" : "343px"}
       flexDirection={(window.outerWidth > 1000) ? 'row' : 'column'} justifyContent='space-between'>
            <StyledBoxIcon> <a className="text">{activeFormat?.icon_size}</a></StyledBoxIcon>
            <Stack width={(window.outerWidth > 1000) ? "490px" : (window.outerWidth < 375) ?  "288px": "343px"} >
              <StyledBox><a className="text">{activeFormat?.sizes}</a></StyledBox>
              <StyledBox><a className="text">{activeFormat?.background_size}</a></StyledBox>
            </Stack>
            </Stack>)
    }
    else return (<StyledBox><a className="text">{activeFormat?.sizes}</a></StyledBox>)
  }
  let src = ""
  if ((preview && preview.banner_path) && (activeFormat?.bannerType !== 'VIDEO')) {
    if ((activeFormat?.sizes.join(' ').split('x')[0] === preview.data.width)
       &&(activeFormat?.sizes.join(' ').split('x')[1] === preview.data.height)) {
      src = `${API_URL}/${preview.banner_path}`
    }
    else handleDelete()
  } else if (preview && activeFormat?.bannerType === 'VIDEO') src = `${API_URL}/${preview.banner_path}`
  let src_video = ""
  let src_icon = ""
  let src_background = ""
  console.log(preview,preview2,preview3)
  if ((activeFormat?.bannerType === 'VIDEO') || (activeFormat?.name === 'MULTIFORM')) {
    src_video = src 
    if (preview2?.banner_path) {
      if ((activeFormat?.icon_size.join(' ').split('х')[0] === preview2.data.width)
       &&(activeFormat?.icon_size.join(' ').split('х')[1] === preview2.data.height)) {
        src_icon = `${API_URL}/${preview2.banner_path}`
      }
      else handleDelete2()
    }
    if (preview3?.banner_path) {
      if ((activeFormat?.background_size.join(' ').split('х')[0] === preview3.data.width)
       &&(activeFormat?.background_size.join(' ').split('х')[1] === preview3.data.height)) {
      src_background = `${API_URL}/${preview3.banner_path}`
      }
      else handleDelete3()
    }
  }
  // eslint-disable-next-line default-case
  switch (activeFormat?.bannerType) {
    case BANNER_TYPES.image:
      if (activeFormat?.name === 'MEDIA') {
      return (
        <StyledBox>
          <IconButton className="delete" onClick={handleDelete} disabled={disable}>
            <DeleteOutlineOutlinedIcon fontSize="small"/>
          </IconButton>
          {preview?.banner_path ? <img style={previewSize && setBgSize(previewSize.split('x'))}
               src={src}/> : (<a className="text">{activeFormat?.sizes}</a>)}
        </StyledBox>
      )}
      else if (activeFormat?.name === 'MULTIFORM') {
        return (
          <Stack width={(window.outerWidth > 1000) ? "590px" : (window.outerWidth < 375) ? "288px" :
          (window.outerWidth < 768) ? "343px" : "280px"} 
          flexDirection={(window.outerWidth > 1000) ? 'row' : 'column'} justifyContent='space-between'>
          <StyledBoxIcon>
            <IconButton className="delete" onClick={handleDelete2} disabled={disable}>
              <DeleteOutlineOutlinedIcon fontSize="small"/>
            </IconButton>
            {preview2?.banner_path ? (<img src={src_icon} controls={true}/>) : (<a className="text">{activeFormat?.icon_size}</a>)}
          </StyledBoxIcon>
          <Stack width="490px" >
          <StyledBox flexDirection='row' >
            <IconButton className="delete" onClick={handleDelete} disabled={disable}>
              <DeleteOutlineOutlinedIcon fontSize="small"/>
            </IconButton>
            {preview?.banner_path ? <img style={previewSize && setBgSize(previewSize.split('x'))} src={src}/> : (<a className="text">{activeFormat?.sizes}</a>)}
          </StyledBox>
          <StyledBox>
            <IconButton className="delete" onClick={handleDelete3} disabled={disable}>
              <DeleteOutlineOutlinedIcon fontSize="small"/>
            </IconButton>
            {preview3?.banner_path ? (<img src={src_background} controls={true}/>) : (<a className="text">{activeFormat?.background_size}</a>)}
          </StyledBox>
          </Stack>
          </Stack>
        ) 
      } else break;
    case BANNER_TYPES.video:
      return (
        <Stack width={(window.outerWidth > 1000) ? "590px" : (window.outerWidth < 375) ? "288px" : 
        (window.outerWidth < 768) ? "343px" : "280px"} 
        flexDirection={(window.outerWidth > 1000) ? 'row' : 'column'} justifyContent='space-between'>
          <StyledBoxIcon>
        <IconButton className="delete" onClick={handleDelete2} disabled={disable}>
          <DeleteOutlineOutlinedIcon fontSize="small"/>
        </IconButton>
        {preview2?.banner_path ? (<img src={src_icon} controls={true}/>) : (<a className="text">{activeFormat?.icon_size}</a>)}
      </StyledBoxIcon>
      <Stack width="490px" >
        <StyledBox>
          {src_video!=='' ? <video src={src_video} controls={true}/> : <a className="text">{activeFormat?.sizes}</a>}
          <IconButton className="delete" onClick={handleDelete} disabled={disable}>
            <DeleteOutlineOutlinedIcon fontSize="small"/>
          </IconButton>
        </StyledBox>
      <StyledBox>
        <IconButton className="delete" onClick={handleDelete3} disabled={disable}>
          <DeleteOutlineOutlinedIcon fontSize="small"/>
        </IconButton>
        {preview3?.banner_path ? (<img src={src_background} controls={true}/>) : (<a className="text">{activeFormat?.background_size}</a>)}
      </StyledBox>
      </Stack>
      </Stack>
      )
    case BANNER_TYPES.html:
      return (
        <StyledBox>
          <IconButton className="delete" onClick={handleDelete} disabled={disable}>
            <DeleteOutlineOutlinedIcon fontSize="small"/>
          </IconButton>
          <a className="text"><b>{src.replace(`${API_HOST}/static/user_banners/`,'')}</b></a>
        </StyledBox>
      )
  }
})

export default PreviewBox;