import { ACTIONS } from "./actionTypes";

const initialState = {
  campaigns: [],
  loading: true,
  error: "",
  id: "",
  errorPostCampaigns: "",
  errorCreateList: "",
  list: null,
  editCampaign: null,
};

export const createNewCompanyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.CREATE_NEW_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.SET_EDIT_CAMPAIGN:
      return {
        ...state,
        id: payload,
        editCampaign: state.campaigns.find(
          (campaign) => campaign.id === payload
        ),
      };
    case ACTIONS.RESET_EDIT_CAMPAIGN:
      return {
        ...state,
        id: "",
        editCampaign: null,
      };
    case ACTIONS.CREATE_NEW_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        id: payload,
      };
    case ACTIONS.CREATE_NEW_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        errorPostCampaigns: payload,
      };
    case ACTIONS.GET_CAMPAIGNS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: payload,
        error: "",
        editCampaign: state.id
          ? payload.find((campaign) => campaign.id === state.id)
          : null,
      };
    case ACTIONS.GET_CAMPAIGNS_FAILURE:
      return {
        ...state,
        loading: false,
        campaigns: [],
        error: payload,
      };
    case ACTIONS.UPDATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS.UPDATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        errorPostCampaigns: payload,
      };
    case ACTIONS.CREATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.CREATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case ACTIONS.CREATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorCreateList: payload,
      };
    default:
      return state;
  }
};
