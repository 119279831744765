import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import FirstSettings from "../stages/FirstSettings";
import MediaPlan from "../stages/MediaPlan";
import Audience from "../stages/Audience";
import Banners from "../stages/Banners/Banners";
import Optimization from "../stages/Optimization";
import { selectCreateCampaigns } from "../../../redux/createNewCompany/selectors";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LAST_TAB = 6;

function CompanyContainer() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const { id, editCampaign } = useSelector(selectCreateCampaigns.all_object);

  let disable = false
  if (editCampaign && editCampaign.media_plan?.date_end) {
    var d1 = new Date();
    var d2 = new Date(editCampaign.media_plan.date_end);
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    if (d2.getTime() < d1.getTime()) {
      disable = true;
    }
  }

  if (editCampaign?.is_activated == true) {
    disable = true
  }

  const handleChange = (event, newValue) => {
    if (newValue < LAST_TAB) setValue(newValue);
    else navigate("/");
  };

  return (
    <div className="wraper">
      <Box sx={{ typography: "body1" }}>
        <TabContext value={value}>
          <Box
            className="tes"
            sx = {(window.outerWidth > 1000) ? 
              {boxShadow: 3,
              width: "100%",
              borderColor: "divider",
              padding: "4px",
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "25px",
            } : (window.outerWidth < 768) ?  {
              width: "343", 
              backgroundColor: "white",
              height: "55px",
              marginLeft: "-30px",
              // overflowX: "scroll",
            } : {
              width: "670px", 
              height: "55px",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons="auto"
              sx={(window.outerWidth > 1000) ? {
                display: "flex",
                padding: "15px",
                marginLeft: "38px",
              } : (window.outerWidth < 768) ?  {
                display: "flex",
                // flexDirection: "row",
                // overflowX: "scroll",
                width: "343px", 
                height: "100%",
                marginLeft: "10px",
                position: "relative",
                typography: "h5"
                // padding: "15px",
                // marginLeft: "38px",
              } : {
                display: "flex",
                background: "#f0f1f3",
                borderRadius: '10px',
                width: "670px", 
                height: "100%",
                position: "relative",
                typography: "h6"
              }}
            >
              <Tab
                icon={<DataSaverOffIcon />}
                iconPosition="start"
                label="Основные настройки"
                value="1"
                sx={{}}
              />

              <Tab
                icon={<DataSaverOffIcon />}
                iconPosition="start"
                label="Медиа план"
                value="2"
                disabled={!id && +value < 2}
                sx={(window.outerWidth > 1000) ? {
                  marginLeft: "80px",
                } : {}}
              />
              <Tab
                icon={<DataSaverOffIcon />}
                iconPosition="start"
                label="Баннеры"
                value="3"
                disabled={!id && +value < 3}
                sx={(window.outerWidth > 1000) ? {
                  marginLeft: "80px",
                } : {}}
              />
              <Tab
                icon={<DataSaverOffIcon />}
                iconPosition="start"
                label="Оптимизация"
                value="4"
                disabled={!id && +value < 4}
                sx={(window.outerWidth > 1000) ? {
                  marginLeft: "80px",
                } : {}}
              />

              <Tab
                icon={<DataSaverOffIcon />}
                iconPosition="start"
                label="Аудитория"
                value="5"
                disabled={!id && +value < 5}
                sx={(window.outerWidth > 1000) ? {
                  marginLeft: "80px",
                } : {}}
              />
            </TabList>
          </Box>
          <TabPanel
            className="panel"
            value="1"
            style={(window.outerWidth > 767) ? { borderBottomLeftRadius: 20, borderBottomRightRadius: 25 } : {margin: "-40px", marginTop: "10px"} }
          >
            <FirstSettings
              handleChange={handleChange}
              campaignID={id}
              isNew={!id}
              data={editCampaign ? editCampaign.basic_config : null}
              disable={disable}
            />
          </TabPanel>
          <TabPanel
            value="2"
            className="panel"
            style={(window.outerWidth > 767) ? { borderBottomLeftRadius: 20, borderBottomRightRadius: 25 } : {margin: "-40px"}}
          >
            <MediaPlan
              handleChange={handleChange}
              campaignID={id}
              data={editCampaign ? editCampaign.media_plan : null}
              disable={disable}
            />
          </TabPanel>
          <TabPanel
            value="3"
            className="panel"
            style={(window.outerWidth > 767) ? { borderBottomLeftRadius: 20, borderBottomRightRadius: 25 } : {margin: "-40px"}}
          >
            <Banners
              handleChange={handleChange}
              campaignID={id}
              data={editCampaign ? editCampaign.banners : null}
              editCampaign={editCampaign}
              disable={disable}
            />
          </TabPanel>
          <TabPanel
            className="panel"
            value="4"
            style={(window.outerWidth > 767) ? { borderBottomLeftRadius: 20, borderBottomRightRadius: 25 } : {margin: "-40px"}}
          >
            <Optimization
              handleChange={handleChange}
              campaignID={id}
              data={editCampaign ? editCampaign : null} //безопасно ли?
              disable={disable}
            />
          </TabPanel>
          <TabPanel
            className="panel"
            value="5"
            style={(window.outerWidth > 767) ? { borderBottomLeftRadius: 20, borderBottomRightRadius: 25 } : {margin: "-40px"}}
          >
            <Audience handleChange={handleChange} 
              campaignID={id} 
              data={editCampaign ? editCampaign.new_segment_id : ""}
              disable={disable}
            />
          </TabPanel>
        </TabContext>
      </Box>
      {/* {inActive && <p>RED</p>} */}
    </div>
  );
}

export default CompanyContainer;
