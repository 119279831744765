import { useState } from "react";

// const addMissingCharacters = (value, mask) => {
//   if (value.length >= mask.length) return value.slice(0, mask.length);
//   const start = value.match(/^\+\d* /i) ? value.match(/^\+\d* /i)[0] : '';

//   let remainingValue = [
//     ...value.slice(start.length).replace(/\D/gi, ""),
//   ].reverse();
//   const remainingMask = mask.slice(start.length);

//   let result = "";
//   let currentMaskIndex = 0;
//   while (remainingValue.length > 0) {
//     const currentMaskSymbol = remainingMask[currentMaskIndex];
//     if (currentMaskSymbol !== "_") {
//       result += currentMaskSymbol;
//     } else {
//       result += remainingValue.pop();
//     }
//     currentMaskIndex++;
//   }
//   return start + result;
// };

const initialMask = "+7 (___) ___-__-__";
const TelephoneInput = ({
  mask = initialMask,
  name,
  id,
  className,
  onChange,
  onBlur
}) => {
  const [input, setInput] = useState("");
  const handleInput = (e) => {
    // if(e.target.value.includes('+') && !mask.includes('+')) {
    //   e.target.value = e.target.value.slice(3)
    // }
    // e.target.value = addMissingCharacters(e.target.value, mask);
    setInput(e.target.value);
    onChange(e);
  };
  return (
    <input
      placeholder={mask}
      onChange={handleInput}
      onBlur={onBlur}
      name={name}
      id={id}
      className={className}
      value={input}
    />
  );
};

export default TelephoneInput;
