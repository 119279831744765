import { user as actionsType } from "../actionsType";
import { client } from "../../utils/network";
import { paths } from "./paths";

export const setUserData = (user) => ({
  type: actionsType.SET_USER_DATA,
  payload: user,
});
export const setUserInfo = (payload) => ({
  type: actionsType.USER_SET_INFO,
  payload,
});

export const setUserRequisites = (payload) => ({
  type: actionsType.USER_SET_REQUISITES,
  payload,
});

export const updateUserInfo = (payload) => async (dispatch) => {
  await client
    .put(paths.user, {
      ...payload,
    })
    .then((res) => {
      dispatch(setUserInfo(res.data));
    })
    .catch((err) => console.log("err - ", err));
};

export const getUserInfo = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (!token) {
    return;
  }
  await client
    .get(paths.user)
    .then((res) => {
      dispatch(setUserInfo(res.data));
    })
    .catch((err) => console.log("getUserInfo err - ", err));
};

export const updateUserRequisites = (payload) => async (dispatch) => {
  await client
    .put(paths.userRequisites, {
      ...payload,
    })
    .then((res) => {
      dispatch(setUserRequisites(res.data));
    })
    .catch((err) => console.log("err - ", err));
};

export const getUserRequisites = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (!token) {
    return;
  } else {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    if (decodedToken.role !== "Role.DEFAULT") return;
  }
  await client
    .get(paths.userRequisites)
    .then((res) => {
      console.log("getUserRequisites res - ", res);
      dispatch(setUserRequisites(res.data));
    })
    .catch((err) => console.log("getUserRequisites err - ", err));
};
