import {useLayoutEffect, useMemo, useRef} from "react";

const throttle = (cb, ms) => {
  let isReady = true
  let timerId = null
  return (...args) => {
    if (isReady) {
      cb(...args)
      isReady = false
      clearTimeout(timerId)
      timerId = setTimeout(() => {
        isReady = true
      }, ms)
    }
  }
}
export const useLatest = (value) => {
  const valueRef = useRef(value)
  useLayoutEffect(() => {
    valueRef.current = value
  }, [value])
  return valueRef
}
export const useThrottle = (cb, ms) => {
  const latestCb = useLatest(cb)
  return useMemo(() => {
    return throttle((...args) => {
      latestCb.current(...args)
    }, ms)
  }, [ms, latestCb])
}