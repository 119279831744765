import cx from "classnames";
import { useFormikContext } from "formik";
import { DatePicker } from "../";
import {  Select } from "../../ui";
import SelectCounter from "../../ui/SelectCounter/SelectCounter"; 
import { client } from "../../utils/network";
import { useState,useEffect } from "react";
import "./Sort.scss";

export function StatisticsSort() {
  const formik = useFormikContext();
  const [counters, setCounters] = useState([]);
  const [prevSelectedGoals, setPrevSelectedGoals] = useState([]);
  useEffect(async () => {
    try {
      const { data } = await client("/api/v1/ya-metrika/counters");
      if (!data || !data.length) {
        return setCounters([
          {
            optionValue: "",
            optionLabel: "У Вас нет созданных счетчиков",
            id: 1234,
          },
        ]);
      }
      setCounters(
        data.map((counter) => ({
          optionValue: counter.id,
          optionLabel: counter.name,
          ...counter,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  let campaignsOptions = [];
  if (formik.values?.metric_counter) {
    let arr = []
    counters.map((item) => {
      if (item.id === formik.values?.metric_counter) {
        if (item?.basic_configs?.length!==0) {
          item.basic_configs.map((campaign) => 
            arr.push({
            label: campaign.name,
            value: campaign.campaign_id,
            selected: formik.values?.selected_campaigns?.some(
              (item) => item === campaign.campaign_id)
            })
          )
        } else arr.push({
          label: "Нет кампаний",
          value: "0",
          selected: false
        })
        //console.log(arr)
      }
    });
    campaignsOptions = arr
    //console.log(campaignsOptions)
  } 

  const createAllGoalsOption = () => ({
    label: "Все цели",
    value: "all_goals",
    selected: formik.values?.selected_goals?.includes("all_goals"),
  });
  
  let goalOptions = []
  if (formik.values?.metric_counter) {
    let arr = []
    counters.map((item) => {
      if (item.id === formik.values?.metric_counter)
      {
        if (item?.goals?.length!==0) {
          item.goals.map((goal) => 
          arr.push({
          label: goal.name,
          value: goal.id,
          selected: formik.values?.selected_goals?.some(
            (item) => item === goal.id
          ),
        }))
        } else arr.push({
          label: "Нет целей",
          value: "0",
          selected: null
        })
      }
    });
    if (!arr.some((goal) => goal.label === "Нет целей")) {
      goalOptions = [createAllGoalsOption(), ...arr];
    } else {
      goalOptions = arr
    }
  }

  const XOROptions = {
    values: [],
    init(initialValues) {
      this.values = initialValues;
    },
    push(value) {
      if (this.values.includes(value)) {
        this.value = this.values.splice(this.values.indexOf(value), 1);
      } else {
        this.values.push(value)
      }
    }
  }

  return (
    <div className="Sort">
      <div className={cx("container", "container-statistics")}>
        <SelectCounter
          options={counters}
          value={formik.values.metric_counter ?? ""}
          name="metric_counter"
          placeholder="Счетчики"
          onChange={selected => {formik.setFieldValue("metric_counter",selected)
            formik.setFieldValue("selected_campaigns",[])
            formik.setFieldValue("selected_goals",[])}} 
        />
        <Select
          placeholder="Кампании"
          options={(formik.values?.metric_counter) ? campaignsOptions : []}
          onChange={selectedOptions => {
            formik.setFieldValue(
              "selected_campaigns",
              selectedOptions?.map(({ value }) => value)
            );
          }}
        />
        <Select
          placeholder="Цели"
          options={formik.values?.metric_counter ? goalOptions : []}
          onChange={(selectedOptions) => {
            let selectedValues = selectedOptions?.map(({ value }) => value) || []
            
            XOROptions.init([...prevSelectedGoals]);
            selectedValues.forEach(element => XOROptions.push(element));

            const changedOption = XOROptions.values[0];
            const isAddedNewOption = selectedValues.length > prevSelectedGoals.length;
            const isAllGoalsSelected = () => !goalOptions.filter(({value}) => !selectedValues.includes(value) && value !== "all_goals").length;
            const removeAllGoalsOption = () => {
              if (selectedValues.indexOf("all_goals") !== -1) {
                selectedValues.splice(selectedValues.indexOf("all_goals"), 1);
              }
            }
            if (isAddedNewOption && changedOption == "all_goals") {
              const _options = goalOptions.map(({ value }) => value);
              formik.setFieldValue("selected_goals", _options);
              setPrevSelectedGoals(_options);
              return true
            } else if (!isAddedNewOption && changedOption == "all_goals") {
              formik.setFieldValue("selected_goals", []);
              setPrevSelectedGoals([]);
              return true
            } else if (isAddedNewOption && changedOption != "all_goals") {
              if (isAllGoalsSelected()) {
                selectedValues.push("all_goals");
              } else {
                removeAllGoalsOption();
              }
            } else if (!isAddedNewOption && changedOption != "all_goals") {
              removeAllGoalsOption();
            }

            formik.setFieldValue("selected_goals", selectedValues);

            setPrevSelectedGoals(selectedValues);
          }}
        />
        <DatePicker />
      </div>
    </div>
  );
}