import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import "./myBanners.scss";
import CircularProgress from "@mui/material/CircularProgress";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { bannersSelector } from "../../../../redux/createBanners/selectors";
import { saveBanner } from "../../../../redux/createBanners/actions";
import { Typography } from "../../../../ui";
import CropImage from "./CropImage";

export const DropArea = ({
  sizes = [],
  accept,
  bannerType,
  userBanners,
  preview,
  isForceClick,
  setIsForceClick,
  disable,
  isCarousel,
  isCarousel2,
  isCarousel3
}) => {
  const [classes, setClasses] = useState(["droparea"]);
  const { bannerError, isBannerLoading } = useSelector(
    bannersSelector.all_object
  );
  const [image, SetImage] = useState("");
  const [cropmodal, setCropModal] = useState(false);
  const dispatch = useDispatch();
  



  // const onDrop = (files) => {
  //   setIsForceClick(false);
  //   if (!files) return;
  //   const findBannerInExistList = userBanners.find((banner) => {
  //     const bannerPath = banner.banner_path.split("/").slice(-1)[0];
  //     return bannerPath === files[0]?.name;
  //   });
  //   SetImage(files[0]);
  //   dispatch(
  //     saveBanner({
  //       format: { sizes },
  //       file: files[0],
  //       bannerType,
  //       existBanner: findBannerInExistList,
  //     })
  //   );

  //   handleDragLeave();
  // };

  const onDrop = (files) => {
    setIsForceClick(false);
    if (!files) return;
    const findBannerInExistList = userBanners.find((banner) => {
      const bannerPath = banner.banner_path.split("/").slice(-1)[0];
      return bannerPath === files[0]?.name;
    });
    SetImage(files[0]);
    if (bannerType !== "VIDEO") {
      if (isCarousel2) {
        bannerType = "IMAGE2"
        sizes = ["256x256"]
      } else if (isCarousel3) {
        bannerType = "IMAGE3"
        sizes = ["1080x607"]
      } else if (isCarousel) {
        bannerType = "IMAGE"
        sizes = ["600x600"]
      }
    }
    dispatch(
      saveBanner({
        format: { sizes },
        file: files[0],
        bannerType,
        existBanner: findBannerInExistList,
      })
    );
    
    handleDragLeave();
  };

  const handleDragOver = () => {
    setClasses((prev) => {
      if (!prev.includes("active")) {
        return [...prev, "active"];
      }
      return prev;
    });
  };
  const handleDragLeave = () => {
    setClasses((prev) => {
      if (prev.includes("active")) {
        return prev.filter((item) => item !== "active");
      }
      return prev;
    });
  };

  if (bannerType === "VIDEO") {
    if (isCarousel) {
      accept = "video/*"
    } else {
      bannerType = "IMAGE"
      accept = "image/*"
    }
  }
  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    onDrop,
    multiple: false,
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
  });

  useEffect(() => {
    if (isForceClick) {
      open();
      setIsForceClick(false);
    }
  }, [isForceClick]);

  if (bannerError) {
    const input = getInputProps();
    if (input.ref.current) {
      input.ref.current.value = "";
    }
    let message = bannerError
    if (bannerError!=='' && bannerError.indexOf("expected_size") >= 0) {
      message = JSON.parse(bannerError)
      if (!cropmodal && (Math.abs(message?.expected_size[0]-message?.given_size[0])<=10) &&
      (Math.abs(message?.expected_size[1]-message?.given_size[1])<=10)&& image!=="" && 
      (window.outerWidth > 1000)) setCropModal(true);
      message = message.message
    }
    
    return (
      <div {...getRootProps({ className: "droparea" })}>
        {
          cropmodal ?
          "" : <input {...getInputProps()} />
        }
        <Typography variant="body1" color="orange">
          {message}
        </Typography>
        {
          cropmodal ?
          <CropImage file={image} setFile={SetImage} sizes={sizes} bannerType={bannerType} setActive={setCropModal}/> : ""
        }
      </div>
    );
  }
  if (isBannerLoading) {
    return (
      <div {...getRootProps({ className: classes.join(" ") })}>
        <CircularProgress />
        {(bannerType==="VIDEO") ? <Typography variant="body1" color="blue">Видео загружается</Typography> : ""}
      </div>
    );
  }
  return (
    <div {...getRootProps({ className: classes.join(" ") })}>
      <input disabled={disable ? "disabled" : ""} {...getInputProps()} />
      {preview[0] ? (
        <ThumbUpAltIcon sx={{ fontSize: 60 }} color="primary" />
      ) : (
        <>
          <Typography
            className="title_small"
            variant="nope"
            color="blue"
          >
          </Typography>
          
          <Typography className="title_small" variant="nope" color="blue">
            Перетащите баннер из медиатеки или кликните для загрузки
            с вашего компьютера
          </Typography>
        </>
      )}
    </div>
  );
};
