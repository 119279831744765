import cx from "classnames";
import { ReactComponent as DeltaIcon } from "../../assets/images/arrow-gray.svg";
import { campaignsSelector } from "../../redux/campaignsInfo/selectors";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  changeFilteredCampaigns,
  changeFilterValue,
} from "../../redux/campaignsInfo/actions";
import SelectStatus from "../../ui/SelectStatus/SelectStatus";
import SelectCampaigns from "../../ui/SelectCampaigns/SelectCampaigns";

const statusOptions = [
  {type: 'active', value: 'Работает'},
  {type: 'moderation', value: 'На модерации'},
  {type: 'error', value: 'Ошибка'},
  {type: 'no_active', value: 'Неактивна'},
  // {type: 'archive', value: 'В архиве'}
]
const createOptions = (campaigns, filteredCampaigns) => {
  const options = campaigns.map((campaign) => {
    const necessaryData = {
      label: campaign.basic_config.name,
      value: campaign.basic_config.id,
      selected: filteredCampaigns.some(
        (item) => item.basic_config.id === campaign.basic_config.id
      ),
    };
    return necessaryData;
  });
  return options;
};
const createStatusOptions = (statusOptions, filterStatus) => {
  const options = statusOptions.map((status) => {
    const necessaryData = {
      label: status.value,
      type: status.type,
      selected: filterStatus.some(
        (item) => item === status.type
      ),
    };
    return necessaryData;
  });
  return options;
};
const validationSchema = Yup.object().shape({
  search: Yup.string(),
  campaigns: Yup.string(),
  fromDate: Yup.date(),
  toDate: Yup.date(),
  budget: Yup.number(),
  rate: Yup.number(),
});

function SortCompany() {
  const { filteredCampaigns, filterValue, campaigns } = useSelector(
    campaignsSelector.all_object
  );
  const dispatch = useDispatch();
  // const handleSign = (value, name) => {
  //   const obj = {
  //     target: {
  //       name,
  //       value: value.type,
  //     },
  //   };
  //   handleInputChange(obj);
  // };
  // const handleDateChange = (value) => {
  //   dispatch(
  //     changeFilterValue({
  //       name: "media_plan",
  //       value: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
  //     })
  //   );
  //   dispatch(changeFilteredCampaigns());
  // };
  const handleInputChange = (event) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { name, value } = event?.target;
    dispatch(changeFilterValue({ name, value }));
    dispatch(changeFilteredCampaigns());
  };
  // const handleChangeFilterStatus = (value) => {
  //   dispatch(changeFilterValue({ name: "filterStatus", value }));
  //   dispatch(changeFilteredCampaigns());
  // };
  if (!filteredCampaigns) return null;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...filterValue }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validate={(values) => console.log(values)}
    >
      {({ values, handleChange }) => (
        <div className="Sort">
          <div className={cx("container", "container-company")}>
            {/* <div className="searchInp">
              <input
                type="text"
                placeholder="Все кампании"
                name="search"
                value={values.search}
                onChange={(event) => {
                  handleInputChange(event);
                  handleChange(event);
                }}
              />
               <DeltaIcon />  
            </div> */}
            <SelectCampaigns
              input={<div className="searchInp">
              <input
                type="text"
                placeholder="Все кампании"
                name="search"
                value={values.search}
                onChange={(event) => {
                  handleInputChange(event);
                  handleChange(event);
                }}
              />
              <DeltaIcon style={{position:"absolute",top:14.4,right:10}}/> 
            </div>}
              options={createOptions(campaigns, filteredCampaigns)}
              name={values.campaigns}
              onChange={(selected) => {
                const name = "campaigns";
                const value = selected.map(({ value }) => value);
                handleInputChange({ target: { name, value } });
              }}
              resetSearch={() =>dispatch(changeFilterValue({ name:"search", value: "" }))}
              filterValue = {filterValue}
            />
            <SelectStatus 
             options={createStatusOptions(statusOptions,filterValue.filterStatus)}
             placeholder="Статус кампании"
             onChange={(selected) => {
                const name = "filterStatus";
                const value = selected.map(({ type }) => type);
                handleInputChange({ target: { name, value } });
              }}
            />
            {/* <DateFromTo
              handleChange={handleDateChange}
              withoutStartDate={true}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
                marginRight: "20px",
              }}
            >
              <input
                type="number"
                placeholder="Бюджет"
                name="budget"
                value={values.budget}
                onChange={(event) => {
                  handleInputChange(event);
                  handleChange(event);
                }}
              />
              <SortPopup
                title=""
                items={ALL_SIGNS}
                onChange={(e) => handleSign(e, "budgetSort")}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <input
                type="number"
                placeholder="Ставка"
                name="rate"
                value={values.rate}
                onChange={(event) => {
                  handleInputChange(event);
                  handleChange(event);
                }}
              />
              <SortPopup
                title=""
                items={ALL_SIGNS}
                onChange={(e) => handleSign(e, "rateSort")}
              />
            </Box> */}
          </div>
        </div>
      )}
    </Formik>
  );
}

export default SortCompany;
