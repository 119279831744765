import "./Profile.scss";
import { NavLink, Outlet } from "react-router-dom";

import { Typography } from "../../ui";
import { CardWhite } from "../../widgets";
import useUser from "../../data/useUser";

function Profile() {
  const { user } = useUser()
  return (
      <div className="Profile">
        <Typography variant="h1" color="dark-gray">
          Профиль
        </Typography>
        <div className="nav">
          <NavLink to="contact-details">Общая информация</NavLink>
          {user.is_entity ? <NavLink to="bank-audits">Банковские реквизиты</NavLink> : ""}
          {/* <NavLink to="settings">Настройки</NavLink> */}
        </div>
        <CardWhite>
          <Outlet />
        </CardWhite>
      </div>
  );
}

export default Profile;
