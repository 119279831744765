/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import {
  addBannerToCampaign,
  changeActiveFormat,
  changePreviewSize,
  deleteBanner,
  setAdvertisement,
} from "../../../../redux/createBanners/actions";
import { bannersSelector } from "../../../../redux/createBanners/selectors";
import {
  FormControl,
  FormHelperText,
  TextField,
  useMediaQuery,
} from "@mui/material";

import { DropArea } from "./DropArea";
// import { DropArea2 } from "./DropArea2";
// import { DropArea3 } from "./DropArea3";
import { SortPopup } from "../../../../widgets";
import AllImagesDialog from "./AllImagesDialog";
import Carousel from "./Carousel";
import Carousel2 from "./Carousel2";
import Carousel3 from "./Carousel3";
// import { POSITION_TYPES } from "./configs";
import { SELECT_OPTIONS } from "./configs";
import PreviewBox from "./PreviewBox";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { SelectOptions } from "./SelectOptions";
import SendIcon from "@mui/icons-material/Send";
import { Button, Typography } from "../../../../ui";
import { LoadingButton } from "@mui/lab";
import { BannersFormat } from "./banners_format.js"
import { Html5Format } from "./html5_format"
import { useSnackbar } from "notistack";
import { client } from "../../../../utils/network";
import { API_HOST } from "../../../../configs/main";
import {CONFIG_FORMATS} from "../../../../components/addCompany/stages/Banners/configs";

export const StyledHelperText = styled(FormHelperText)(({}) => ({
  background: "orange",
  width: "fit-content",
  position: "absolute",
  right: 0,
  top: 0,
  margin: 0,
  padding: "2px 5px",
  borderTopRightRadius: "5px",
}));


const theme = (window.outerWidth > 1000) ? createTheme({}) : createTheme({
  components: {
    // Name of the component
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          "& fieldset": { border: 'none' },
          // Some CSS
          // border: "0px solid white",
          marginTop: "-10px",
          marginBottom: "-15px",
        },
      },
      defaultProps: {
        inputProps: {
          // variant: 'standard',
          style: {
            fontSize: "12px",
            height: "0px",
            background: "#f0f1f3",
            border: "0px solid white",
            paddingTop: "15px",
          },
        },
        InputLabelProps : {
          style: { 
            fontSize: "12px",
            paddingTop: "10px",
          },
        },
      },
    },
    MuiFormControlLabel: {
      label: {
          fontSize: '12px',
      }
  }
  },
});

const validationSchema1 = Yup.object().shape({
  link: Yup.string().required().url(),
  head: Yup.string(),
  text: Yup.string(),
  utm: Yup.string(),
  text_utm: Yup.string(),
  call_to_action: Yup.string().required(),
});

const validationSchema2 = Yup.object().shape({
  link: Yup.string().required().url(),
  head: Yup.string().required("Введите заголовок").max(25, "Заголовок не может быть больше 25 символов"),
  text: Yup.string().required("Введите описание").max(90, "Описание не может быть длиннее 90 символов"),
  utm: Yup.string(),

  // text_utm: Yup.string().when('utm', {
  //   is: (utm) => utm === 'custom',
  //   then: Yup.string().required()
  // }),
  text_utm: Yup.string(),
  call_to_action: Yup.string().required(),
  // cpm: Yup.number().required(),
  // link: Yup.string().required(),
});

const isActive = (activeFormat, format) => {
  return activeFormat && activeFormat.id === format.id;
};

const setUtmText = (values) => {
  const { utm, text_utm } = values;
  switch (utm) {
    case "AUTO":
      return "Utm_source=Programmatic";
    case "MANUALLY":
      return text_utm;
    case "NOTHING":
      return "";
    default:
      return text_utm;
  }
};

const InitialValues = {
  head: "",
  // banner_position: "",
  text: "",
  utm: "AUTO",
  text_utm: "{utm_source, utm_medium, openstat_service, gclid}",
  call_to_action: "buy",//SELECT_OPTIONS.
  // cpm: "",
  link: "",
  link2:"",
  link3:"",
  link4:"",
  link5:"",
};

const createInitialValues = (data,link) => {
  //const result = { ...initialValues };
  const init = Object.assign({}, InitialValues);
  // console.log(Object.keys(data).length)
  if (typeof data === 'undefined') {
    InitialValues["link"] = link
    console.log("this init",InitialValues)
    return InitialValues;
  }
  else {
    for (const key in data) {
      if (key in init) {
        init[key] = data[key];
      }
    }
  init["link"] = data.urls.link1;
  init["link2"] = data.urls.link2;
  init["link3"] = data.urls.link3;
  init["link4"] = data.urls.link4;
  init["link5"] = data.urls.link5;
  // console.log("this init",init)
  return init;
  }
};

const getFirstEl = (arr) => {
  return arr[0] ? arr[0] : {};
};

const Banners = ({ handleChange: handleChangeTab, campaignID, data, editCampaign, disable }) => {
  const { formats, previewSize, userBanners, activeFormat, advertisement } =
    useSelector(bannersSelector.all_object);
  const isSmall = useMediaQuery("(max-width: 1250px)");
  const { enqueueSnackbar } = useSnackbar();
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [isForceClickArea, setIsForceClickArea] = useState(false);
  // const [isForceClickArea2, setIsForceClickArea2] = useState(false);
  // const [isForceClickArea3, setIsForceClickArea3] = useState(false);
  const [isCarousel,setCarousel] = useState(true);
  const [isCarousel2,setCarousel2] = useState(false);
  const [isCarousel3,setCarousel3] = useState(false);
  const [isSortDESC, setIsSortDESC] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting , setIsSubmitting ] = useState(false);
  const dispatch = useDispatch();
  const handleChangeFormat = (format) => {
    if (isActive(activeFormat, format)) {
      return;
    }
    dispatch(changeActiveFormat(format));
    set1();
  };
  const handleChangePreviewSize = (event) =>
    dispatch(changePreviewSize(event.target.dataset.value));
  const handleDeleteBanner = (id) => {
    if (!id) return;
    dispatch(deleteBanner(id));
  };
  const link = editCampaign?.basic_config.site
  const targetType = editCampaign?.basic_config.target_type
  useEffect(() => {
    if (data) {
      const matchingFormat = formats.find(format => format.name === data[0]?.image_type || format.name === data[0]?.html_type || format.name === data[0]?.video_type);
      if (matchingFormat && matchingFormat !== activeFormat) {
          handleChangeFormat(matchingFormat);
      } else if (targetType === "TRAFFIC") {
        handleChangeFormat(CONFIG_FORMATS[0])
      }

      const preview = data[0]?.banner ? data[0].banner : {};
      let preview2, preview3;
      dispatch(setAdvertisement({ preview }));
      if (data[0]?.icon) {
        advertisement.preview2 = data[0]?.icon
        preview2= data[0]?.icon
        dispatch(setAdvertisement({ preview2 }));
      } else dispatch(setAdvertisement({ preview2 }));
      if (data[0]?.overlay) {
        advertisement.preview3 = data[0]?.overlay
        preview3=data[0]?.overlay
        dispatch(setAdvertisement({ preview3 }));
      } else dispatch(setAdvertisement({ preview3 }));
      advertisement.preview = data[0]?.banner
      // console.log(advertisement)
    } else handleChangeFormat(CONFIG_FORMATS[0]);
  }, [data]);

  const handleChangeSort = ({ value }) => {
    if ((isSortDESC && value === "DESC") || (!isSortDESC && value === "ASC"))
      return;
    if (value === "ASC") {
      setIsSortDESC(false);
    } else {
      setIsSortDESC(true);
    }
  };
  let bannerDTO = {}
  const activeFormat1 = {
    id: 5,
    title: 'Баннеры',
    name: 'MEDIA',
    sizes: ['256x256'],
    descriptionType: 'изображение',
    bannerType: 'IMAGE',
    accept: 'image/*'
  }
  const activeFormat2 = {
    id: 5,
    title: 'Баннеры',
    name: 'MEDIA',
    sizes: ['1080x607'],
    descriptionType: 'изображение',
    bannerType: 'IMAGE',
    accept: 'image/*'
  }
  // console.log("data",data[0])
  // console.log(createInitialValues(initialValues, data[0]))
  const values = useFormik({
      //enableReinitialize: true,
      //initialValues: createInitialValues(advertisement, data),
      initialValues: createInitialValues(data[0],link),
      validationSchema: ((activeFormat?.name === 'MEDIA') || (activeFormat?.bannerType === 'HTML5')) ? validationSchema1 : validationSchema2,
      onSubmit: async (values) => {
        if (activeFormat?.name === 'MULTIFORM') {
          if (advertisement?.preview && advertisement?.preview2 && !advertisement?.preview3) {
            alert("Иконка не может быть выбрана одновременно только с картинкой 600x600");
            return;
          } else if (advertisement?.preview && Object.keys(advertisement.preview).length === 0 || !advertisement?.preview) {
            alert("Загрузите основной баннер");
            return;
          }
        }
        else if (!advertisement || !advertisement?.preview) {
          if (advertisement?.preview && Object.keys(advertisement.preview).length === 0 || !advertisement?.preview) {
            alert("Загрузите основной баннер");
            return;}
        }
        values.text_utm = setUtmText(values);
        dispatch(setAdvertisement(values));
        bannerDTO = {
          //config: { ...values, banners_type: "MEDIA" },
          banners: [
            {
              id: advertisement.preview.id,
              banner: advertisement.preview,
              ...values,
              urls_id: data[0]?.urls?.id,
              overlay_id: advertisement.preview3?.id,
              icon_id: advertisement.preview2?.id,
              created_at: advertisement.preview.created_at,
              updated_at: advertisement.preview.updated_at,
              moderate_status: "ACCEPTED",
            },
          ],
        };
        const handleChangeTabGlobal = () => handleChangeTab("event", "4");
        setIsSubmitting(true)
        
        const isDataChanged =
          data[0]?.banner?.id !== bannerDTO.banners[0]?.id ||
          data[0]?.icon?.id !== bannerDTO.banners[0]?.icon_id ||
          data[0]?.overlay?.id !== bannerDTO.banners[0]?.overlay_id ||
          data[0]?.head !== bannerDTO.banners[0]?.head ||
          data[0]?.text !== bannerDTO.banners[0]?.text ||
          data[0]?.call_to_action !== bannerDTO.banners[0]?.call_to_action;
        
        if (isDataChanged) {
          await dispatch(
            addBannerToCampaign(
              bannerDTO,
              campaignID,
              activeFormat,
              handleChangeTabGlobal
            )
          );
        } else {
          handleChangeTabGlobal();
        }
        
        setIsSubmitting(false)
        advertisement.preview = ""
        advertisement.preview2 = ""
        advertisement.preview3 = ""
        //values.setValues(InitialValues)
      return;
      },
    });
  console.log(activeFormat)
  function set1() {
    setCarousel(true)
    setCarousel2(false)
    setCarousel3(false)
  }
  function set2() {
    setCarousel2(true)
    setCarousel(false)
    setCarousel3(false)
  }
  function set3() {
    setCarousel3(true)
    setCarousel(false)
    setCarousel2(false)
  }

  const handleClickCreative = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    const res = await fetch(`${API_HOST}/api/v1/utils/help/banners/?campaign_id=${campaignID}`,{
      method:'POST',
      headers:myHeaders,
      body:JSON.stringify({})
    });
    if(res.ok){
      enqueueSnackbar("Запрос принят! Скоро с вами свяжется наш менеджер", {variant:"success"})
    }
  }

  const BannersBool = (title) => {
    if (disable) {
      return true
    } else if (title === "Баннеры") {
      return false
    } else {
      return !(editCampaign?.basic_config?.target_type === "REACH" && title === "Видео")
    }
  }
  return (
    <div style={(window.outerWidth > 1000) ? { marginLeft: "20px" } : 
    (window.outerWidth < 375) ? {width: "288px", marginLeft: "20px", marginTop: "40px"}
    : (window.outerWidth < 768) ? {width: "343px", marginLeft: "20px", marginTop: "40px"} : {}}>
      <div className="title-text" style={{ marginBottom: "50px" }}>
        <h2>Баннеры</h2>
        <p>3000+ Сегментов - Показывайте рекламу Только Тем, Кто Вам Нужен</p>
      </div> 
      <Stack width={(window.outerWidth > 1000) ? "330px" : (window.outerWidth < 375) ?  "288px" : "343px"} marginBottom={(window.outerWidth > 1000) ? "38px" : "0px"}>
        {(window.outerWidth > 1000) ? <Typography variant="h1" className="mb_2">
          Рекламный формат
        </Typography> : ""}
      <div className="CreativeButtonBox"><button onClick={() => {handleClickCreative()}} className="CreativeButton">Помочь с креативами</button></div>
        <Stack spacing="8px">
        {(window.outerWidth > 1000) ? 
          formats.map((format, index) => //( index === formats.length -1) ? (
            <Button
              disabled={BannersBool(format.title)}
              variant={isActive(activeFormat, format) ? "primary" : "nope"}
              className="format_item"
              key={format.id}
              onClick={() => handleChangeFormat(format)}
            >
              {format.title}
            </Button>)
            : ""
        }
        </Stack>
      </Stack>
      <Stack marginBottom="36px">
        <div className="banner_load_wrap">
          <Stack width={(window.outerWidth > 767) ? "228px" : (window.outerWidth < 375) ? "268px" : "318px"} marginRight={(window.outerWidth > 1000) ? "40px" : "40px"}>
            <Typography variant={(window.outerWidth > 1000) ? "body1" :"body3"} className="mb_2 row">
              Загрузить из кампании {(window.outerWidth > 1000) ? "баннер" : <div>
                <span onClick={() => setVisiblePopup(!visiblePopup)}>{activeFormat ? activeFormat.title : "баннер"}</span>
                <svg
                  className={visiblePopup ? "rotated" : "norotate"}
                  width="10"
                  height="5"
                  viewBox="0 0 10 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 5C10 5.16927 9.93815 5.31576 9.81445 5.43945C9.69075 5.56315 9.54427 5.625 9.375 5.625H0.625C0.455729 5.625 0.309245 5.56315 0.185547 5.43945C0.061849 5.31576 0 5.16927 0 5C0 4.83073 0.061849 4.68424 0.185547 4.56055L4.56055 0.185547C4.68424 0.061849 4.83073 0 5 0C5.16927 0 5.31576 0.061849 5.43945 0.185547L9.81445 4.56055C9.93815 4.68424 10 4.83073 10 5Z"
                    fill="#3f73f9"
                  />
                </svg>
                {visiblePopup && <div className="popup">
                      <ul>
                        {formats &&
                          
                          formats.map((format, index) => (
                            <li
                              onClick={() => {
                                handleChangeFormat(format)
                                setVisiblePopup(!visiblePopup)
                              }}
                              className="format_item"
                              // eslint-disable-next-line react/no-unknown-property
                              variant={isActive(activeFormat, format) ? "primary" : "nope"}
                              key={format.id}
                            >
                              {format.title}
                            </li>
                          ))}
                      </ul>
                    </div>}
                  </div> }
            </Typography>
            <Stack spacing="8px">
            {activeFormat && (
              <DropArea
                disable={disable}
                {...activeFormat}
                userBanners={userBanners.filter(({content_type}) => content_type === activeFormat?.bannerType )}
                isForceClick={isForceClickArea}
                setIsForceClick={setIsForceClickArea}
                preview={advertisement}
                isCarousel={isCarousel}
                isCarousel2={isCarousel2}
                isCarousel3={isCarousel3}
              />
            )}
            {/* {activeFormat && isCarousel2 && (
              <DropArea2
                disable={disable}
                {...activeFormat1}
                userBanners={userBanners.filter(({content_type}) => content_type === 'IMAGE')}
                isForceClick={isForceClickArea2}
                setIsForceClick={setIsForceClickArea2}
                preview={advertisement?.preview2}
                set1={set1}
                set2={set2}
                set3={set3}
              /> 
            )}
            {activeFormat && isCarousel3 && (
              <DropArea3
                  disable={disable}
                  {...activeFormat2}
                  userBanners={userBanners.filter(({content_type}) => content_type === 'IMAGE')}
                  isForceClick={isForceClickArea3}
                  setIsForceClick={setIsForceClickArea3}
                  preview={advertisement?.preview3}
                  set1={set1}
                  set2={set2}
                  set3={set3}
              /> 
            )} */}
            {activeFormat && ((activeFormat?.bannerType === 'VIDEO') || (activeFormat?.name === 'MULTIFORM')) ? (
            <Stack spacing="8px" width={(window.outerWidth > 767) ? '200px' : (window.outerWidth < 375) ? '268' : '320px'}>
              <Button
              variant={isCarousel ? "primary" : "nope"}
              className="format_item"
              onClick={() => set1()}
              >
                {(activeFormat?.bannerType === 'VIDEO') ? (activeFormat?.sizes) : `Баннер ${activeFormat?.sizes}`}
              </Button>
                <Button
                variant={isCarousel2 ? "primary" : "nope"}
                className="format_item"
                onClick={() => set2()}
              >
                Баннер 256x256
              </Button>
              <Button
                variant={isCarousel3 ? "primary" : "nope"}   
                className="format_item"
                onClick={() => set3()}
              >
                Баннер 1080x607
              </Button>
            </Stack>
            ) : "" }
            </Stack>
          </Stack>
          <Stack>
            <Stack
              direction={(window.outerWidth > 767) ? "row" : "column"}
              margin={(window.outerWidth > 1000) ? "0 0 21px 40px" : "10px 0 0px 2px"}
              height={(window.outerWidth > 1000) ? "20px" : "0px"}
              spacing={(window.outerWidth > 1000) ? "30px" : (window.outerWidth < 767) ? "-10px" : "5px"}
              alignItems={(window.outerWidth > 1000) ? "center" : "start"}
            >
              <SortPopup
                title="По дате загрузки"
                items={[
                  { name: "сначала новые", value: "DESC" },
                  { name: "сначала старые", value: "ASC" },
                ]}
                onChange={handleChangeSort}
              />
              <Stack direction="row" spacing="5px" alignItems={(window.outerWidth > 767) ? "center" : ""}>
                <Typography variant={(window.outerWidth > 1000) ? "body1" :"body3"}>Смотреть</Typography>
                <button
                  onClick={() => setIsDialogOpen(true)}
                  className="small_title c_blue"
                >
                  {" "}
                  все баннеры ({userBanners.filter(({content_type}) => content_type === activeFormat?.bannerType ).length})
                </button>
              </Stack>
              {isDialogOpen && activeFormat && (
                <AllImagesDialog
                  setIsDialogOpen={setIsDialogOpen}
                  banners={userBanners.filter(({content_type}) => content_type === activeFormat?.bannerType )}
                />
              )}
            </Stack>
            {(isCarousel) && (
              <Carousel
                disable={disable}
                activeFormat={activeFormat}
                userBanners={userBanners.filter(({ content_type, data }) => 
                  content_type === activeFormat?.bannerType &&
                  !(data.width === '256' && data.height === '256') &&
                  !(data.width === '1080' && data.height === '607')
                )}
                deleteBanner={handleDeleteBanner}
                showItem={5}
                isSortDESC={isSortDESC}
              />
            )}
            {(isCarousel2) && (
              <Carousel
                disable={disable}
                activeFormat={activeFormat}
                userBanners={userBanners.filter(({ content_type, data }) => content_type === 'IMAGE' && data.width === '256' && data.height === '256')}
                deleteBanner={handleDeleteBanner}
                showItem={5}
                isSortDESC={isSortDESC}
              />
            )}
            {(isCarousel3) && (
              <Carousel
                disable={disable}
                activeFormat={activeFormat2}
                userBanners={userBanners.filter(({ content_type, data }) => content_type === 'IMAGE' && data.width === '1080' && data.height === '607')}
                deleteBanner={handleDeleteBanner}
                showItem={5}
                isSortDESC={isSortDESC}
              />
            )}
          </Stack>
        </div>

        {(isCarousel) && <Button
          className="button_main"
          onClick={() => setIsForceClickArea(true)}
        >
          Добавить баннер
        </Button>}
        {(isCarousel2) && <Button
          className="button_main"
          onClick={() => setIsForceClickArea(true)}
        >
          Добавить баннер
        </Button>}
        {(isCarousel3) && <Button
          className="button_main"
          onClick={() => setIsForceClickArea(true)}
        >
          Добавить баннер
        </Button>}
      </Stack>
      
      <form onSubmit={values.handleSubmit}>
        <Stack width={(window.outerWidth > 1000) ? "630px" : (window.outerWidth < 375) ? "288px" : "343px"} marginTop={(window.outerWidth > 1000) ? "0px" : "-30px"}>
        <Typography variant="h1" className="mb_2">
              Добавление баннера
        </Typography>
        </Stack>
        <Stack
          direction={(window.outerWidth > 767) ? "row" : "column"}
          justifyContent={(window.outerWidth > 767) ? "space-between" : "flex-start"}
          marginBottom={(window.outerWidth > 1000) ? "34px" : "0px"}
          gap={(window.outerWidth > 767) ? "40px" : "10px"}
        >
          <Stack width={(window.outerWidth > 1000) ? "630px" : (window.outerWidth < 375) ?  "288px" : "343px"}> 
            <Stack spacing={(window.outerWidth > 767) ? "16px" : "2px"} className="mb_3">
            {(activeFormat?.bannerType === 'HTML5') ? (
                <Html5Format  values={values}/>) : ""}
            {(activeFormat?.name === 'MEDIA') ? (
              <BannersFormat values={values}/>) : ""}
            {((activeFormat?.name !== 'MEDIA') && (activeFormat?.bannerType !== 'HTML5')) ?
              <Stack spacing={(window.outerWidth > 1000) ? "16px" : "5px"} className="mb_3">
              <ThemeProvider theme={theme}>
              <FormControl fullWidth>
                <TextField
                  disabled={disable}
                  label={(window.outerWidth > 1000) ? "Заголовок" : ""}
                  placeholder={(window.outerWidth > 1000) ? "" : "Заголовок"}
                  name="head"
                  onChange={values.handleChange}
                  value={values.values.head}
                  error={(values.values.head.length > 25) ? true : false}
                  helperText={(values.values.head.length > 25) ? "Заголовок не может быть больше 25 символов" : <br />}
                />
                {Boolean(values.touched.head && values.errors.head) && (
                  <StyledHelperText>{values.errors.head}</StyledHelperText>
                )}
              </FormControl>
              </ThemeProvider>
              <FormControl>
                <TextField
                  disabled={disable}
                  label={(window.outerWidth > 1000) ? "Описание" : ""}
                  placeholder={(window.outerWidth > 1000) ? "" : "Описание"}
                  name="text"
                  value={values.values.text}
                  multiline={true}
                  rows={5}
                  inputProps={(window.outerWidth > 1000) ? {} : {style: {fontSize: "12px"}}} // font size of input text
                  InputLabelProps={(window.outerWidth > 1000) ? {} : {style: {fontSize: "12px"}}}
                  sx = {(window.outerWidth > 1000) ? {} : {background: "#f0f1f3", "& fieldset": { border: 'none' }, borderRadius: "5px"}}
                  onChange={values.handleChange}
                  error={(values.values.text.length > 90) ? true : false}
                  helperText={(values.values.text.length > 90) ? "Описание не может быть длиннее 90 символов" : <br />}
                />
                {Boolean(values.touched.text && values.errors.text) && (
                  <StyledHelperText>{values.errors.text}</StyledHelperText>
                )}
              </FormControl>

              <SelectOptions
                disable={disable}
                name="call_to_action"
                options={SELECT_OPTIONS}
                value={SELECT_OPTIONS[values.values.call_to_action]}
                title="Выберите текст кнопки:"
                onChange={(e) => values.setFieldValue("call_to_action", e)}
              />
              </Stack> : ""}
              
            </Stack>
          </Stack>
          <Stack width={(window.outerWidth > 1000) ? "590px" : (window.outerWidth < 375) ? "288px" :"343px"} 
          alignItems={(window.outerWidth > 767) ? "flex-end" : "flex-start"}
          margin={(window.outerWidth > 1000) ? "0" : (window.outerWidth < 768) ? "-60px 0 10px 0" : "-10px 0 30px -20px"}>
            {activeFormat &&
            <PreviewBox
              disable={disable}
              activeFormat={activeFormat}
              preview={advertisement?.preview}
              preview2={advertisement?.preview2}
              preview3={advertisement?.preview3}
              previewSize={previewSize}
              data={data[0]?.banner}
              icon={data[0]?.icon}
              overlay={data[0]?.overlay}
            />}
          </Stack>
        </Stack>
        
        <Stack>
          <div className="button_controls">
            <Button className="noBorder">Отмена</Button>
            <LoadingButton
              loading={isSubmitting}
              loadingPosition="end"
              endIcon={<SendIcon />}
              variant="contained"
              sx={{
                marginLeft: "32px",
              }}
              onClick={() => values.handleSubmit()}
            >
              {isSubmitting ? "Сохранение" : "Далее"}
            </LoadingButton>
          </div>
        </Stack>
      </form>
    </div>
  );
};
export default Banners;
