export const GET_USERS = "GET_USERS";
export const DELETE_USER = "DELETE_USERS";
export const ADD_COMPANY = "ADD_COMPANY";
export const LOAD_USERID = "LOAD_USERID";
export const UPDATE_USER = "UPDATE_USER";
export const GET_CAMPAIGNS = "GET_CAMPAIGNS";

export const GET_SEGMENTS = "GET_SEGMENTS";
export const GET_PREFERENCE = "GET_PREFERENCE";

export const user = {
  USER_SET_INFO: "USER_SET_INFO",
  USER_SET_REQUISITES: "USER_SET_REQUISITES",
  SET_USER_DATA: "SET_USER_DATA",
};
