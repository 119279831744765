import { useState } from "react";
import { saveBanner } from "../../../../redux/createBanners/actions";
import { useDispatch } from "react-redux";
import ImageCrop from "./ImageCrop";
import { Buffer } from 'buffer';

const CropImage = ({file, setFile, sizes, bannerType, setActive}) => {
  const dispatch = useDispatch();

  const [image, setImage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  
  if (image === "") {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImage(reader.result);
    };
  }

  function dataUrlToFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    if (arr.length < 2) { return undefined; }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) { return undefined; }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, {type:mime});
  }

  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = sizes.join(' ').split('x')[0];
    canvasEle.height = sizes.join(' ').split('x')[1];

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        sizes.join(' ').split('x')[0],
        sizes.join(' ').split('x')[1]
      );

      const dataURL = canvasEle.toDataURL(`modif_${file.path}`);
      const new_file = dataUrlToFile(dataURL,`modif_${file.path}`)
      setImgAfterCrop(new_file);

      dispatch(
        saveBanner({
          format: { sizes },
          file: new_file,
          bannerType,
          existBanner: false,
        }));
      setActive(false);
    };
  };

  const onCropCancel = () => {
    setImage("");
    setFile("")
    setActive(false);
  };

  return (
      <div>
          <ImageCrop
            image={image}
            onCropDone={onCropDone}
            onCropCancel={onCropCancel}
            size={sizes}
                />
      </div>
    );
};

export default CropImage;