export const ACTIONS = {
  CREATE_NEW_COMPANY_REQUEST: "CREATE_NEW_COMPANY_REQUEST",
  CREATE_NEW_COMPANY_SUCCESS: "CREATE_NEW_COMPANY_SUCCESS",
  CREATE_NEW_COMPANY_FAILURE: "CREATE_NEW_COMPANY_FAILURE",
  GET_CAMPAIGNS_REQUEST: " GET_CAMPAIGNS_REQUEST",
  GET_CAMPAIGNS_SUCCESS: " GET_CAMPAIGNS_SUCCESS",
  GET_CAMPAIGNS_FAILURE: " GET_CAMPAIGNS_FAILURE",
  UPDATE_CAMPAIGN_REQUEST: "UPDATE_CAMPAIGN_REQUEST",
  UPDATE_CAMPAIGN_SUCCESS: "UPDATE_CAMPAIGN_SUCCESS",
  UPDATE_CAMPAIGN_FAILURE: "UPDATE_CAMPAIGN_FAILURE",
  CREATE_LIST_REQUEST: "CREATE_LIST_REQUEST",
  CREATE_LIST_SUCCESS: "CREATE_LIST_SUCCESS",
  CREATE_LIST_FAILURE: "CREATE_LIST_FAILURE",
  SET_EDIT_CAMPAIGN: "SET_EDIT_CAMPAIGN",
  RESET_EDIT_CAMPAIGN: "RESET_EDIT_CAMPAIGN",
  // USER_CAMPAIGNS_STATE_CHANGE: "USER_CAMPAIGNS_STATE_CHANGE",
};
