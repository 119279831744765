import { useState } from "react";
import Cropper from "react-easy-crop";
import { Modal } from "../../../../widgets";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../../assets/images/close.svg";

function ImageCrop({ image, size, onCropDone, onCropCancel}) {
  const [openCrop, setOpenCrop] = useState(true);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(4 / 3);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  // eslint-disable-next-line no-unused-vars
  const onAspectRatioChange = (event) => {
    setAspectRatio(event.target.value);
  };

  return (
    <div>
    {(parseInt(size.join(' ').split('x')[0])!==1080) ? 
    <Modal 
        isOpen={openCrop}
        onClose={()=>{
            setOpenCrop(!openCrop)
            onCropCancel()}} 
        title="Выберите размер изображения">
        <div style={{width: parseInt(size.join(' ').split('x')[0]),
                height: parseInt(size.join(' ').split('x')[1])}}>
        <Cropper
          image={image}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          cropSize={{
            width: parseInt(size.join(' ').split('x')[0]), 
            height: parseInt(size.join(' ').split('x')[1])}}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            
            containerStyle: {
                width: parseInt(size.join(' ').split('x')[0])+10,
                height: parseInt(size.join(' ').split('x')[1])+10,
                alignContent:"center",
                marginLeft:"auto",
                marginRight:"auto",
                marginTop:"auto",
                marginBottom:"auto"
            },
        }}
        />
        </div>

        <Button
            variant="contained"
            onClick={() => {
                onCropDone(croppedArea);
            }}
        >
          Сохранить изображение
        </Button>
    </Modal>
   : <Dialog fullScreen onClose={()=>{
        setOpenCrop(!openCrop)
        onCropCancel()}} open={openCrop}>
          <DialogTitle>
            <Typography style={{ fontWeight: 600 }} variant="h5">
              Выберите размер изображения
            </Typography>
            <DialogActions style={{marginTop: -35}}><button onClick={()=>{setOpenCrop(!openCrop)
                                                  onCropCancel()}}>
              <CloseIcon />
              </button>
            </DialogActions>
          </DialogTitle>
        <DialogContent>
        <Cropper
          image={image}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          cropSize={{
            width: parseInt(size.join(' ').split('x')[0]), 
            height: parseInt(size.join(' ').split('x')[1])}}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            
            containerStyle: {
                width: parseInt(size.join(' ').split('x')[0])+10,
                height: parseInt(size.join(' ').split('x')[1])+10,
                alignContent:"center",
                marginLeft:"auto",
                marginRight:"auto",
                marginTop:"auto",
                marginBottom:"auto"
            },
        }}
        />
        </DialogContent>
        <Button style={{paddingTop:10,margin:20}}
            variant="contained"
            onClick={() => {
                onCropDone(croppedArea);
            }}
        >
          Сохранить изображение
        </Button>
      </Dialog>}
   </div>
    );
}

export default ImageCrop;