import React, { useState, useEffect } from 'react';
import './SearchSet.scss';
import { Switch, FormControlLabel } from '@mui/material';

import useRegions from '../../data/useRegions';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

function deleteFromSelected(node,selected,setSelected) {
    // var flag=false;
    // selected.map((item)=>(Math.abs(item)===Math.abs(node)) ? flag=true : console.log("No This"))
    // if (flag===true) {  }
    console.log(node);
    const array = selected.filter(((value) => Math.abs(value) !== Math.abs(node)));
    setSelected([...array]);
    console.log('Array', array);
    console.log('DeleteSelected');
}

export default function Search({ edit = false,open, value, onChange}) {
    const [selected, setSelected] = React.useState(value);
    useEffect(() => onChange(selected), [selected]);
    const { regions } = useRegions();
    const [values, setValue] = useState('');
    if (!open) return (<></>)
    const onChanges = (event) => {
        setValue(event.target.value);
    };

    const onSearch = (searchTerm) => {
        setValue(searchTerm.name);
        console.log('search ', searchTerm.name);
        let flag = false;
        selected.map((item) => (item === searchTerm.id ? (flag = true) : null));
        if (!flag) {
            setSelected([...selected, searchTerm.id]);
        }
    };

    function getOnChange(checked, node) {
        let flag = false; // first
        selected.map((item) => ((Math.abs(item) === Math.abs(node)) ? flag = true : console.log('No This')));
        console.log(checked);
        if (flag === false) {
            const array = [...selected, node];
            console.log(array);
            setSelected(array);
        } else {
            // let mnode={id:Number(-node.id),name:node.name}
            const array = selected.filter(((value) => Math.abs(value) !== Math.abs(node)));
            setSelected([...array]);
            console.log('array', array);
            setSelected([...array, Number(-node)]);
        }
    }

    function RenderTreeWithSwitch(node) {
        console.log('Nodes', node);
        let name = [];
        regions.map((item) => ((Math.abs(node) === item.id) ? name = item.name : 'a'));
        console.log('Nodes', name);
        // nodes?.map((node) => console.log("One Nodes",nodes) (node > 0) ? "success" : "error"
        return (
          <div>
            {console.log('start')}
            <FormControlLabel
              control={(
                <Switch
                  color="success"
                  checked={(node > 0)}
                  disabled = {edit}
                  onChange={edit ? edit : (event) => getOnChange(event.currentTarget.checked, node)}
                />
              )}
              label={(<div>{name}</div>)}
              key={Math.abs(node)}
            />
            {!edit && <button type="button" onClick={() => deleteFromSelected(node,selected,setSelected)}>
                <CloseIcon />
            </button>}
          </div>
        );
    }

    return (
    <div>
        <div className="App">
        <h2>Поиск регионов</h2><br></br>
        <div className="search-container">
            <div className="search-inner">
            <input placeholder="Введите регион" disabled={edit} type="text" value={values} onChange={onChanges}/>
            <button onClick={(e) => e.preventDefault()}></button>
            </div>
            <div className="dropdown">
            {regions && regions
                .filter((item) => {
                    const searchTerm = values.toLowerCase();
                    const fullName = item.name.toLowerCase();
                    return searchTerm.trim() && fullName.includes(searchTerm);
                })
                .slice(0, 10)
                .map((item) => (
                <button type="button"
                    onClick={() => onSearch(item)}
                    className="dropdown-row"
                    key={item.name}>
                {item.name}
                </button>
                ))
            }
            </div>
        </div>
        </div>
        {selected && selected?.sort((a, b) => Math.abs(a) - Math.abs(b))
        .map((item,i) => <div key={i}>{RenderTreeWithSwitch(item)}</div>)}
    </div>
    );
}
