import {
  Box,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormHelperText } from "@mui/material";

const FORMIK_INITIAL_VALUES = {
  first_name: "",
  surname: "",
  email: "",
  role: "",
};

const CreateSegmentSchema = Yup.object().shape({
  role: Yup.string().required(),
  first_name: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string()
    .required()
    .matches(/^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/i),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AddStaffModal({
  addUserModal,
  handleCloseUser,
  confirmUser,
  setConfirmUser,
  handleAddStaff,
}) {
  const formik = useFormik({
    initialValues: FORMIK_INITIAL_VALUES,
    validationSchema: CreateSegmentSchema,
    onSubmit: () => {
      handleAddStaff(formik.values);
      formik.setValues(FORMIK_INITIAL_VALUES);
      handleCloseUser();
    },
  });
  return (
    <Modal
      open={addUserModal}
      onClose={() => {
        handleCloseUser();
        formik.setValues(FORMIK_INITIAL_VALUES);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Добавить сотрудника
          </Typography>
          <FormControl
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              marginTop: 20,
            }}
          >
            <TextField
              error={formik.errors.surname && formik.touched.surname}
              helperText={
                formik.errors.surname && formik.touched.surname ? (
                  "Обязательное поле"
                ) : (
                  <br />
                )
              }
              onChange={formik.handleChange}
              name="surname"
              value={formik.values.surname}
              fullWidth
              id="outlined-basic"
              variant="outlined"
              placeholder="Фамилия"
            />
            <TextField
              error={formik.errors.first_name && formik.touched.first_name}
              helperText={
                formik.errors.first_name && formik.touched.first_name ? (
                  "Обязательное поле"
                ) : (
                  <br />
                )
              }
              onChange={formik.handleChange}
              name="first_name"
              value={formik.values.first_name}
              fullWidth
              id="outlined-basic"
              variant="outlined"
              placeholder="Имя"
            />
            <TextField
              error={formik.errors.email && formik.touched.email}
              helperText={
                formik.errors.email && formik.touched.email ? (
                  "Некорректный адрес электронной почты"
                ) : (
                  <br />
                )
              }
              onChange={formik.handleChange}
              name="email"
              value={formik.values.email}
              fullWidth
              id="outlined-basic"
              variant="outlined"
              placeholder="Email"
            />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              onChange={formik.handleChange}
              value={formik.values.role}
              name="role"
            >
              <Typography>Роль (права):</Typography>
              <FormControlLabel
                value="MANAGER"
                control={
                  <Radio
                    style={{
                      color: "#3F73F9",
                    }}
                  />
                }
                label="Менеджер"
              />
              <FormControlLabel
                value="ADMIN"
                control={
                  <Radio
                    style={{
                      color: "#3F73F9",
                    }}
                  />
                }
                label="Администратор"
              />
              <FormControlLabel
                value="SUPERUSER"
                control={
                  <Radio
                    style={{
                      color: "#3F73F9",
                    }}
                  />
                }
                label="Суперюзер"
              />
            </RadioGroup>
            {formik.errors.role && formik.touched.role && (
              <FormHelperText error={true}>Обязательное поле</FormHelperText>
            )}
          </FormControl>
          {!confirmUser && (
            <div onClick={() => setConfirmUser(true)} className="editButton">
              <span>Добавить</span>
            </div>
          )}
          {confirmUser && (
            <div className="buttonGroup">
              <div
                className="resetButton"
                onClick={() => {
                  handleCloseUser();
                  formik.setValues(FORMIK_INITIAL_VALUES);
                }}
              >
                <span>Отменить</span>
              </div>
              <div className="confirmButton">
                <button type="submit">Подтвердить</button>
              </div>
            </div>
          )}
        </Box>
      </form>
    </Modal>
  );
}

export default AddStaffModal;
