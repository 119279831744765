export const inputValueHolder = (formik, key, defaultValue) => {
  if (
    formik.values[key] === undefined &&
    formik.values[key] !== 0 &&
    (defaultValue || defaultValue === 0)
  ) {
    formik.values[key] = defaultValue;
  }
  return formik.values[key];
};

export const setInitialValue = (data, INITIAL_VALUES) => {
  if (!data) return INITIAL_VALUES;
  const newValue = Object.entries(INITIAL_VALUES).map(([key, value]) => {
    if (key === "metric_counter" && data[key]) {
      return [key, data[key].id];
    }
    return data[key] ? [key, data[key]] : [key, value];
  });
  return Object.fromEntries(newValue);
};
