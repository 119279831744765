import "./PasswordRecovery.scss";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { client } from "../../utils/network";
import * as Yup from "yup";
import { Button, Typography } from "../../ui";

import { ReactComponent as LogoWhiteIcon } from "../../assets/images/logo-white.svg";
import  { useState } from "react";
import FormWithText from "../../widgets/FormWithText/FormWithText";

function PasswordRecovery() {
  const [successText, setSuccessText] = useState("");

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setErrors,
    handleBlur,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Введите корректный Email")
        .required("Пожалуйста, введите ваш Email"),
    }),
    initialValues: { email: "" },
    onSubmit: async (values) => {
      try {
        const { data } = await client.post(`/api/v1/auth/password/reset`, {
          username: values.email,
        });
        if (data?.data === "Сообщение о сбросе пароля отправлено на почту.") {
          setSubmitting(true);
        }
        setSuccessText(data.data);
      } catch (error) {
        if (error.message === '{"detail":"Пользователь с данным username | email не найден"}') {
          setErrors({ ...errors, email: "Пользователь с таким Email не зарегистрирован" });
        } else {
          setErrors({ ...errors, response: error.message });
        }
        setSubmitting(false);
      }
      //  finally {
        // setSubmitting(false);
      // }
    }
  });
  const handleInput = (e) => {
    setSuccessText("");
    setErrors({});
    handleChange(e);
  };

  return (
    <div className="PasswordRecovery">
      <div className="images">
        <Link to="/">
          <LogoWhiteIcon className="logo" />
        </Link>

        <div className="greetings">
          <Typography className="greetings-title" variant="h1" color="white">
            Приветствуем Вас!
          </Typography>
          <Typography variant="h1" color="white">
            Крупнейшая programmatic-экосистема в Рунете
          </Typography>
        </div>
      </div>
      <form className="wrapp" onSubmit={handleSubmit}>
      {successText === "Сообщение о сбросе пароля отправлено на почту." ?
          <div className="form">
            <Typography className="form-title" variant="h1" color="dark-gray">
              Восстановить пароль
            </Typography>
            <Typography className="sub-title" variant="h2" color="dark-gray">
              На Ваш адрес электронной почты отправлено письмо, содержащее дальнейшие инструкции. 
              Если вы не получили письмо в течение 10 минут, проверьте папку «Спам».
            </Typography>
            <div className="btn-actions">
            <Link to="/sign-in">
              <Button variant="secondary">
                Перейти на главную
              </Button>
            </Link>
            </div>
          </div> 
      : <div className="form">
          <Typography className="form-title" variant="h1" color="dark-gray">
            Восстановить пароль
          </Typography>
          <Typography className="sub-title" variant="h2" color="dark-gray">
            Введите адрес Email, который Вы использовали при регистрации
          </Typography>

          <FormWithText>
          <div className="validate_input">
            <input
              id="email"
              name="email"
              className={errors.email && touched.email ? "email-field err" : "email-field"}
              type="text"
              placeholder="Email"
              value={values.email}
              onChange={handleInput}
              onBlur={handleBlur}
              autoComplete="off"
            />
            {touched.email && errors.email ? (
                  <div className="error_text">{errors.email}</div>
                ) : null}
            </div>
          </FormWithText>

          <div className="btn-actions">
            <Button
              type="submit"
              variant="primary"
              className="primary"
              disabled={isSubmitting}
            >
              Восстановить
            </Button>
            <Link to="/sign-in">
              <Button variant="secondary">
                Назад
              </Button>
            </Link>
          </div>
        </div>
      }
      </form>
    </div>
  );
}

export default PasswordRecovery;
