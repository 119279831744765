import useSWR from "swr";
import axios from "axios";
import { API_HOST } from '../configs/main.js';

export default function useAdminUser() {
  const token = localStorage.getItem("admin_token");
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch admin user data");
    }
  };

  const { data, error, mutate } = useSWR(token ? `${API_HOST}/api/v1/me` : null, fetchData);
  const isLoading = !error && !data;
  const loggedOut = Boolean(!isLoading && error);
  const role = data?.role;

  return {
    token,
    user: data,
    isLoading,
    loggedOut,
    role,
    mutate,
  };
}