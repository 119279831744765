import "./styles/index.scss";
import React from "react";
import ReactDOM from "react-dom";
import { SWRConfig } from "swr";
import App from "./App";
import { fetcher } from "./utils/fetchers";
import { Provider } from "react-redux";
import store from "./redux/store";
import { SnackbarProvider } from "notistack";

const swrConfig = {
  fetcher,
  revalidateOnFocus: false,
  revalidateIfStale: false,
};

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig value={swrConfig}>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </Provider>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById("root")
);
