import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import {
    FormControl,
    FormHelperText,
    TextField,
  } from "@mui/material";
import { isWebUri } from "valid-url";
  
// eslint-disable-next-line no-empty-pattern
const StyledHelperText = styled(FormHelperText)(({}) => ({
    background: "orange",
    width: "fit-content",
    position: "absolute",
    right: 0,
    top: 0,
    margin: 0,
    padding: "2px 5px",
    borderTopRightRadius: "5px",
}));

export function Html5Format({values}) {
    return (
        <Stack spacing="16px" className="mb_3">
                <FormControl fullWidth>
                    <TextField
                    className="cpm_input"
                    label="Ссылка 1"
                    name="link"
                    value={values.values.link}
                    onChange={values.handleChange}
                    />
                    {Boolean(values.touched.link && values.errors.link) && (
                    <StyledHelperText>Введите ссылку верного формата</StyledHelperText>
                    )}
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                    className="cpm_input"
                    label="Ссылка 2"
                    name="link2"
                    value={values.values.link2}
                    onChange={values.handleChange}
                    />
                    {Boolean(values.touched.link2 && values.errors.link2) && (
                    <StyledHelperText>Неверный формат</StyledHelperText>
                    )}
                    {((values.values.link2) && !isWebUri(values.values.link2)) ? 
                    (<StyledHelperText>Неверный формат</StyledHelperText>) : ""
                    }
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                    className="cpm_input"
                    label="Ссылка 3"
                    name="link3"
                    value={values.values.link3}
                    onChange={values.handleChange}
                    />
                    {Boolean(values.touched.link3 && values.errors.link3) && (
                    <StyledHelperText>Неверный формат</StyledHelperText>
                    )}
                    {((values.values.link3) && !isWebUri(values.values.link3)) ? 
                    (<StyledHelperText>Неверный формат</StyledHelperText>) : ""
                    }
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                    className="cpm_input"
                    label="Ссылка 4"
                    name="link4"
                    value={values.values.link4}
                    onChange={values.handleChange}
                    />
                    {Boolean(values.touched.link4 && values.errors.link4) && (
                    <StyledHelperText>Неверный формат</StyledHelperText>
                    )}
                    {((values.values.link4) && !isWebUri(values.values.link4)) ? 
                    (<StyledHelperText>Неверный формат</StyledHelperText>) : ""
                    }
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                    className="cpm_input"
                    label="Ссылка 5"
                    name="link5"
                    value={values.values.link5}
                    onChange={values.handleChange}
                    />
                    {Boolean(values.touched.link5 && values.errors.link5) && (
                    <StyledHelperText>Неверный формат</StyledHelperText>
                    )}
                    {((values.values.link5) && !isWebUri(values.values.link5)) ? 
                    (<StyledHelperText>Неверный формат</StyledHelperText>) : ""
                    }
                </FormControl>
                </Stack>
    )
}