export const bannersSelector = {
  formats: (state) => state.createBanners.formats,
  activeFormat: (state) => state.createBanners.activeFormat,
  preview: (state) => state.createBanners.preview,
  preview2: (state) => state.createBanners.preview2,
  preview3: (state) => state.createBanners.preview3,
  bannerError: (state) => state.createBanners.bannerError,
  isLoading: (state) => state.createBanners.formats,
  error: (state) => state.createBanners.formats,
  all_object: (state) => state.createBanners
}

