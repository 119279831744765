import "./Sort.scss";
import cx from "classnames";
import { Form, Formik } from "formik";

import { Select } from "../../ui";
import { DatePicker } from "../";

import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import useCampaigns from "../../data/useCampaigns";
import { StatisticsSort } from "./StatisticsSort";

function Sort({ company, statistics }) {
  const { campaigns } = useCampaigns();

  const campaignsOptions = [];

  for (const key in campaigns) {
    campaignsOptions.push({
      label: campaigns[key]["basic_config"]["name"],
      value: campaigns[key]["id"],
    });
  }

  return (
    <div className="Sort">
      {company && (
        <div className={cx("container", "container-company")}>
          <div className="search">
            <input type="text" placeholder="Поиск" />
            <SearchIcon />
          </div>
          <Select placeholder="Все кампании" />
          <DatePicker />
          <input type="text" placeholder="Бюджет" />
          <input type="text" placeholder="Ставка" />
        </div>
      )}
      {statistics && (
        <Formik
          initialValues={{
            selected_campaigns: [],
            media_plan: {
              date_start: null,
              date_end: null,
            },
          }}
          /*onSubmit={(value) => {
                      console.log('export') || console.log(value)
                    }}
                    innerRef={formikAction => (formikAction ? setFormikValues(formikAction.values) : setFormikValues({}))}*/
        >
          <Form>
            <StatisticsSort campaignsOptions={campaignsOptions} />
          </Form>
        </Formik>
      )}
    </div>
  );
}

export default Sort;
