import useSWR  from "swr"

export default function useYaRequest() {
    const { data, error, mutate } = useSWR("api/v1/ya-metrika/oauth-link")
    const isLoading = !error && !data
    const loggedOut = Boolean(!isLoading && error)

    return {
        link: data,
        isLoading,
        loggedOut,
        mutate
    }
}