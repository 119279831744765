import {
  Box,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FORMIK_INITIAL_VALUES = {
  role: "",
};

const CreateSegmentSchema = Yup.object().shape({
  role: Yup.string().required(),
});

function EditRoleModal({
  editRoleModal,
  handleCloseRole,
  confirmRole,
  setConfirmRole,
  handleEditRole,
  el,
}) {
  const formik = useFormik({
    initialValues: FORMIK_INITIAL_VALUES,
    validationSchema: CreateSegmentSchema,
    onSubmit: () => {
      formik.setValues(FORMIK_INITIAL_VALUES);
    },
  });

  return (
    <Modal
      open={editRoleModal}
      onClose={handleCloseRole}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Изменить права сотрудника
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{
            marginBottom: 20,
            marginTop: 20,
            color: "#42454C",
            opacity: "70%",
          }}
        >
          Сотрудник: {el.full_name}
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{ marginBottom: 5, marginTop: 20 }}
        >
          Роль (права):
        </Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            onChange={formik.handleChange}
            value={formik.values.role}
            name="role"
          >
            <FormControlLabel
              value="MANAGER"
              control={
                <Radio
                  style={{
                    color: "#3F73F9",
                  }}
                />
              }
              label="Менеджер"
            />
            <FormControlLabel
              value="ADMIN"
              control={
                <Radio
                  style={{
                    color: "#3F73F9",
                  }}
                />
              }
              label="Администратор"
            />
            <FormControlLabel
              value="SUPERUSER"
              control={
                <Radio
                  style={{
                    color: "#3F73F9",
                  }}
                />
              }
              label="Суперюзер"
            />
          </RadioGroup>
        </FormControl>
        {!confirmRole && (
          <div onClick={() => setConfirmRole(true)} className="editButton">
            <span>Изменить</span>
          </div>
        )}
        {confirmRole && (
          <div className="buttonGroup">
            <div
              className="resetButton"
              onClick={() => {
                handleCloseRole();
                formik.setFieldValue("role", "");
              }}
            >
              Отменить
            </div>
            <div
              className="confirmButton"
              onClick={() => {
                handleEditRole(el.id, formik.values.role);
                formik.setFieldValue("role", "");
              }}
            >
              Подтвердить
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default EditRoleModal;
